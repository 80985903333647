const appLabel = {
  username: "Username",
  password: "Password",
  btnLogin: "Login",
  forgotpass: "Forgot Password",
  currentPwd: "Current Password",
  newPwd: "New Password",
  confirmPwd: "Confirm Password",
  changePwd: "Change Password",
  proceed: "Proceed",
  confirm: "Confirm",
  confirmEmail: "Confirm Email",

  // Home Page
  about: "About us",
  aboutUsContent:
    " nAcomoda was established with an eager to make a difference in the property sector. At nAcomoda, we believe that managing your property should be so easy to do, that it doesn’t take a lot of your time, allowing you to focus on the most important parts of your business. We are a Property Management System that allows you to manage ALL your residences, clients and staff in ONE platform. We increase your business revenue by offering services that allow you to cut costs, increase your staff productivity and improve your customer’s experience.",
  readMore: "Want to read more?",
  version: "We have versions catered to your needs",
  //versionDescription: "We have versions catered to your needs",
  perUser: "/user/day",
  booking: "Booking",
  bookingDescription: "We have accommodations available",
  contactQuestion: "Got a question?",
  contactDescription:
    "If you want to work with us, to give us feedback or you have a question for us, please feel free to ",
  frequetlyAsked: "Frequently Asked Questions",
  frequentlyAskedDescription:
    "We have answered all the questions you might have.",

  // About us Page

  // Versions Page
  versionPricingDescriptionHead: "Choose the plan that's right for you.",
  versionPricingDescriptionP:
    "To give you even more power, we will keep releasing new versions of the system with more and more features.",
  freeVersion: "FREE ",
  freeVersionPrice: "0",
  freeVersionResidence: "Unlimited residences",
  freeVersionCategory: "Unlimited categories",
  freeVersionBlock: "Unlimited blocks",
  freeVersionRoles: "Unlimited sub-roles",
  freeVersionUsers: "No of users: 100 ",
  freeVersionDays: "No of days: 100",
  freeVersionFeeds: "Unlimited feeds",
  versionGuarantees: "VERSION GUARANTEES",
  versionGuaranteesRisk: "NO Risk ",
  versionGuaranteesContract: " NO Contract",
  versionGuaranteesFunctional: "FULLY functional ",
  versionGuaranteesTraining: "Life-time FREE Training ",
  versionGuaranteesCreditCards:
    "NO Credit card details required to test the system ",
  versionOne: "Version 1.0",
  versionOnePrice: "2",
  versionOneResidence: "Unlimited residences",
  versionOneCategory: "Unlimited categories",
  versionOneBlock: "Unlimited blocks",
  versionOneRoles: "Unlimited sub-roles",
  versionOneUsers: "Unlimited users",
  versionOneFeeds: "Unlimited feeds",
  versionOneUsers: "No of users: You choose",
  versionOneDays: "No of days: You choose",
  getStarted: "Get Started",
  versionSelect: "Version",
  selectVersion: "Select Version",
  versionNoOfUsers: "Number of users",
  versionNoOfDays: "Number of days",
  versionAmount: "Amount",
  versionCalDescription:
    "Please use the below calculator to know how many credits (1credit=R1) are enough for you to manage your residence(s).",
  versionCalc: " Amount = Version price x Number of users x  Number of days ",
  versionFaq:
    "To understand what we mean by a category, block, sub role and so on, please visit our",
  versionContact:
    "To get more details about the versions of the system, please feel free to",

  // Booking Page
  residenceName: "Residence Name",
  residenceDescription: "Residence Description",
  propertyAddress: "Property address",
  availableRooms: "Availability",
  available: "available",
  newPrice: " New Price",
  btnPreview: "view",
  x: "X",
  selectDate: "Select Date",
  selectHouse: "Number of Guests",
  bookingPreview: "Booking Preview",
  numOfHouses: "Number of Houses",
  numOfGuests: "No of Guests",
  numOfExtras: "Number of Extras",
  propertyDetails: "Property Details",
  roomsDescription: "Rooms",
  singleRoom: "Single Room",
  singlePrice: "R4500",
  sharingRoom: "Sharing Room",
  sharingPrice: "R2500",
  faq: "FAQ",
  roomCategory: "Room Category",
  points: "Points of Interest",
  space: "Space Name",
  service: "Additional Services",
  dateInfo: "Move Date",
  guestInfo: "Guest",
  serviceInfo: "Service ",
  totalService: "Total Amount",
  guestConditon: "You don't have more than 4 guests",
  guests: "Guest(s)",
  moveIn: "Move In",
  moveOut: "Move Out",
  guests: "No of Guests",
  noOfNight: "Number of night",
  noOfNights: "Number of nights",
  serviceTotal: "Amount",
  nights: "nights",
  night: "night",
  perDay: "/guest/night",
  perMonth: " /pm",
  perNight: " /night",
  guestPerRange: " Guest/Age Range",
  noRange: "No Range Selected",
  extraGuestPerRange: " Extra Guest/Age Range",
  single: "1 Person",
  singleTenant: "Person",
  emailAddress: "Email Address",
  contactNumber: "Contact Number",
  sharing: "2 People",
  ageRangeLabel: "Age Range",
  ageRangeDailyPriceLabel: "Price night",
  extraGuestLabel: "Extra Guests",
  noOfGuest: "No of Guest",
  houseDailyPriceLabel: " price per night is ",
  maxGuestMsg: "The maximum No of Guests is ",
  notMoreGuestMsg: "You are not more than ",
  guestsRangeLabel: "Are you more than",
  guestsRange: "4?",
  guestNumber: "No",
  total: "Total : ",
  totalDescription: " Total Price for ",
  contact: "Contact-us",
  priceSummary: "Price Summary",
  guestFee: "Guest Fee",
  serviceFee: "Service Fee",
  totalFee: "Total Fee",

  // Contact Page
  contactHeader: "We are one click away",
  contactParagraph: "Whatever your goal, we will get you there.",
  formHeader:
    "We are your one way to 4IR. Let us help you take your property to another level",
  contactReason: "reason",
  companyName: "Company Name",
  submit: "Submit",
  back: "Back",
  choose: "choose",
  selectCriteria: "Search by Residence Type",
  noFoundSearch: "There was no residence found. Please conduct another search.",
  searchAll: "Search company, residence, category, space etc...",
  noRegisteredResidence: "So far, there is no registered residence.",
};

export default appLabel;

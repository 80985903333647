import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";
import { Carousel } from "3d-react-carousal";
import appStatic from "../../config/appStaticData";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import { BOM } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays } from "date-fns";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ImgCarouselFac } from "../layouts/Facility";

const BookingDescriptionGuest = (props) => {
  const { location } = props;
  const { state } = location;
  const { defaultImgCarourel } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const dispatch = useDispatch();

  const [spaceDetail, setSpaceDetail] = useState({});

  var today = new Date();
  var nextDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  // Drop down condition
  const [showHide, setShowHide] = useState(false);
  const [isRangeType, setIsRangeType] = useState(false);

  // Number of nights chosen on the calender
  const [totalNoNight, setTotalNoNight] = useState(1);

  // Number of People chosen as pricing functions
  const [selectedSvc, setSelectedSvc] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [totalService, setTotalService] = useState(0);
  const [totalGuest, setTotalGuest] = useState(0);
  const [totalGuestExtra, setTotalGuestExtra] = useState(0);
  const [extraGuestNo, setExtraGuestNo] = useState(0);
  const [dailyPrice, setDailyPrice] = useState(0); // from backend
  const [numberOfPeople, setNumberOfPeople] = useState(0); // from backend
  const [ageRangeData, setAgeRangeData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [pointInterestData, setPointInterestData] = useState([]);

  // Calender
  var nextDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(nextDay);
  const [currency, setCurrency] = useState("");

  // Modal
  const [modal, setModal] = useState(false);
  const [excludeDates, setExcludeDates] = useState([]);
  const [slides, setSlides] = useState(defaultImgCarourel);

  useEffect(() => {
    // console.log(state.ggParam);
    // console.log(props)
    getDetailById(state.ggParam);
    window.scrollTo(0, 0);
  }, []);

  const assignExcludeDates = (arrParam) => {
    let tempArr = [];

    arrParam.map((item, index) => {
      tempArr.push(new Date(item.BOOK_DATE));
    });
    setExcludeDates([...tempArr]);
  };
  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      spaceId: params.BD_ID,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetAllSpace, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let guestHouseDetails = res.guestHouseDetails;
        setSpaceDetail(guestHouseDetails);

        //get space images
        getBlockImage(guestHouseDetails);

        //get booked date space
        assignExcludeDates(guestHouseDetails.DATE_DATA);

        setDailyPrice(parseFloat(guestHouseDetails.CAT_PRICE));
        setNumberOfPeople(guestHouseDetails.CAT_NUM_PEOPLE);
        setCurrency(guestHouseDetails.CUR_CODE);
        setAgeRangeData(guestHouseDetails.RANGE_DATA);
        setServiceData(guestHouseDetails.SERVICE_DATA);
        setPointInterestData(guestHouseDetails.POINT_INTEREST);

        /** LOGIC ONLY FOR PEOPLE TYPE */
        if (guestHouseDetails.PRICE_TYPE === "people") {
          setTotalGuest(parseFloat(guestHouseDetails.CAT_PRICE) * totalNoNight);
        }

        if (guestHouseDetails.PRICE_TYPE === "range") {
          setIsRangeType(true);
        }

        /** SORT AGE RANGE */
        const myData = []
          .concat(guestHouseDetails.RANGE_DATA)
          .sort((a, b) => (a.CR_MIN_AGE > b.CR_MIN_AGE ? 1 : -1));
        setAgeRangeData([...myData]);
      }
    });
  };
  const onCalendarSelectCalc = (dates) => {
    const [start, end] = dates;
    let date1 = new Date(start);
    let date2 = new Date(end);

    //calculate time difference
    let time_difference = date2.getTime() - date1.getTime();
    let tempTime = time_difference / (1000 * 60 * 60 * 24);
    setTotalNoNight(tempTime);

    setTotalGuest(parseFloat(dailyPrice) * tempTime);
    setStartDate(start);
    setEndDate(end);
  };

  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];

    //exclude endDate
    while (date < endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    dateDataCheck(dates.map((item, index) => BOM.ReformatDate(item)));
    // return dates.map((item, index)=>BOM.ReformatDate(item));
    setDateData(dates.map((item, index) => BOM.ReformatDate(item)));
  }

  const onCalcService = (svcObj, noPeopleService) => {
    console.log(noPeopleService);
    if (!noPeopleService || noPeopleService === "0") {
      setSelectedSvc(
        selectedSvc.filter((item, index) => item.svc_id !== svcObj.SVC_ID)
      );
      return;
    }
    setSelectedSvc(
      BOM.GetUnique(
        [
          ...selectedSvc,
          {
            svc_id: svcObj.SVC_ID,
            svc_name: svcObj.SVC_NAME,
            svc_price: svcObj.SVC_PRICE,
            noOfGuest: noPeopleService,
          },
        ],
        "svc_id"
      )
    );
  };

  const onCalcGuest = (guestObj, noOfGuest) => {
    if (!noOfGuest || noOfGuest === "0") {
      setSelectedGuest(
        selectedGuest.filter((item, index) => item.guest_id !== guestObj.CR_ID)
      );
      return;
    }

    setSelectedGuest(
      BOM.GetUnique(
        [
          ...selectedGuest,
          {
            guest_id: guestObj.CR_ID,
            age_from: guestObj.CR_MIN_AGE,
            age_to: guestObj.CR_MAX_AGE,
            guest_price: guestObj.CR_AMOUNT,
            noOfGuest,
          },
        ],
        "guest_id"
      )
    );
  };

  function dateDataCheck(checkDateData = []) {
    let loc1 = excludeDates.map((item, index) => BOM.ReformatDate(item)),
      found = false;
    // console.log(checkDateData)
    // console.log(loc1)
    checkDateData.forEach((element) => {
      excludeDates.map((item) => {
        if (item.BOOK_DATE === element) {
          found = true;
        }
      });
    });

    if (found) {
      BOM.AlertMsg(cnt.WARNING, "Sorry, you selected unavailable date(s)");
      return;
    }
  }

  const rangePick = (dates) => {
    const [start, end] = dates;
    let date1 = new Date(start);
    let date2 = new Date(end);

    if (date1 > date2) {
      BOM.AlertMsg(cnt.WARNING, "Please select the move out date");
      return;
    }
  };

  const onBookReview = () => {
    if (startDate > endDate) {
      BOM.AlertMsg(cnt.WARNING, "Please select the move out date");
      return;
    }

    let tempService = 0;
    selectedSvc.forEach((element) => {
      tempService += element.svc_price * element.noOfGuest * totalNoNight;
    });

    let tempGuest = 0;
    selectedGuest.forEach((element) => {
      tempGuest += element.guest_price * element.noOfGuest * totalNoNight;
    });

    let tempGuestNo = 0;
    selectedGuest.forEach((element) => {
      tempGuestNo += element.noOfGuest * 1;
    });

    switch (spaceDetail.PRICE_TYPE.toLowerCase()) {
      case "range":
        break;

      case "people":
        setTotalGuest(parseFloat(dailyPrice) * totalNoNight);
        break;
    }

    setTotalService(tempService);
    setTotalGuestExtra(tempGuest + totalGuest);
    setExtraGuestNo(tempGuestNo + numberOfPeople);
    setTotalFee(tempService + tempGuest + totalGuest);
    setNumberOfPeople(numberOfPeople);

    /** VALIDATE THE FOLLOWING */

    switch (spaceDetail.PRICE_TYPE.toLowerCase()) {
      case "range":
        if (tempGuestNo + numberOfPeople > parseInt(spaceDetail.MAX_NO_GUEST)) {
          BOM.AlertMsg(
            cnt.WARNING,
            "No of Guest cannot exceed " + spaceDetail.MAX_NO_GUEST
          );
          return;
        }
        if (selectedGuest.length === 0) {
          BOM.AlertMsg(cnt.WARNING, "Please select at least one range");
          return;
        }
        break;
      case "people":
        if (tempGuestNo + numberOfPeople > parseInt(spaceDetail.MAX_NO_GUEST)) {
          BOM.AlertMsg(
            cnt.WARNING,
            "No of Guest cannot exceed " + spaceDetail.MAX_NO_GUEST
          );
          return;
        }
        break;
    }

    if (!showHide && !isRangeType) {
      setSelectedGuest([]);
    }

    setModal(!modal);

    //Get all Dates between start & end Dates
    getDatesInRange(startDate, endDate);
  };

  function getBlockImage(objParam) {
    // setSlides([])
    const body = {
      // profileId: objParam.PROFILE_ID,
      coId: objParam.CO_ID,
      bdId: objParam.BD_ID,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetBlockImage, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.DOCUMENTS;
        let tempArr = [];

        if (objList.length > 0) {
          objList.map((item, index) => {
            tempArr.push({
              image: item.DOCUMENT,
            });
          });

          setSlides([...tempArr]);
        }
      }
    });
  }

  const formSubmit = () => {
    let username = document.getElementById("username").value;

    if (!username) {
      BOM.AlertMsg(cnt.WARNING, "nAcomoda username is required.");
      return;
    }

    let body = {
      username,
      loId: spaceDetail.LO_ID,
      bdId: spaceDetail.BD_ID,
      spaceName: spaceDetail.SPACE_NAME,
      roomCatId: spaceDetail.ROOM_CAT_ID,
      startDate: BOM.ReformatDate(startDate),
      endDate: BOM.ReformatDate(endDate),
      rangeData: selectedGuest,
      serviceData: selectedSvc,
      totalRangeFee: totalGuestExtra,
      totalServiceFee: totalService,
      finalAmount: totalFee,
      noOfNight: totalNoNight,
      extraGuestNo,
      dateData,
    };
    // console.log(body);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.NewTenantBooking, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        setTimeout(() => {
          window.location.href = "https://backoffice.nacomoda.com";
        }, 1000);
      }
    });
  };

  return (
    <div>
      {/* ========== HEADER ========== */}

      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}

      <main id="content" role="main">
        <div className="container space-top-3 space-top-sm-3">
          <div className="row">
            <div className="col-lg-7">
              <ImgCarouselFac imageData={slides} automatic={true} />
            </div>
            <div className="col-lg-5">
              {/* Title */}
              <div className="mb-1">
                <h1 className="h2">{spaceDetail.SPACE_NAME}</h1>
                <p>
                  <i className="fa fa-building text-danger"></i>&nbsp;
                  {spaceDetail.CO_NAME}&nbsp;-&nbsp;{spaceDetail.LO_NAME}
                </p>
              </div>
              {/* End Title */}

              {/* Price */}
              <div className="mb-1">
                {spaceDetail.PRICE_TYPE === "range" && "From"}{" "}
                <span className="text-success font-size-2 font-weight-bold">
                  <NumberFormat
                    value={dailyPrice}
                    className="foo"
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currency + " "}
                  />
                </span>{" "}
                for <b>1</b>&nbsp;
                {totalNoNight > 1 ? appLabel.nights : appLabel.night}
              </div>
              {/* End Price */}
              <div className="mb-2">
                <span>
                  {!spaceDetail.SPACE_DESCRIPTION ? (
                    <i>{BOM.Uwfirst("No description")}</i>
                  ) : (
                    <i>{spaceDetail.SPACE_DESCRIPTION}</i>
                  )}
                </span>
              </div>
              <div id="shopCartAccordion" className="accordion mb-5">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center ml-auto">
                    <div className="small text-muted">
                      <address>
                        <i className="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1 text-primary"></i>
                        {spaceDetail.LO_ADDRESS}, {spaceDetail.CITY_NAME},{" "}
                        {spaceDetail.STATE_NAME}
                      </address>
                    </div>
                  </div>
                </div>
                <div className="card border shadow-none">
                  <div
                    className="card-body card-collapse"
                    id="shopCardHeadingTen"
                  >
                    <a
                      className="btn btn-link btn-block card-btn collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCardTen"
                      aria-expanded="false"
                      aria-controls="shopCardTen"
                    >
                      <span className="row align-items-center">
                        <span className="col-9">
                          <span className="media align-items-center">
                            <span className="w-100 max-w-6rem mr-3">
                              <i className="fa fa-calendar text-primary fa-2x" />
                            </span>
                            <span className="media-body">
                              <span className="d-block font-size-1 font-weight-bold">
                                {appLabel.selectDate}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="col-3 text-right">
                          <span className="card-btn-toggle">
                            <span className="card-btn-toggle-default">+</span>
                            <span className="card-btn-toggle-active">−</span>
                          </span>
                        </span>
                      </span>
                    </a>
                  </div>
                  <div
                    id="shopCardTen"
                    className="collapse"
                    aria-labelledby="shopCardHeadingTen"
                    data-parent="#shopCartAccordion"
                  >
                    <div className="picker">
                      <div className="card-body ">
                        <div className="row justify-content-center">
                          {/* <DateRangePicker
                          startDate={startDate} // momentPropTypes.momentObj or null,
                          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                          endDate={endDate} // momentPropTypes.momentObj or null,
                          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                          onDatesChange={rangeDatesChangeHandler} // focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          focusedInput={focusedInput}
                          onFocusChange={onFocusChangeRangeHandler}
                        /> */}

                          <DatePicker
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onCalendarSelectCalc}
                            excludeDates={excludeDates}
                            selectsRange
                            // selectsDisabledDaysInRange
                            inline
                            minDate={subDays(new Date(), 0)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card border shadow-none">
                  <div
                    className="card-body card-collapse"
                    id="shopCardHeadingOne"
                  >
                    <a
                      className="btn btn-link btn-block card-btn collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCardOne"
                      aria-expanded="false"
                      aria-controls="shopCardOne"
                    >
                      <span className="row align-items-center">
                        <span className="col-9">
                          <span className="media align-items-center">
                            <span className="w-100 max-w-6rem mr-3">
                              <i className="fa fa-users text-primary fa-2x" />
                            </span>
                            <span className="media-body">
                              <span className="d-block font-size-1 font-weight-bold">
                                {appLabel.selectHouse}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="col-3 text-right">
                          <span className="card-btn-toggle">
                            <span className="card-btn-toggle-default">+</span>
                            <span className="card-btn-toggle-active">−</span>
                          </span>
                        </span>
                      </span>
                    </a>
                  </div>
                  <div
                    id="shopCardOne"
                    className="collapse"
                    aria-labelledby="shopCardHeadingOne"
                    data-parent="#shopCartAccordion"
                  >
                    <div className="card-body">
                      {spaceDetail.PRICE_TYPE === "people" && (
                        <div>
                          <h5>
                            <small>
                              {" "}
                              The {spaceDetail.SPACE_NAME}{" "}
                              {appLabel.houseDailyPriceLabel}&nbsp;
                            </small>
                            <NumberFormat
                              value={dailyPrice}
                              className="foo font-size-1"
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={currency + " "}
                            />
                          </h5>
                          <small className="font-size-1">
                            {" "}
                            {spaceDetail.MAX_NO_EXTRA_GUEST !== 0 ? (
                              <div>
                                <h4>
                                  <small>
                                    {appLabel.guestsRangeLabel}&nbsp;
                                  </small>
                                  {numberOfPeople} ? &nbsp;
                                  <select
                                    className="form-controly"
                                    onChange={() => setShowHide(!showHide)}
                                  >
                                    <option value="">
                                      {appLabel.guestNumber}
                                    </option>
                                    {appStatic.guestNumber.map(
                                      (item, index) => (
                                        <option value={item.value} key={index}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </h4>
                              </div>
                            ) : (
                              <h4>
                                <small>{appLabel.maxGuestMsg}</small>{" "}
                                <b>{spaceDetail.MAX_NO_GUEST}</b>
                              </h4>
                            )}
                          </small>
                        </div>
                      )}

                      {(showHide || spaceDetail.PRICE_TYPE === "range") && (
                        <div className="row mx-n2 mt-3">
                          {ageRangeData.map((item, index) => (
                            <div
                              className="col-sm-6  px-2 mb-3 mb-lg-3"
                              key={index}
                            >
                              {/* Icon Blocks */}
                              <a
                                className="card h-100 transition-3d-hover"
                                style={{
                                  borderRadius: "15px",
                                  boxShadow: "0 6px 6px -3px gray",
                                }}
                              >
                                <div className="card-body">
                                  <h5>
                                    <small>Age Range :</small> {item.CR_MIN_AGE}{" "}
                                    - {item.CR_MAX_AGE}{" "}
                                  </h5>
                                  <div>
                                    <span className="text-dark">
                                      <span
                                        className="text-dark"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        <NumberFormat
                                          value={item.CR_AMOUNT}
                                          className="foo font-size-1"
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={currency + " "}
                                        />
                                      </span>
                                      <span className="font-size-1">
                                        {appLabel.perDay}
                                      </span>
                                    </span>
                                    {/* Form Group */}
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-controlGuest"
                                        placeholder={
                                          spaceDetail.PRICE_TYPE === "range"
                                            ? appLabel.noOfGuest
                                            : appLabel.extraGuestLabel
                                        }
                                        onChange={(e) =>
                                          onCalcGuest(item, e.target.value)
                                        }
                                      />
                                    </div>
                                    {/* End Form Group */}
                                  </div>
                                </div>
                              </a>
                              {/* End Icon Blocks */}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* End Card */}
                {/* Card */}
                <div className="card border shadow-none">
                  <div
                    className="card-body card-collapse"
                    id="shopCardHeadingEight"
                  >
                    <a
                      className="btn btn-link btn-block card-btn collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCardEight"
                      aria-expanded="false"
                      aria-controls="shopCardEight"
                    >
                      <span className="row align-items-center">
                        <span className="col-9">
                          <span className="media align-items-center">
                            <span className="w-100 max-w-6rem mr-3">
                              <i className="fa fa-list-ol text-primary fa-2x" />
                            </span>
                            <span className="media-body">
                              <span className="d-block font-size-1 font-weight-bold">
                                {appLabel.service}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="col-3 text-right">
                          <span className="card-btn-toggle">
                            <span className="card-btn-toggle-default">+</span>
                            <span className="card-btn-toggle-active">−</span>
                          </span>
                        </span>
                      </span>
                    </a>
                  </div>
                  <div
                    id="shopCardEight"
                    className="collapse"
                    aria-labelledby="shopCardHeadingEight"
                    data-parent="#shopCartAccordion"
                  >
                    <div className="card-body">
                      <div className="row mx-n2">
                        {serviceData.length === 0 ? (
                          <p>No Service available yet</p>
                        ) : (
                          serviceData.map((item, index) => (
                            <div
                              className="col-sm-6  px-2 mb-3 mb-lg-3"
                              key={index}
                            >
                              {/* Icon Blocks */}
                              <a
                                className="card h-100 transition-3d-hover"
                                style={{
                                  borderRadius: "15px",
                                  boxShadow: "0 6px 6px -3px gray",
                                }}
                              >
                                <div className="card-body">
                                  <h4>
                                    <small>{item.SVC_NAME}</small>
                                  </h4>
                                  <div>
                                    <span className="text-dark">
                                      <span
                                        className="text-dark"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <NumberFormat
                                          value={item.SVC_PRICE}
                                          className="foo font-size-1"
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={currency + " "}
                                        />
                                      </span>
                                      <span className="font-size-1">
                                        {appLabel.perDay}
                                      </span>
                                    </span>
                                    {/* Form Group */}
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-controlGuest"
                                        placeholder={appLabel.numOfGuests}
                                        onChange={(e) =>
                                          onCalcService(item, e.target.value)
                                        }
                                        min={0}
                                      />
                                    </div>
                                    {/* End Form Group */}
                                  </div>
                                </div>
                              </a>
                              {/* End Icon Blocks */}
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card border shadow-none">
                  <div
                    className="card-body card-collapse"
                    id="shopCardHeadingTenTen"
                  >
                    <a
                      className="btn btn-link btn-block card-btn collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCardTenTen"
                      aria-expanded="false"
                      aria-controls="shopCardTenTen"
                    >
                      <span className="row align-items-center">
                        <span className="col-9">
                          <span className="media align-items-center">
                            <span className="w-100 max-w-6rem mr-3">
                              <i className="fa fa-database  text-primary fa-2x" />
                            </span>
                            <span className="media-body">
                              <span className="d-block font-size-1 font-weight-bold">
                                {appLabel.points}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="col-3 text-right">
                          <span className="card-btn-toggle">
                            <span className="card-btn-toggle-default">+</span>
                            <span className="card-btn-toggle-active">−</span>
                          </span>
                        </span>
                      </span>
                    </a>
                  </div>
                  <div
                    id="shopCardTenTen"
                    className="collapse"
                    aria-labelledby="shopCardHeadingTenTen"
                    data-parent="#shopCartAccordion"
                  >
                    <div className="picker">
                      <div className="card-body ">
                        <div className="row justify-content-left">
                          <div className="row">
                            {pointInterestData.length === 0 ? (
                              <p>No point of interest </p>
                            ) : (
                              pointInterestData.map((poi, index) => (
                                <div className="col-sm-12" key={index}>
                                  <ul className="list-sm-article text-body mb-0">
                                    <li>{poi.pInterest}</li>
                                  </ul>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Accordion */}
              <br></br>
              <div className="mb-4">
                <button
                  type="submit"
                  // onClick={handleSubmit(formSubmit)}
                  onClick={() => onBookReview()}
                  className="btn btn-block btn-primary btn-pill transition-3d-hover"
                >
                  {appLabel.bookingPreview}
                </button>
              </div>
              {/* Help Link */}
              <div className="media align-items-center">
                <span className="w-100 max-w-6rem mr-2">
                  {/* <img
                    className="img-fluid"
                    src="../../assets/svg/icons/icon-4.svg"
                    alt="SVG"
                  /> */}
                </span>
                <div className="media-body text-body small">
                  <span className="font-weight-bold mr-1">
                    <i className="fa fa-share text-primary" /> Need Help?
                  </span>
                  Check our&nbsp;
                  <Link to="/faq" className="" target="blank">
                    {appLabel.faq}
                  </Link>
                  &nbsp;page
                </div>
              </div>
              {/* End Help Link */}
            </div>
            {/* End Product Description */}
          </div>
        </div>

        {/* End Product Description Section */}
      </main>
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}

      {/* REVIEW MODAL */}
      <div>
        <Modal size="lg" isOpen={modal}>
          <ModalHeader>{appLabel.bookingPreview}</ModalHeader>
          <ModalBody>
            <main id="content" role="main">
              <div className="container space-1 space-md-2">
                <div className="row mb-4">
                  <div
                    className="col-lg-4 order-lg-2 mb-7 mb-lg-0"
                    style={{
                      borderRadius: "15px",
                      boxShadow: "0 6px 6px -3px gray",
                    }}
                  >
                    <div className="border-bottom pb-2 mb-2">
                      <div className="media">
                        <div className="media-body">
                          <h2 className="h6">
                            <i className="fas fa-calendar text-success mt-1 mr-2" />
                            &nbsp;{appLabel.dateInfo}
                          </h2>
                          <div className="text-body font-size-1">
                            <h4>
                              <small>
                                <i className="fas fa-check-circle text-success mt-1 mr-2" />
                                &nbsp;{appLabel.moveIn}:{" "}
                              </small>
                              <small>
                                <b>{BOM.ReformatDate(startDate)}</b>
                              </small>
                            </h4>
                          </div>
                          <div className="text-body font-size-1">
                            <h4>
                              <small>
                                <i className="fas fa-check-circle text-success mt-1 mr-2" />
                                &nbsp;{appLabel.moveOut}:{" "}
                              </small>
                              <small>
                                <b>{BOM.ReformatDate(endDate)}</b>
                              </small>
                            </h4>
                          </div>
                          <hr />
                          <h4>
                            <small>
                              No of{" "}
                              {totalNoNight > 1
                                ? appLabel.nights
                                : appLabel.night}
                              : <b>{totalNoNight}</b>
                            </small>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 order-lg-2 mb-7 mb-lg-0"
                    style={{
                      borderRadius: "15px",
                      boxShadow: "0 6px 6px -3px gray",
                    }}
                  >
                    <div className="border-bottom pb-2 mb-2">
                      <div className="media">
                        <div className="media-body">
                          <h2 className="h6">
                            <i className="fas fa-users text-success mt-1 mr-2" />
                            &nbsp;{appLabel.guestInfo}
                          </h2>
                          <div className="text-body font-size-1">
                            {!showHide && spaceDetail.PRICE_TYPE === "people" && (
                              <div>
                                <p>
                                  {`${appLabel.notMoreGuestMsg} ${numberOfPeople} guest(s)`}
                                </p>
                                <hr />
                                <h4>
                                  <small> {appLabel.guestFee} : </small>
                                  <NumberFormat
                                    value={totalGuest}
                                    className="foo"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={currency + " "}
                                  />
                                </h4>
                              </div>
                            )}
                            {showHide && spaceDetail.PRICE_TYPE === "people" && (
                              <div>
                                <p className="mb-2">
                                  {spaceDetail.PRICE_TYPE === "people" &&
                                  selectedGuest.length !== 0
                                    ? appLabel.extraGuestPerRange
                                    : `${appLabel.notMoreGuestMsg} ${numberOfPeople} guest(s)`}
                                </p>
                                {selectedGuest.map((item, index) => (
                                  <div key={index}>
                                    <h5>
                                      <small>
                                        <i className="fas fa-check-circle text-success mt-1 mr-2" />{" "}
                                        <b>
                                          {item.age_from} - {item.age_to}{" "}
                                        </b>
                                        : &nbsp;
                                        <b>{item.noOfGuest}</b> ({" "}
                                        <NumberFormat
                                          value={item.guest_price}
                                          className="foo"
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={currency + " "}
                                        />{" "}
                                        / Guest )
                                      </small>
                                    </h5>
                                    <br />
                                  </div>
                                ))}
                                {selectedGuest.length !== 0 && (
                                  <div>
                                    <hr />
                                    <h4>
                                      <small className="mt-4">
                                        {appLabel.guests} :{" "}
                                        <b>{extraGuestNo}</b>
                                      </small>
                                    </h4>
                                    <hr />
                                  </div>
                                )}

                                <h4>
                                  <small>
                                    {appLabel.guestFee} : &nbsp;
                                    <NumberFormat
                                      value={totalGuestExtra}
                                      className="foo"
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={currency + " "}
                                    />
                                  </small>
                                </h4>
                              </div>
                            )}

                            {isRangeType &&
                              selectedGuest.length === 0 &&
                              spaceDetail.PRICE_TYPE === "range" && (
                                <div>
                                  <p>{appLabel.noRange}</p>
                                  <hr />
                                  <h4>
                                    <small> {appLabel.guestFee} : </small>
                                    <NumberFormat
                                      value={totalGuest}
                                      className="foo"
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={currency + " "}
                                    />
                                  </h4>
                                </div>
                              )}
                            {isRangeType &&
                              selectedGuest.length !== 0 &&
                              spaceDetail.PRICE_TYPE === "range" && (
                                <div>
                                  <p className="mb-2">
                                    {appLabel.guestPerRange}
                                  </p>

                                  {selectedGuest.map((item, index) => (
                                    <div key={index}>
                                      <h5>
                                        <small>
                                          <i className="fas fa-check-circle text-success mt-1 mr-2" />{" "}
                                          &nbsp;
                                          <b>
                                            {item.age_from} - {item.age_to}{" "}
                                            &nbsp;
                                          </b>
                                          : &nbsp;
                                          <b>{item.noOfGuest}</b> &nbsp; ({" "}
                                          <NumberFormat
                                            value={item.guest_price}
                                            className="foo"
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={currency + " "}
                                          />{" "}
                                          / Guest )
                                        </small>
                                      </h5>
                                      <br />
                                    </div>
                                  ))}
                                  {selectedGuest.length !== 0 && (
                                    <div>
                                      <h4>
                                        <small className="mt-4">
                                          {appLabel.guests}:{" "}
                                          <b>{extraGuestNo}</b>
                                        </small>
                                      </h4>
                                      <hr />
                                    </div>
                                  )}

                                  <h4>
                                    <small>
                                      {appLabel.guestFee} : &nbsp;
                                      <b>
                                        <NumberFormat
                                          value={totalGuestExtra}
                                          className="foo"
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={currency + " "}
                                        />
                                      </b>
                                    </small>
                                  </h4>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 order-lg-2 mb-7 mb-lg-0"
                    style={{
                      borderRadius: "15px",
                      boxShadow: "0 6px 6px -3px gray",
                    }}
                  >
                    <div className="border-bottom pb-2 mb-2">
                      <div className="media">
                        <div className="media-body">
                          <h2 className="h6">
                            <i className="fa fa-list-ol text-success mt-1 mr-2" />
                            &nbsp; {appLabel.service}
                          </h2>
                          <div className="text-body font-size-1">
                            {selectedSvc.length === 0 ? (
                              <p>No Service selected</p>
                            ) : (
                              selectedSvc.map((item, index) => (
                                <div key={index}>
                                  <h5>
                                    <small>
                                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>{" "}
                                      <b>{item.svc_name}</b> for{" "}
                                      <b>{item.noOfGuest}</b> ({" "}
                                      <NumberFormat
                                        value={item.svc_price}
                                        className="foo"
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={currency + " "}
                                      />{" "}
                                      / Guest )
                                    </small>
                                  </h5>
                                  {/* <br /> */}
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4>
                      <small>
                        {" "}
                        {appLabel.serviceFee} : &nbsp;
                        <b>
                          <NumberFormat
                            value={totalService}
                            className="foo"
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currency + " "}
                          />
                        </b>
                      </small>
                    </h4>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6">
                      {/* <label>Username</label> */}
                      <input
                        type="text"
                        className="form-controlGuest"
                        id="username"
                        placeholder="Enter Your nAcomoda Username*"
                      />
                      {/* <span className="asterisks">{errors?.userName?.message}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </ModalBody>
          <ModalFooter>
            <div className="col-md-5">
              <h4>
                <small>{appLabel.totalFee} : </small>
                <NumberFormat
                  value={totalFee}
                  className="text-success font-size-2 font-weight-bold"
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currency + " "}
                />
              </h4>
            </div>
            <Button
              color="secondary"
              className="btn btn-xs"
              onClick={() => setModal(!modal)}
            >
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              className="btn btn-xs"
              // onClick={() => setModal(!modal)}
              onClick={handleSubmit(formSubmit)}
            >
              <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
              {appLabel.confirm}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      {/* MODAL END */}
    </div>
  );
};
export default BookingDescriptionGuest;

import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import history from "../../history";
import { Link } from "react-router-dom";
import appConfig from "../../config/appConfig";

const Header = (props) => {
  const { dev, prod, isProduction, apiSettings } = appConfig;

  const isProductionUrl = (key) => {
    switch (key) {
      case true:
        return prod.officeURL;
      case false:
        return dev.officeURL;
    }
  };
  return (
    <div>
      {/* ========== HEADER ========== */}
      <header
        id="header"
        className="header header-box-shadow-on-scroll header-abs-top header-bg-transparent header-show-hide"
        data-hs-header-options='{
          "fixMoment": 1000,
          "fixEffect": "slide"
        }'
      >
        {/* Search */}
        <div id="searchPushTop" className="search-push-top">
          <div className="container position-relative">
            <div className="search-push-top-content pt-3">
              {/* Close Button */}
              <div className="search-push-top-close-btn">
                <div className="hs-unfold">
                  <a
                    className="js-hs-unfold-invoker btn btn-icon btn-xs btn-soft-secondary mt-2 mr-2"
                    href="#"
                    data-hs-unfold-options='{
                      "target": "#searchPushTop",
                      "type": "jquery-slide",
                      "contentSelector": ".search-push-top"
                    }'
                  >
                    <svg
                      width={10}
                      height={10}
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* End Close Button */}
              {/* Input */}
              <form className="input-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Front"
                  aria-label="Search Front"
                />
                <div className="input-group-append">
                  <button type="button" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </form>
              {/* End Input */}
            </div>
          </div>
        </div>
        {/* End Search */}
        <div className="header-section">
          {/* Topbar */}
          <div className="container header-hide-content pt-2">
            <div className="d-flex align-items-center">
              {/* Language */}
              {/* <div className="hs-unfold">
                <a
                  className="js-hs-unfold-invoker dropdown-nav-link dropdown-toggle d-flex align-items-center"
                  href="#"
                  data-hs-unfold-options='{
                    "target": "#languageDropdown",
                    "type": "css-animation",
                    "event": "hover",
                    "hideOnScroll": "true"
                  }'
                >
                  <img
                    className="dropdown-item-icon mr-2"
                    src="../../assets/vendor/flag-icon-css/flags/4x3/us.svg"
                    alt="SVG"
                  />
                  <span className="d-inline-block d-sm-none">US</span>
                  <span className="d-none d-sm-inline-block">English</span>
                </a>
              </div> */}
              {/* End Language */}
              <div className="ml-auto">
                {/* Links */}
                {/* <div className="nav nav-sm nav-y-0 d-none d-sm-flex ml-sm-auto">
                  <a className="nav-link" href="../pages/faq.html">
                    Help
                  </a>
                  <a className="nav-link" href="../pages/contacts-agency.html">
                    Contacts
                  </a>
                </div> */}
                <div className="nav nav-sm text-white nav-y-0 d-none d-sm-flex ml-sm-auto">
                  {/* <a className="nav-link" href="../pages/faq.html">Sign up</a> */}
                  {/* <a className="nav-link" href="../pages/contacts-agency.html">Rewards</a> */}
                </div>
                {/* End Links */}
              </div>
            </div>
          </div>
          {/* End Topbar */}
          <div id="logoAndNav" className="container">
            {/* Nav */}
            <nav className="js-mega-menu navbar navbar-expand-lg">
              {/* Logo */}
              <Link to="/home" className="navbar-brand" aria-label="Front">
                <img
                  src="../../assets/svg/logos/nacomoda_edit1.png"
                  alt="Logo"
                />
              </Link>
              {/* End Logo */}
              {/* Responsive Toggle Button */}
              <button
                type="button"
                className="navbar-toggler btn btn-icon btn-sm rounded-circle"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
              >
                <span className="navbar-toggler-default">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
                    />
                  </svg>
                </span>
                <span className="navbar-toggler-toggled">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                    />
                  </svg>
                </span>
              </button>
              {/* End Responsive Toggle Button */}
              {/* Navigation */}
              <div id="navBar" className="collapse navbar-collapse">
                <div className="navbar-body header-abs-top-inner">
                  <ul className="navbar-nav">
                    <Link
                      to="/home"
                      className="hs-mega-menu-invoker nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Home
                    </Link>
                    <Link
                      to="/features"
                      className="hs-mega-menu-invoker nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Features
                    </Link>
                    <Link
                      to="/versions"
                      className="hs-mega-menu-invoker nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Versions
                    </Link>
                    <Link
                      to="/booking"
                      className="hs-mega-menu-invoker nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Booking
                    </Link>
                    <Link
                      to="/contact"
                      className="hs-mega-menu-invoker nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Contact
                    </Link>
                    <Link
                      to="/news"
                      className="hs-mega-menu-invoker nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      News
                    </Link>
                    <Link
                      to="/faq"
                      className="hs-mega-menu-invoker nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      FAQ
                    </Link>

                    {/* <li className="navbar-nav-last-item">
                      <a
                        className="btn btn-sm btn-primary transition-3d-hover"
                        href="https://backoffice.nacomoda.com/"
                        target="_blank"
                        >
                        <i className="fas fa-home"></i>&nbsp;Back Office
                      </a>
                    </li> */}

                    <li className="navbar-nav-last-item">
                      <a
                        className="btn btn-sm btn-primary transition-3d-hover"
                        href={isProductionUrl(isProduction)}
                        // target="_blank"
                      >
                        <i className="fas fa-sign-in-alt"></i> &nbsp; Sign In
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Navigation */}
            </nav>
            {/* End Nav */}
          </div>
        </div>
      </header>
      {/* ========== END HEADER ========== */}
    </div>
  );
};
export default Header;

// import _ from "lodash";
import { cnt } from "../../constant.action";

const INITIAL_STATE = {
  success_response: {},
  failed_response: {},
  countries: [],
  provinces: [],
  cities: [],
  blockDetail: {},
};
export const staticDataRed = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case cnt.RESPONSE_SUCCESS_DATA:
      return { ...state, success_response: payload };

    case cnt.RESPONSE_FAILED_DATA:
      return { ...state, failed_response: payload };

    case cnt.COUNTRY_DATA:
      return { ...state, countries: payload };

    case cnt.BLOCK_DATA:
      return { ...state, blockDetail: payload };

    case cnt.CLEAR_STATIC_DATA:
      return { ...INITIAL_STATE };

    /** here will code for provinces and cities so far we are using only countrie */
    default:
      return state;
  }
};

import React, { Fragment, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Footer from "../ui-setup/Footer";

const NewsArticle= () => {
  useEffect(() => {}, []);
  return (
    <div>
      {/* ========== HEADER ========== */}
      <header
        id="header"
        className="header header-box-shadow-on-scroll header-abs-top header-bg-transparent header-show-hide"
        data-hs-header-options='{
      "fixMoment": 1000,
      "fixEffect": "slide"
    }'
      >
        {/* Search */}
        <div id="searchPushTop" className="search-push-top">
          <div className="container position-relative">
            <div className="search-push-top-content pt-3">
              {/* Close Button */}
              <div className="search-push-top-close-btn">
                <div className="hs-unfold">
                  <a
                    className="js-hs-unfold-invoker btn btn-icon btn-xs btn-soft-secondary mt-2 mr-2"
                    href="#"
                    data-hs-unfold-options='{
            "target": "#searchPushTop",
            "type": "jquery-slide",
            "contentSelector": ".search-push-top"
           }'
                  >
                    <svg
                      width={10}
                      height={10}
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* End Close Button */}
              {/* Input */}
              <form className="input-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Front"
                  aria-label="Search Front"
                />
                <div className="input-group-append">
                  <button type="button" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </form>
              {/* End Input */}
            </div>
          </div>
        </div>
        {/* End Search */}
        <div className="header-section">
          {/* Topbar */}
          <div className="container header-hide-content pt-2">
            <div className="d-flex align-items-center">
              {/* Language */}
              {/* <div className="hs-unfold">
                <a
                  className="js-hs-unfold-invoker dropdown-nav-link dropdown-toggle d-flex align-items-center"
                  href="#"
                  data-hs-unfold-options='{
          "target": "#languageDropdown",
          "type": "css-animation",
          "event": "hover",
          "hideOnScroll": "true"
         }'
                >
                  <img
                    className="dropdown-item-icon mr-2"
                    src="../../assets/vendor/flag-icon-css/flags/4x3/us.svg"
                    alt="SVG"
                  />
                  <span className="d-inline-block d-sm-none">US</span>
                  <span className="d-none d-sm-inline-block">English</span>
                </a>
              </div> */}
              {/* End Language */}
              <div className="ml-auto">
                {/* Links */}
                {/* <div className="nav nav-sm nav-y-0 d-none d-sm-flex ml-sm-auto">
                  <a className="nav-link" href="../pages/faq.html">
                    Help
                  </a>
                  <a className="nav-link" href="../pages/contacts-agency.html">
                    Contacts
                  </a>
                </div> */}

<div className="nav nav-sm nav-y-0 d-none d-sm-flex ml-sm-auto">
              <a className="nav-link" href="../pages/faq.html">Sign up</a>
              <a className="nav-link" href="../pages/contacts-agency.html">Rewards</a>
            </div>
                {/* End Links */}
              </div>
            </div>
          </div>
          {/* End Topbar */}
          <div id="logoAndNav" className="container">
            {/* Nav */}
            <nav className="js-mega-menu navbar navbar-expand-lg">
              {/* Logo */}
              <a className="navbar-brand" href="index.html" aria-label="Front">
                <img
                  src="../../assets/svg/logos/nacomoda_edit1.png"
                  alt="Logo"
                />
              </a>
              {/* End Logo */}
              {/* Responsive Toggle Button */}
              <button
                type="button"
                className="navbar-toggler btn btn-icon btn-sm rounded-circle"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
              >
                <span className="navbar-toggler-default">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
                    />
                  </svg>
                </span>
                <span className="navbar-toggler-toggled">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                    />
                  </svg>
                </span>
              </button>
              {/* End Responsive Toggle Button */}

              {/* Navigation */}
              <div id="navBar" className="collapse navbar-collapse">
                <div className="navbar-body header-abs-top-inner">
                  <ul className="navbar-nav">

                  <NavLink to = "/home" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Home</NavLink>
                  <NavLink to = "/about" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false" >About us</NavLink>
                  <NavLink to = "/versions" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false" >Versions</NavLink>
                  <NavLink to = "/bookings" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Bookings</NavLink>
                  <NavLink to = "/contact" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Contact</NavLink>
                  <NavLink to = "/news" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">News</NavLink>
                  <NavLink to = "/faq" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">FAQ</NavLink>
                
                    {/* Button */}
                    <li className="navbar-nav-last-item">
                      <a
                        className="btn btn-sm btn-primary transition-3d-hover"
                        href="https://backoffice.nacomoda.com/"
                        target="_blank"
                      >
                        <i className="fas fa-home"></i>&nbsp;Back Office
                      </a>
                    </li>
                    {/* End Button */}
                  </ul>
                </div>
              </div>



              
              {/* End Navigation */}
            </nav>
            {/* End Nav */}
          </div>
        </div>
      </header>
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
     <main id="content" role="main">
     
    
    <div className="container space-top-2 space-bottom-2">
   
      <div className="w-lg-60 mx-lg-auto">
      <div className="my-4 my-sm-8">
        <img className="img-fluid rounded" src="../../assets/svg/logos/news2.jpg" alt="Image Description"/>
      </div>
        <div className="mb-4">
         <center> <h1 className="h2">News Title</h1></center>
        </div>

      
        <div className="border-top border-bottom py-4 mb-5">
          <div className="row align-items-md-center">
            <div className="col-md-7 mb-5 mb-md-0">
              <div className="media align-items-center">
                <div className="avatar avatar-circle">
                  <img className="avatar-img"  src="../../assets/svg/logos/nacomoda_edit1.png" alt="Image Description"/>
                </div>
                <div className="media-body font-size-1 ml-3">
                  <span className="h6"><a href="blog-profile.html">Company name</a> </span>
                  <span className="d-block text-muted">Date</span>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex justify-content-md-end align-items-center">
                <span className="d-block small font-weight-bold text-cap mr-2">Share:</span>

                <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.facebook.com">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.twitter.com">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.instagram.com">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.whatsapp.com">
                  <i className="fab fa-whatsapp"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        

        <p>Article content
  
        </p>
      </div>

     

      <div className="w-lg-60 mx-lg-auto">
        <p>Article content</p>

        <p>Article content</p>

        <blockquote className="bg-soft-primary border-0 rounded text-center text-dark font-size-2 font-weight-bold text-lh-lg p-5 my-5">
          "Real estate is the only estate that's real"
          <span className="d-block text-muted font-size-1 mt-2">- nAcomoda</span>
        </blockquote>

       
        <ul className="list-article">
          <li>Article content</li>
          <li>Article content</li>
        </ul>
      </div>

     

      <div className="w-lg-60 mx-lg-auto">
       

        <div className="card bg-img-hero bg-navy text-white text-center p-4 my-4">
          <h4 className="text-white mb-3">Like what you're reading? Subscribe to our top stories.</h4>

        
          <form className="js-validate w-md-75 mx-md-auto">
            <div className="js-form-message">
              <div className="d-flex align-items-center">
                <label className="sr-only" for="subscribeSrArticle">Subscribe</label>
                <div className="input-group">
                  <input type="email" className="form-control" id="subscribeSrArticle" placeholder="Your email" aria-label="Your email"/>
                </div>
                <button type="submit" className="btn btn-light ml-3">Submit</button>
              </div>
            </div>
          </form>
        
        </div>

        

       

      
  
       
      
       
        <div className="media align-items-center border-top border-bottom py-5 my-8">
          <div className="avatar avatar-circle avatar-xl">
            <img className="avatar-img"  src="../../assets/svg/logos/nacomoda_edit1.png" alt="Image Description"/>
          </div>
          <div className="media-body ml-3">
            <small className="d-block small font-weight-bold text-cap">Posted by</small>
            <h3 className="mb-0">Company name</h3>
            <div className="row justify-content-sm-between align-items-sm-center mt-5">
          <div className="col-sm-6 mb-2 mb-sm-0">
            <div className="d-flex align-items-center">
              <span className="d-block small font-weight-bold text-cap mr-2">Share:</span>

              <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.facebook.com">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.twitter.com">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.instagram.com">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" target={`_blank`} href="https://www.whatsapp.com">
                  <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>

        </div>
          </div>
        </div>
      

        
        
      </div>
    </div>
   

    
    </main>
 
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

export default NewsArticle;

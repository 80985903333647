import React from "react";
import appLabel from "../../config/appLabel";
import { BOM } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { Carousel } from "react-carousel-minimal";

export const AcomListFac = (props) => {
  const { arrList, currency } = props;
  return (
    <div className="row mb-5">
      {arrList.length === 0 && (
        <div className="alert bg-warning text-white">
          <strong>{appLabel.noFoundSearch}</strong>
        </div>
      )}
      {arrList.map((item, index) => (
        <article className="col-md-6 col-lg-3 mb-3" key={index}>
          <div
            className="card border h-100"
            style={{
              boxShadow: "0 6px 6px -3px gray",
            }}
          >
            <div className="card-img-top position-relative">
              {item.DOCUMENT ? (
                <img
                  className="card-img-top img-fluid"
                  style={{ width: "338px", height: "227px" }}
                  src={`${item.DOCUMENT}`}
                />
              ) : (
                <img
                  className="card-img-top"
                  style={{ height: "227px" }}
                  src="../../assets/svg/logos/student.jpg"
                  alt="Image Description"
                />
              )}
            </div>
            <div className="card-body">
              <div className="mb-2">
                <h5>
                  <a className="text-inherit">{item.CAT_NAME} </a>
                </h5>
              </div>
              <small className="d-block small font-weight-bold mb-2">
                <i className="fa fa-building text-danger"></i>&nbsp;
                {item.LO_NAME}&nbsp;-&nbsp;<i>{item.CO_NAME}</i>
              </small>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center ml-auto">
                  <div className="small text-muted">
                    <i className="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                    {item.LO_ADDRESS}, {item.CITY_NAME}, {item.STATE_NAME}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2">
                  <span className="text-dark">
                    <NumberFormat
                      value={item.CAT_PRICE}
                      className="text-success font-weight-bold"
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={item.CUR_CODE + " "}
                    />
                    <small className="text-secondary ">
                      {appLabel.perMonth}
                    </small>
                  </span>
                </div>
                <Link
                  to={{
                    pathname: "/booking-detail-student",
                    state: {
                      ggParam: item,
                    },
                  }}
                  className="btn btn-xs btn-primary transition-3d-hover"
                >
                  <i className="fa fa-arrow-circle-right text-danger" />
                  &nbsp; {appLabel.btnPreview}
                </Link>
              </div>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};
export const GuestListFac = (props) => {
  const { arrList, currency } = props;
  return (
    <div className="row mb-5">
      {arrList.length === 0 && (
        <div className="alert bg-warning text-white">
          <strong>{appLabel.noFoundSearch}</strong>
        </div>
      )}
      {arrList.map((item, index) => (
        <article className="col-md-6 col-lg-3 mb-3" key={index}>
          <div
            className="card border h-100"
            style={{
              boxShadow: "0 6px 6px -3px gray",
            }}
          >
            <div className="card-img-top position-relative">
              {item.DOCUMENT ? (
                <img
                  className="card-img-top img-fluid"
                  style={{ width: "338px", height: "227px" }}
                  src={`${item.DOCUMENT}`}
                />
              ) : (
                <img
                  className="card-img-top"
                  style={{ height: "227px" }}
                  src="../../assets/svg/logos/guest.jpg"
                  alt="Image Description"
                />
              )}
            </div>
            <div className="card-body">
              <div className="mb-2">
                <h5>
                  <a className="text-inherit">{item.SPACE_NAME}</a>
                </h5>
              </div>
              <small className="d-block small font-weight-bold mb-2">
                <i className="fa fa-building text-primary"></i>&nbsp;
                {item.LO_NAME}&nbsp;-&nbsp;<i>{item.CO_NAME}</i>
              </small>

              {/* <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center ml-auto">
                           <div className="small text-muted">
                          <i className="fa fa-bed text-primary" >&nbsp;3</i>&nbsp; 
                        <i className="fa fa-bath text-primary">&nbsp;2</i>&nbsp;
                        <i className="fa fa-television text-primary">&nbsp;1</i>&nbsp; 
                        <i className=" fa fa-cutlery text-primary">&nbsp;1</i>&nbsp; 
                        <i className="fa fa-car text-primary">&nbsp;1</i>&nbsp;
                          </div> 
                          <small className="text-muted mx-2">|</small>
                           <div className="small text-muted">
                        <i className="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div> 
                        </div>
                      </div> */}
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center ml-auto">
                  <div className="small text-muted">
                    <i className="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                    {item.LO_ADDRESS}, {item.CITY_NAME}, {item.STATE_NAME}
                  </div>
                  {/* <div className="small text-muted">
                        <i className="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div> */}
                </div>
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2">
                  <span className="text-secondary">
                    {item.PRICE_TYPE.toLowerCase() === "range" && "From "}
                  </span>
                  <span className="text-success d-block h5 text-lh-sm mb-0">
                    {" "}
                    <NumberFormat
                      value={item.CAT_PRICE}
                      className="text-success font-weight-bold"
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={item.CUR_CODE + " "}
                    />
                    <small className="text-secondary ">
                      {appLabel.perNight}
                    </small>
                  </span>
                  <span>
                    {item.MAX_NO_GUEST}&nbsp;<small>Guests Max</small>{" "}
                  </span>
                </div>
                <Link
                  to={{
                    pathname: "/booking-detail-guest",
                    state: {
                      ggParam: item,
                    },
                  }}
                  className="btn btn-xs btn-primary transition-3d-hover"
                >
                  <i className="fa fa-arrow-circle-right text-danger" />
                  &nbsp;
                  {appLabel.btnPreview}
                </Link>
              </div>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};

export const ImgCarouselFac = (props) => {
  const { imageData, automatic } = props;
  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <>
      <Carousel
        data={imageData}
        automatic={automatic}
        width="600px"
        height="400px"
        radius="10px"
        slideNumber={true}
        slideNumberStyle={slideNumberStyle}
        captionPosition="bottom"
        dots={true}
        pauseIconColor="white"
        pauseIconSize="40px"
        slideBackgroundColor="darkgrey"
        slideImageFit="cover"
        style={{
          textAlign: "center",
          maxWidth: "850px",
          maxHeight: "500px",
          margin: "40px auto",
          margin: "40px auto",
        }}
      />
    </>
  );
};
export const SearchAcomListFac = (props) => {
  const { arrList, currency } = props;
  return (
    <div className="row mb-5">
      {arrList.length === 0 && (
        <div className="alert bg-warning text-white">
          <strong>{appLabel.noFoundSearch}</strong>
        </div>
      )}
      <div className="col-md-12">
        <div className="alert bg-success text-white">
          <strong>
            {arrList.length > 1
              ? arrList.length + " results "
              : arrList.length + " result "}{" "}
            found
          </strong>
        </div>
      </div>
      {arrList.map((item, index) => (
        <article className="col-md-6 col-lg-3 mb-3" key={index}>
          <div
            className="card border h-100"
            style={{
              boxShadow: "0 6px 6px -3px gray",
            }}
          >
            <div className="card-img-top position-relative">
              {item.DOCUMENT ? (
                <img
                  className="card-img-top img-fluid"
                  style={{ width: "338px", height: "227px" }}
                  src={`${item.DOCUMENT}`}
                />
              ) : (
                <img
                  className="card-img-top"
                  style={{ height: "227px" }}
                  src="../../assets/svg/logos/student.jpg"
                  alt="Image Description"
                />
              )}
            </div>
            <div className="card-body">
              <div className="mb-2">
                <h5>
                  <a className="text-inherit">{item.CAT_NAME} </a>
                </h5>
              </div>
              <small className="d-block small font-weight-bold mb-2">
                <i className="fa fa-building text-danger"></i>&nbsp;
                {item.LO_NAME}&nbsp;-&nbsp;<i>{item.CO_NAME}</i>
              </small>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center ml-auto">
                  <div className="small text-muted">
                    <i className="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                    {item.LO_ADDRESS}, {item.CITY_NAME}, {item.STATE_NAME}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2">
                  <span className="text-dark">
                    <NumberFormat
                      value={item.CAT_PRICE}
                      className="text-success font-weight-bold"
                      displayType={"text"}
                      thousandSeparator={true}
                      // prefix={currency && currency + " "}
                      prefix={item.CUR_CODE + " "}
                    />
                    <small className="text-secondary ">
                      {appLabel.perMonth}
                    </small>
                  </span>
                </div>
                <Link
                  to={{
                    pathname: "/booking-detail-student",
                    state: {
                      ggParam: item,
                    },
                  }}
                  className="btn btn-xs btn-primary transition-3d-hover"
                >
                  <i className="fa fa-arrow-circle-right text-danger" />
                  &nbsp; {appLabel.btnPreview}
                </Link>
              </div>
            </div>
          </div>
        </article>
      ))}
      <div className="col-md-12">
        <hr />
      </div>
    </div>
  );
};
export const SearchGuestListFac = (props) => {
  const { arrList, currency } = props;
  return (
    <div className="row mb-5">
      {arrList.length === 0 && (
        <div className="alert bg-warning text-white">
          <strong>{appLabel.noFoundSearch}</strong>
        </div>
      )}
      <div className="col-md-12">
        <div className="alert bg-success text-white">
          <strong>
            {arrList.length > 1
              ? arrList.length + " results "
              : arrList.length + " result "}{" "}
            found
          </strong>
        </div>
      </div>
      {arrList.map((item, index) => (
        <article className="col-md-6 col-lg-3 mb-3" key={index}>
          <div
            className="card border h-100"
            style={{
              boxShadow: "0 6px 6px -3px gray",
            }}
          >
            <div className="card-img-top position-relative">
              {item.DOCUMENT ? (
                <img
                  className="card-img-top img-fluid"
                  style={{ width: "338px", height: "227px" }}
                  src={`${item.DOCUMENT}`}
                />
              ) : (
                <img
                  className="card-img-top"
                  style={{ height: "227px" }}
                  src="../../assets/svg/logos/guest.jpg"
                  alt="Image Description"
                />
              )}
            </div>
            <div className="card-body">
              <div className="mb-2">
                <h5>
                  <a className="text-inherit">{item.SPACE_NAME}</a>
                </h5>
              </div>
              <small className="d-block small font-weight-bold mb-2">
                <i className="fa fa-building text-primary"></i>&nbsp;
                {item.LO_NAME}&nbsp;-&nbsp;<i>{item.CO_NAME}</i>
              </small>

              {/* <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center ml-auto">
                           <div className="small text-muted">
                          <i className="fa fa-bed text-primary" >&nbsp;3</i>&nbsp; 
                        <i className="fa fa-bath text-primary">&nbsp;2</i>&nbsp;
                        <i className="fa fa-television text-primary">&nbsp;1</i>&nbsp; 
                        <i className=" fa fa-cutlery text-primary">&nbsp;1</i>&nbsp; 
                        <i className="fa fa-car text-primary">&nbsp;1</i>&nbsp;
                          </div> 
                          <small className="text-muted mx-2">|</small>
                           <div className="small text-muted">
                        <i className="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div> 
                        </div>
                      </div> */}
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center ml-auto">
                  <div className="small text-muted">
                    <i className="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                    {item.LO_ADDRESS}, {item.CITY_NAME}, {item.STATE_NAME}
                  </div>
                  {/* <div className="small text-muted">
                        <i className="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div> */}
                </div>
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2">
                  <span className="text-secondary">
                    {item.PRICE_TYPE.toLowerCase() === "range" && "From "}
                  </span>
                  <span className="text-success d-block h5 text-lh-sm mb-0">
                    {" "}
                    <NumberFormat
                      value={item.CAT_PRICE}
                      className="text-success font-weight-bold"
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={item.CUR_CODE + " "}
                    />
                    <small className="text-secondary ">
                      {appLabel.perNight}
                    </small>
                  </span>
                  <span>
                    {item.MAX_NO_GUEST}&nbsp;<small>Guests Max</small>{" "}
                  </span>
                </div>
                <Link
                  to={{
                    pathname: "/booking-detail-guest",
                    state: {
                      ggParam: item,
                    },
                  }}
                  className="btn btn-xs btn-primary transition-3d-hover"
                >
                  <i className="fa fa-arrow-circle-right text-danger" />
                  &nbsp;
                  {appLabel.btnPreview}
                </Link>
              </div>
            </div>
          </div>
        </article>
      ))}

      <div className="col-md-12">
        <hr />
      </div>
    </div>
  );
};
// export const BookingListFac = (props) => {
//   const { bookingList, currency } = props;
//   return (
//     <div className="row mb-5">
//       {bookingList.map((item, index) => (
//         <article className="col-md-6 col-lg-4 mb-5" key={index}>
//           <div
//             className="card border h-100"
//             style={{
//               // borderRadius: "15px",
//               boxShadow: "0 6px 6px -3px gray",
//             }}
//           >
//             <div className="card-img-top position-relative">
//               {item.DOCUMENT ? (
//                 <img
//                   className="card-img-top img-fluid"
//                   style={{ width: "338px", height: "227px" }}
//                   src={`${item.DOCUMENT}`}
//                 />
//               ) : (
//                 <img
//                   className="card-img-top"
//                   style={{ height: "227px" }}
//                   src="../../assets/svg/logos/student.jpg"
//                   alt="Image Description"
//                 />
//               )}
//             </div>
//             <div className="card-body">
//               <div className="mb-3">
//                 <h3>
//                   <a className="text-inherit">{item.CAT_NAME} </a>
//                 </h3>
//               </div>
//               <small className="d-block small font-weight-bold mb-2">
//                 <i className="fa fa-building text-danger"></i>&nbsp;
//                 {item.LO_NAME}&nbsp;-&nbsp;<i>{item.CO_NAME}</i>
//               </small>
//               <div className="mb-3">
//                 <span>
//                   <i>
//                     {item.CAT_DESCRIPTION.length <= 80
//                       ? BOM.Uwfirst(item.CAT_DESCRIPTION)
//                       : BOM.Uwfirst(item.CAT_DESCRIPTION.substring(0, 80)) +
//                         "..."}
//                   </i>
//                   <i>
//                     {item.CAT_DESCRIPTION.length <= 0 &&
//                       BOM.Uwfirst("No description")}
//                   </i>
//                 </span>
//               </div>
//               <div className="d-flex align-items-center">
//                 <div className="d-flex align-items-center ml-auto">
//                   <div className="small text-muted">
//                     <i className="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
//                     {item.LO_ADDRESS}, {item.CITY_NAME}, {item.STATE_NAME}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="card-footer border-0 pt-0">
//               <div className="d-flex justify-content-between align-items-center">
//                 <div className="mr-2">
//                   <span className="text-dark">
//                     <NumberFormat
//                       value={item.CAT_PRICE}
//                       className="text-success font-size-2 font-weight-bold"
//                       displayType={"text"}
//                       thousandSeparator={true}
//                       prefix={currency && currency + " "}
//                     />
//                     <small className="text-secondary ">
//                       {appLabel.perMonth}
//                     </small>

//                     <br />
//                     <span
//                       className="text-dark"
//                       style={{
//                         fontSize: "16px",
//                         fontWeight: "bold",
//                       }}
//                     >
//                       <span className="text-secondary ">
//                         {/* <small>x available</small>{" "} */}
//                       </span>
//                     </span>
//                   </span>
//                 </div>
//                 <Link
//                   to={{
//                     pathname: "/booking-detail-student",
//                     state: {
//                       ggParam: item,
//                     },
//                   }}
//                   className="btn btn-sm btn-primary transition-3d-hover"
//                 >
//                   <i className="fa fa-arrow-circle-right text-danger" />
//                   &nbsp;
//                   {appLabel.btnPreview}
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </article>
//       ))}
//     </div>
//   );
// };


import React, { Fragment, state, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";
import appStatic from "../../config/appStaticData";
import appLabel from "../../config/appLabel";
import { BOM } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
// constructor(props) {
//   super(props);
//   this.state = {
//     name: '',
//     companyName: '',
//     reason: '',
//     message: '',
//     contact: '',
//     email: '',
//     mailSent: false,
//     error: null
//   }
// }


// handleFormSubmit( event ) {
//   event.preventDefault();
//   console.log(this.state);
// }

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  useEffect(() => {window.scrollTo(0, 0) }, []);

  const formSubmit = (formValues) => {
    // console.log("data", formValues);
   
 

    let body = {
     
      ...formValues,
    };

    console.log(body);
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(
      body,
      api.NewContact,
      "/home"
    );
  };

  return (

    <div className="overflow-hidden">
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        <div className="container position-relative z-index-4 space-top-2 space-top-md-4 space-top-lg-3">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-md-10 col-lg-5 mb-9 mb-lg-0">
              <div className="mb-9">
                <br></br> <br></br><h1 className="display-4 mb-4"> {/* Content */}
           
              <div className="row">
                <div className="col-lg-9">
                  <div className="mb-1 mt-11">
                    <h1 className="display-5 mb-1">
                      We are Making Housing
                      <span className="text-primary text-highlight-warning">
                        &nbsp;
                        <span className="js-text-animation" />
                      </span>
                    </h1>
                   
                  </div>
                </div>
              </div>
           
            {/* End Content */}</h1>
                <p className="lead">{appLabel.contactParagraph}</p>
              </div>
              <img className="img-fluidA" src="../../assets/svg/logos/Contact.jpg" alt="Image Description" />
            </div>
            <div className="col-lg-6">
              <br></br> <br></br>
              <form action="" className="formcontact" method="post"  autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
                <div className="card-header bg-light text-center py-4 px-5 px-md-6">
                  <p className="lead">{appLabel.formHeader}</p>
                </div>
                <div className="card-body p-4 p-md-6">
                  <div className="row">
                    <div className="js-form-message form-group">
                      Hello nAcomoda. <br></br>My name is<input type="text" className="form-controlA"  {...register("visitorName", {required: "Field is required.",})} placeholder="full name* " />
                      <span className="asterisks">
                        {/* {errors?.visitorName?.message} */}
                      </span> from  
                      <input type="text" className="form-controlB"  {...register("visitorCompany", {required: "Field is required.",})} placeholder="company "
                      /><br></br>and I would like to
                      <select className="form-controlCC" {...register("visitorReason", {required: "Field is required.",})}>
                        <option value="" >{appLabel.contactReason}*</option>
                        {appStatic.reason.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.name}
                          </option>
                        ))}
                      </select><br></br>Tell us more*:
                      <input type="text" className="form-controlF" rows="3" {...register("visitorMessage", {required: "Field is required.",})} 
                       /><br></br><br></br> Please contact me at <input type="email" className="form-controlD" {...register("visitorEmail", {required: "Field is required.",})} placeholder="email*"/> 
                       and <input type="text" className="form-controlE" {...register("visitorContact", {required: "Field is required.",})} placeholder="contact no"
                  
                      />
                    </div>
                  </div>
                  <br></br><br></br>
                  <button type="submit" className="btn btn-block btn-primary">{appLabel.submit}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </main>
      {/* ========== END MAIN CONTENT ========== */}

      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};
export default Contact;

export const cnt = {
  // ALERT
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  INFO: "INFO",
  PROCESS: "PROCESS",
  LOAD: "LOAD",
  COPIED: "COPIED",

  // STATIC DATA
  RESPONSE_SUCCESS_DATA: "RESPONSE_SUCCESS_DATA",
  RESPONSE_FAILED_DATA: "RESPONSE_FAILED_DATA",
  COUNTRY_DATA: "COUNTRY_DATA",
  BLOCK_DATA: "BLOCK_DATA",
  CLEAR_STATIC_DATA: "CLEAR_STATIC_DATA",
};

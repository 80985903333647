import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";
import appLabel from "../../config/appLabel";
import history from "../../history";
import appConfig from "../../config/appConfig";

const Versions = () => {
  const [version, setVersion] = useState(2);
  const [user, setUser] = useState(0);
  const [day, setDay] = useState(0);
  const [total, setTotal] = useState(0);

  const { dev, prod, isProduction, apiSettings } = appConfig;

  const isProductionUrl = (key) => {
    switch (key) {
      case true:
        return prod.officeURL;
      case false:
        return dev.officeURL;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const calc = () => {
    setTotal(parseFloat(version) * parseFloat(user) * parseFloat(day));
  };

  const getStarted = () => {
    window.location.href = isProductionUrl(isProduction) + "/#/new-account";
  };

  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        <div id="featuresSection" className="container space-2 space-lg-3">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-5 mb-9 mb-lg-0">
              <div className="titleversion">
                <h2 className="h1">We have versions catered to your needs</h2>
              </div>
              <p>
                With the desire to give you the freedom to choose how much you
                give us, we have designed a pricing model allowing you to choose
                not only the version of the system you want to use but also the
                number of people you want to manage and for how many days. So
                you don’t have to pay us monthly if you don’t want to. We also
                don’t take any commission in the customers we bring you.
              </p>
            </div>
            <div className="col-lg-6 col-xl-5">
              <div className="position-relative min-h-500rem mx-auto">
                <figure
                  className="position-absolute top-0 right-0 z-index-2 mr-11"
                  data-aos="fade-up"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 450 450"
                    width="165"
                    height="165"
                  >
                    <g>
                      <defs>
                        <path
                          id="circleImgID2"
                          d="M225,448.7L225,448.7C101.4,448.7,1.3,348.5,1.3,225l0,0C1.2,101.4,101.4,1.3,225,1.3l0,0
                          c123.6,0,223.7,100.2,223.7,223.7l0,0C448.7,348.6,348.5,448.7,225,448.7z"
                        />
                      </defs>
                      <clipPath id="circleImgID1">
                        <use href="#circleImgID2" />
                      </clipPath>
                      <g clip-path="url(#circleImgID1)">
                        <image
                          width="450"
                          height="450"
                          xlinkHref="../../assets/svg/logos/Version2.jpg"
                        ></image>
                      </g>
                    </g>
                  </svg>
                </figure>
                <figure
                  className="position-absolute top-0 left-0"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 335.2 335.2"
                    width="120"
                    height="120"
                  >
                    <circle
                      fill="none"
                      stroke="#377DFF"
                      strokeWidth="75"
                      cx="167.6"
                      cy="167.6"
                      r="130.1"
                    />
                  </svg>
                </figure>
                <figure
                  className="d-none d-sm-block position-absolute top-0 left-0 mt-11"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 515 515"
                    width="200"
                    height="200"
                  >
                    <g>
                      <defs>
                        <path
                          id="circleImgID4"
                          d="M260,515h-5C114.2,515,0,400.8,0,260v-5C0,114.2,114.2,0,255,0h5c140.8,0,255,114.2,255,255v5
                          C515,400.9,400.8,515,260,515z"
                        />
                      </defs>
                      <clipPath id="circleImgID3">
                        <use href="#circleImgID4" />
                      </clipPath>
                      <g clip-path="url(#circleImgID3)">
                        <image
                          width="515"
                          height="515"
                          href="../../assets/svg/logos/Version1.jpg"
                          transform="matrix(1 0 0 1 1.639390e-02 2.880859e-02)"
                        ></image>
                      </g>
                    </g>
                  </svg>
                </figure>
                <figure
                  className="position-absolute top-0 right-0"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 67 67"
                    width="25"
                    height="25"
                  >
                    <circle fill="#00C9A7" cx="33.5" cy="33.5" r="33.5" />
                  </svg>
                </figure>
                <figure
                  className="position-absolute top-0 right-0 mr-3"
                  data-aos="fade-up"
                  data-aos-delay="350"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 141 141"
                    width="50"
                    height="50"
                  >
                    <circle fill="#FFC107" cx="70.5" cy="70.5" r="70.5" />
                  </svg>
                </figure>
                <figure
                  className="position-absolute bottom-0 right-0"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 770.4 770.4"
                    width="280"
                    height="280"
                  >
                    <g>
                      <defs>
                        <path
                          id="circleImgID6"
                          d="M385.2,770.4L385.2,770.4c212.7,0,385.2-172.5,385.2-385.2l0,0C770.4,172.5,597.9,0,385.2,0l0,0
                          C172.5,0,0,172.5,0,385.2l0,0C0,597.9,172.4,770.4,385.2,770.4z"
                        />
                      </defs>
                      <clipPath id="circleImgID5">
                        <use href="#circleImgID6" />
                      </clipPath>
                      <g clip-path="url(#circleImgID5)">
                        <image
                          width="900"
                          height="900"
                          href="../../assets/svg/logos/Versions.jpg"
                          transform="matrix(1 0 0 1 -64.8123 -64.8055)"
                        ></image>
                      </g>
                    </g>
                  </svg>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="verse">
          <h2>{appLabel.versionPricingDescriptionHead}</h2>
          <p>{appLabel.versionPricingDescriptionP}</p>
        </div>
        <div className="overflow-hidden">
          <div className="container pb-4">
            <div className="row mx-lg-5">
              <div className="col-sm-6 col-lg-4 px-lg-5 mb-5">
                <div className="card shadow-none h-100">
                  <div className="card-header border-0 text-center">
                    <span className="d-block h3 mb-0">
                      {appLabel.versionOne}
                    </span>
                    <span className="d-block">
                      <span className="font-size-2 text-primary font-weight-bold">
                        <span className="mr-n2">R&nbsp;</span>
                        <span>{appLabel.versionOnePrice}</span>
                      </span>
                      <span className="font-size-1">{appLabel.perUser}</span>
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.versionOneResidence}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.versionOneCategory}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.versionOneBlock}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.versionOneRoles}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.versionOneFeeds}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.versionOneUsers}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.versionOneDays}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer border-0">
                    <button
                      type="button"
                      onClick={() => getStarted()}
                      className="versionsbutton"
                    >
                      {appLabel.getStarted}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 px-lg-5 mb-5">
                <div className="position-relative">
                  <div className="card shadow-lg h-100">
                    <div className="card-header border-0 text-center">
                      <span className="d-block h3 mb-0">
                        {" "}
                        {appLabel.versionGuarantees}{" "}
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="media font-size-1 mb-3">
                        <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                        <div className="media-body">
                          {appLabel.versionGuaranteesRisk}
                        </div>
                      </div>
                      <div className="media font-size-1 mb-3">
                        <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                        <div className="media-body">
                          {appLabel.versionGuaranteesContract}
                        </div>
                      </div>
                      <div className="media font-size-1 mb-3">
                        <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                        <div className="media-body">
                          {appLabel.versionGuaranteesFunctional}
                        </div>
                      </div>
                      <div className="media font-size-1 mb-3">
                        <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                        <div className="media-body">
                          {appLabel.versionGuaranteesTraining}
                        </div>
                      </div>
                      <div className="media font-size-1 mb-3">
                        <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                        <div className="media-body">
                          {appLabel.versionGuaranteesCreditCards}
                        </div>
                      </div>
                    </div>
                  </div>
                  <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img
                        className="img-fluid"
                        src="../../assets/svg/components/dots-2.svg"
                        alt="Image Description"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 px-lg-5 mb-5">
                <div className="card shadow-none h-100">
                  <div className="card-header border-0 text-center">
                    <span className="d-block h3 mb-0">
                      {appLabel.freeVersion}{" "}
                    </span>
                    <span className="d-block">
                      <span className="font-size-2 text-primary font-weight-bold">
                        <span className="mr-n2">R&nbsp;</span>
                        <span>{appLabel.freeVersionPrice}</span>
                      </span>
                      <span className="font-size-1">{appLabel.perUser}</span>
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.freeVersionResidence}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.freeVersionCategory}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.freeVersionBlock}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.freeVersionRoles}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.freeVersionFeeds}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.freeVersionUsers}
                      </div>
                    </div>
                    <div className="media font-size-1 text-body mb-3">
                      <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                      <div className="media-body">
                        {appLabel.freeVersionDays}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer border-0">
                    <button
                      type="button"
                      onClick={() => getStarted()}
                      className="versionsbutton"
                    >
                      {appLabel.getStarted}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 className="text-center">{appLabel.versionCalDescription}</h3>
          <br></br>
          <div className="calc">
            <div className="row mx-n2 ml- mb-5 mb-md-9">
              <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
                <a className="card h-100 transition-3d-hover">
                  <div className="card-body">
                    <span className="d-block small font-weight-bold text-cap mb-2">
                      {appLabel.versionSelect}
                    </span>
                    <select
                      className="form-controlversion"
                      onKeyPress={() => calc()}
                      onChange={(e) => setVersion(e.target.value)}
                    >
                      {/* <option value="" selected disabled>{appLabel.selectVersion}</option> */}
                      <option value={2}>{appLabel.versionOne}</option>
                    </select>
                  </div>
                </a>
              </div>
              <div className="col-sm-6 col-lg-2 px-2 mb-3 mb-lg-0">
                <a className="card h-100 transition-3d-hover">
                  <div className="card-body">
                    <span className="d-block small font-weight-bold text-cap mb-2">
                      {appLabel.versionNoOfUsers}
                    </span>
                    <input
                      type="number"
                      className="form-controluser"
                      placeholder="0"
                      min={0}
                      onChange={(e) => setUser(e.target.value)}
                    />
                  </div>
                </a>
              </div>
              <div className="col-sm-6 col-lg-2 px-2 mb-3 mb-sm-0">
                <a className="card h-100 transition-3d-hover">
                  <div className="card-body">
                    <span className="d-block small font-weight-bold text-cap mb-2">
                      {appLabel.versionNoOfDays}
                    </span>
                    <input
                      type="number"
                      className="form-controldays"
                      placeholder="0"
                      min={0}
                      onChange={(e) => setDay(e.target.value)}
                    />
                  </div>
                </a>
              </div>
              <div className="col-sm-6 col-lg-2 px-2 space-top-2">
                <button
                  type="submit"
                  onClick={() => calc()}
                  className="btn btn-block btn-primary btn-pill transition-3d-hover"
                >
                  Calculate
                </button>
              </div>
              <div className="col-sm-6 col-lg-3 px-2">
                <a className="card h-100 transition-3d-hover">
                  <div className="card-body">
                    <span className="d-block small font-weight-bold text-cap mb-2">
                      {appLabel.versionAmount}
                    </span>
                    <input
                      type="text"
                      disabled
                      className="form-controlpayable"
                      onChange={() => setTotal(total)}
                      placeholder={total}
                    />
                  </div>
                </a>
              </div>
            </div>
            <div></div>
          </div>
          <h3 className="text-center">{appLabel.versionCalc}</h3>
          <br></br>
          <h3 className="text-center">
            {appLabel.versionFaq}&nbsp;
            <Link
              to="/faq"
              aria-haspopup="true"
              aria-expanded="false"
              aria-labelledby="blogSubMenu"
            >
              FAQ
            </Link>{" "}
            page.
          </h3>
          <h3 className="text-center">
            {appLabel.versionContact}&nbsp;
            <Link
              to="/contact"
              aria-haspopup="true"
              aria-expanded="false"
              aria-labelledby="blogSubMenu"
            >
              contact us
            </Link>
            .
          </h3>
        </div>
        <br></br>
      </main>

      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

export default Versions;

import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      
  <footer className="bg-navy">
  <div className="container">
    <div className="space-top-2 space-bottom-1 space-bottom-lg-2">
      <div className="row justify-content-lg-between">
        <div className="col-lg-3 ml-lg-auto mb-5 mb-lg-0">
          {/* Logo */}
          <div className="mb-4">
            <a href="index.html" aria-label="Front">
              <img className="brand" style={{borderRadius: '10px'}}
                  src="../../assets/svg/logos/nacomoda_edit1.png"
                  alt="Logo"
                />
            </a>
          </div>
          {/* End Logo */}
        </div>
        <div className="col-xs-6 col-md-3 col-lg mb-5 mb-lg-0">
          <h5 className="text-white">Company</h5>
          {/* Nav Link */}
          <ul className="nav nav-sm nav-x-0 nav-white flex-column">
            <Link to = "/home" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Home</Link>
            <Link to = "/features" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false" aria-labelledby="pagesSubMenu">Features</Link>
            <Link to = "/versions" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false" aria-labelledby="blogSubMenu">Versions</Link>
            <Link to = "/booking" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Booking</Link>
          </ul>
          {/* End Nav Link */}
        </div>
        <div className="col-xs-6 col-md-3 col-lg mb-5 mb-lg-0">
          <h5 className="text-white"></h5>
          {/* Nav Link */}
          <ul className="nav nav-sm nav-x-0 nav-white flex-column"><br></br>
            <Link to = "/contact" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Contact</Link>
            <Link to = "/news" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">News</Link>
            <Link to = "/faq" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">FAQ</Link>
          
          </ul>
          {/* End Nav Link */}
        </div>
        <div className="col-xs-6 col-md-3 col-lg mb-5 mb-lg-0">
          <h5 className="text-white">Contact</h5>
          {/* Nav Link */}
          <ul className="nav nav-sm nav-x-0 nav-white flex-column">
            <li className="nav-item"><a className="nav-link" href="#">+27 76 703 4588</a></li>
            <li className="nav-item"><a className="nav-link" href="#">contact@nacomoda.com</a></li>
          </ul>
          {/* End Nav Link */}
        </div>
        <div className="col-6 col-md-3 col-lg mb-5 mb-lg-0">
          <h5 className="text-white">Registration Number</h5>
          {/* Nav Link */}
          <ul className="nav nav-sm nav-x-0 nav-white flex-column">
            <li className="nav-item"><a className="nav-link" href="#">2021/123770/07</a></li>
          </ul>
          {/* End Nav Link */}
        </div>
      </div>
    </div>
    <hr className="opacity-xs my-0" />
    <div className="space-1">
      <div className="row align-items-md-center mb-7">
        <div className="col-md-7 text-md-right">
          <ul className="list-inline mb-0">
            {/* Social Networks */}
            <li className="list-inline-item">
              <a className="btn btn-xs btn-icon btn-soft-light" target={`_blank`} href="https://www.facebook.com/profile.php?id=100074963961286">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li className="list-inline-item">
              <a className="btn btn-xs btn-icon btn-soft-light" target={`_blank`} href="https://www.linkedin.com/company/nacomoda">
                <i className="fab fa-linkedin" />
              </a>
            </li>
            <li className="list-inline-item">
              <a className="btn btn-xs btn-icon btn-soft-light" target={`_blank`} href="https://www.instagram.com/nacomoda_/">
                <i className="fab fa-instagram" />
              </a>
            </li>
            {/* End Social Networks */}
          </ul>
        </div>
      </div>
      {/* Copyright */}
      <div className="w-md-75 text-lg-center mx-lg-auto">
        <p className="text-white opacity-sm small">© 2021 nAcomoda | All rights reserved.</p>
      </div>
      {/* End Copyright */}
    </div>
  </div>
</footer>

    </div>
  );
};

export default Footer;

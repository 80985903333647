import React, { Fragment, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";
import appLabel from "../../config/appLabel";


const About = () => {
  useEffect(() => {window.scrollTo(0, 0)}, []);

  
  return (
    <div>
      {/* ========== HEADER ========== */}
         <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9 space-top-3">
            <br></br><h2 className="h2 text-secondary">We increase your business revenue by offering features that allow you to cut costs, increase your staff productivity and improve your customer’s experience:</h2>

          </div>
          {/* Related Courses */}
        <div className="container border-top space-bottom-2">
        
            
            <div className="row">
            <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 "><a className="text-inherit" >Payment Collection</a></h3>  </center>   
               {/* Article */}         
               <div className="card border  h-70" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
               <center><img className="card-img-top"src="../../assets/svg/illustrations/visa-card.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"50%",
                     height:"50%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                             
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>A click on one button automatically generates invoices for each of your clients with the specific amount they need to pay. You can allow them to pay online and the money goes directly to you.</span>  </center> 
                 </div>
                 
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
             
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 "><a className="text-inherit" >Debt and Balance Control</a></h3>  </center>  
                {/* Article */}         
                <div className="card border h-70" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
                  <div className="card-img-top position-relative">
                  <center><img className="card-img-top"src="../../assets/svg/illustrations/Calculator.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"51%",
                     height:"51%",

                  }} /></center>
                  </div>
                  <div className="card-body">  
                                                 
                    <div className="mb-3">
                    <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>In the event that a client owes the business, you can see the specific amounts per month. If they pay more than the amount required for a specific month, the balance is kept for them, to be used in future payments.</span> </center> 
                    </div>
                    
                  </div>
                  <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
                </div>
                {/* End Article */}
              </article>
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 "><a className="text-inherit" >FREE Marketing</a></h3>  </center>  
                {/* Article */}         
                <div className="card border h-70" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
                  <div className="card-img-top position-relative">
                  <center><img className="card-img-top"src="../../assets/svg/illustrations/Ecommerce-campaign.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"51%",
                     height:"51%",

                  }} /></center>
                  </div>
                  <div className="card-body">  
                                                 
                    <div className="mb-3">
                    <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>Save on marketing by advertising your apartments, guest houses, BnBs and student accommodations for free to get more customers and increase your revenue. Your customers pay to you directly.</span> </center> 
                    </div>
                  
                  </div>
                  <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
                </div>
                {/* End Article */}
              </article>
              
            </div>
            <div className="row">
            <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 space-top-1"><a className="text-inherit" >Reports</a></h3>  </center> 
               {/* Article */}         
               <div className="card border h-80"style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
                 <center><img className="card-img-top"src="../../assets/svg/illustrations/Design-stats.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"50%",
                     height:"50%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                               
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>The system is online based to allow you and your staff members, from wherever you are, to know in real time and at any time which rooms are available and unavailable, which customers have paid and not yet paid, how many move-in requests are being processed (with their details), how many residences, customers, staff and rooms the company currently has and much more.</span>  </center> 
                 </div>
                
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 space-top-1"><a className="text-inherit" >Information Management</a></h3>  </center>  
                {/* Article */}         
                <div className="card border h-80" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
                  <div className="card-img-top position-relative">
                  <center><img className="card-img-top"src="../../assets/svg/illustrations/File-manager.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"50%",
                     height:"50%",

                  }} /></center>
                  </div>
                  <div className="card-body">  
                                                 
                    <div className="mb-3">
                    <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>Forget about spending to print papers and organizing them in folders, by digitalizing everything you used to use papers for, from tenant application forms to proofs of residence. Your clients also send their proofs of payments here. This means you can easily find any information you need, since you don’t go through papers or emails anymore, you just search.</span> </center> 
                    </div>
            
                  </div>
                  <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
                </div>
                {/* End Article */}
              </article>       
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 space-top-1"><a className="text-inherit" >Staff Management</a></h3>  </center>   
               {/* Article */}         
               <div className="card border  h-80" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
                 <center><img className="card-img-top"src="../../assets/svg/illustrations/we-are-in-office-2.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"72%",
                     height:"72%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                             
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>You don’t have to pay for a human resource management software, since we have one integrated that allows you to give to specific staff member only the features they need to do their job properly and increase their productivity. This allows you to monitor what they do if you want.</span>  </center> 
                 </div>
             
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
            </div>
            <div className="row">
            
            <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5" ><a className="text-inherit" >Know Your Customer</a></h3>  </center>  
                {/* Article */}         
                <div className="card border h-80" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
                  <div className="card-img-top position-relative">
                  <center><img className="card-img-top"src="../../assets/svg/illustrations/verified-user.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"40%",
                     height:"40%",

                  }} /></center>
                  </div>
                  <div className="card-body">  
                                                 
                    <div className="mb-3">
                    <span className="d-block large  mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>We know it is a headache to know who your potential customers are before accepting their move in requests, so we do it for you: you get your prospects profile and information about their stay in their previous residences to ease your decision-making process</span> 
                    </div>
                  </div>
                  <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
                </div>
                {/* End Article */}
              </article>
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 "><a className="text-inherit" >Different Residence Types</a></h3>  </center> 
               {/* Article */}         
               <div className="card border  h-80" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
                 <center><img className="card-img-top"src="../../assets/svg/illustrations/multi-family-house.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"46%",
                     height:"46%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                               
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>No need to pay for different platforms to manage your different residence types. Whether you own apartments (in an estate or a complex), guest houses, BnBs or student accommodations, manage ALL of them in this ONE centralized platform with features fitting each residence type.</span>  </center> 
                 </div>
                
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
              
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5"><a className="text-inherit" >Communication</a></h3>  </center>   
               {/* Article */}         
               <div className="card border h-80" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
                 <center><img className="card-img-top"src="../../assets/svg/illustrations/communicating-men.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"61%",
                     height:"61%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                             
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>Create roles (for example managers, security, landlords, tenants, etc.) to group your clients and staff members and easily communicate important information for the business to the relevant groups</span>  </center> 
                 </div>
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
            </div>
            <div className="row">
            <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 space-top-1"><a className="text-inherit" >Data Safety</a></h3>  </center> 
               {/* Article */}         
               <div className="card border h-75"style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
                 <center><img className="card-img-top"src="../../assets/svg/illustrations/Fingerprint-Authentication.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"50%",
                     height:"50%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                               
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>We have a team of expert IT specialists who make sure the data you need to manage your business is safe and available from anywhere, anytime you need them</span>  </center> 
                 </div>
                
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 space-top-1"><a className="text-inherit" >Notifications</a></h3>  </center>   
               {/* Article */}         
               <div className="card border  h-75" style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
                 <center><img className="card-img-top"src="../../assets/svg/illustrations/Refer-a-Friend.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"50%",
                     height:"50%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                             
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>To increase the control you already have on your business, we notify you whenever something happens in your company.</span>  </center> 
                 </div>
                 
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
              <article className="col-md-6 col-lg-4 mb-5">
              <center><h3 className="mb-5 space-top-1"><a className="text-inherit" >Self-service</a></h3>  </center> 
               {/* Article */}         
               <div className="card border h-75"style={{
                    // borderRadius: "15px",
                    boxShadow: "0 6px 6px -3px gray",
                  }}>
               
               <div className="card-img-top position-relative">
                 <center><img className="card-img-top"src="../../assets/svg/illustrations/Mobile-login.svg" alt="Image Description" style={{
                    // borderRadius: "15px",
                    width:"50%",
                     height:"50%",

                  }} /></center>
               </div>
               <div className="card-body">  
                                               
                 <div className="mb-3">
                 <center><span className="d-block large   mb-2" style={{
                    // borderRadius: "15px",
                    textAlign: "justify",
                  }}>Your clients can generate their proof of residence and statements by themselves, which frees your staff members from such tasks, allowing them to focus on other tasks.</span>  </center> 
                 </div>
               
               </div>
               <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                    <div className="mb-n7 ml-n7">
                      <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
                    </div>
                  </figure>
             </div>
             {/* End Article */}
              </article>
              
            </div>
     
        </div>
        {/* End Related Courses */}

        <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9 space-top-0">
            <h2 className="h2 text-secondary">As we release new versions of the system, we will keep coming up with new features to meet all your needs...</h2>

          </div>

      </main>
      {/* ========== END MAIN CONTENT ========== */}

      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};
export default About;

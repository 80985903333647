// Contact Page

const appStatic = {
  reason: [
    {
      value: "Work with you",
      name: " Work with you",
    },

    {
      value: "Give  you feedback",
      name: " Give  you feedback",
    },

    {
      value: "Ask a question",
      name: " Ask a question",
    },

    {
      value: "ther",
      name: " Other",
    },
  ],

  // Booking Page

  guestNumber: [
    {
      value: "Yes",
      name: "Yes",
    },
  ],
  servicesData: [
    {
      SVC_ID: "1",
      SVC_NAME: "Breakfast",
      SVC_PRICE: 10,
    },
    {
      SVC_ID: "2",
      SVC_NAME: "Wifi",
      SVC_PRICE: 30,
    },
  ],
  guestsData: [
    {
      GUEST_ID: "1",
      AGE_FROM: 0,
      AGE_TO: 12,
      GUEST_PRICE: 200,
    },
    {
      GUEST_ID: "2",
      AGE_FROM: 13,
      AGE_TO: 20,
      GUEST_PRICE: 150,
    },
    {
      GUEST_ID: "3",
      AGE_FROM: 21,
      AGE_TO: 29,
      GUEST_PRICE: 100,
    },
  ],

  residenceType: [
    {
      id: "1",
      name: "Guest House",
    },

    {
      id: "2",
      name: "Estate",
    },
    {
      id: "3",
      name: "Complex",
    },
    {
      id: "4",
      name: "Student Accommodation",
    },
    // {
    //   id: "5",
    //   name: "Hotel",
    // },

    {
      id: "6",
      name: "BnB",
    },
  ],

  defaultImgCarourel: [
    {
      image: "../../assets/svg/logos/student.jpg",
      caption: "The image has yet to be configured.",
    },
    {
      image: "../../assets/svg/logos/guest.jpg",
      caption: "The image has yet to be configured.",
    },
    {
      image: "../../assets/img/900x900/img19.jpg",
      caption: "The image has yet to be configured.",
    },
  ],
};

export default appStatic;

import React, { Fragment, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";
import appLabel from "../../config/appLabel";
import video from './video/three.mp4'


const Home = () => {
  useEffect(() => {}, []);
  return (
    <div>
      {/* ========== HEADER ========== */}
         <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        <center>
         <video className="backgroundvideo" autoPlay muted loop>
            <source src={video} type="video/mp4" autoPlay/>
         </video>
        </center>
        
          {/* Articles Section */}
          <div className="container">
            {/* Title */}
           
            {/* End Title */}
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4 mb-md-5 mb-lg-0">
                {/* Card */}
                <a className="card shadow-soft h-100 text-center transition-3d-hover" >
                  <div className="card-body pt-7 px-7">
                    <figure className="avatar mx-auto mb-1">
                    <i class="fa fa-question text-primary fa-3x" aria-hidden="true"></i>
                    {/* <i class="fa fa-road text-primary fa-3x" aria-hidden="true" ></i> */}
                    </figure>
                    <h3>WHY</h3>
                    <p className="text-body">We believe technology can do more to increase properties' revenue while providing a better experience to their clients.</p>
                  </div>
                  
                </a>
                {/* End Card */}
              </div>
              <div className="col-md-6 col-lg-4 mb-4 mb-md-5 mb-lg-0">
                {/* Card */}
                <a className="card shadow-soft h-100 text-center transition-3d-hover" >
                  <div className="card-body pt-7 px-7">
                    <figure className="avatar mx-auto mb-1">
                    <i class="fa fa-lightbulb-o text-primary fa-3x" aria-hidden="true"></i>
                      {/* <i class="fa fa-eye text-primary fa-3x" aria-hidden="true" ></i> */}
                    </figure>
                    <h3>HOW</h3>
                    <p className="text-body">We increase your revenue by cuting costs, increasing your staff productivity and improving your customer's experience.</p>
                  </div>
                  
                </a>
                {/* End Card */}
              </div>
              <div className="col-md-6 col-lg-4 mb-4 mb-md-5 mb-lg-0">
                {/* Card */}
                <a className="card shadow-soft h-100 text-center transition-3d-hover" href="#">
                  <div className="card-body pt-7 px-7">
                    <figure className="avatar mx-auto mb-1">
                    <i class="fa fa-user-o text-primary fa-3x" aria-hidden="true"></i>
                    {/* <i class="fa fa-heart text-primary fa-3x" aria-hidden="true" ></i> */}
                    </figure>
                    <h3>WHO</h3>
                    <p className="text-body">We have experienced housing both as clients and owners, that's what gave us such a good understanding of the challenges faced by both.</p>
                  </div>
                
                </a>
                {/* End Card */}
              </div>
            </div>
          </div>
          {/* End Articles Section */}

        <div className="container  ">
          <p className="lead">
            {appLabel.aboutUsContent}
          </p>
          <div className="position-relative z-index-2 text-center">
            <div className="d-inline-block font-size-1 border bg-black text-center rounded-pill py-3 px-4">
            {appLabel.readMore}<Link to = "/features" className="hs-mega-menu-invoker nav-link"></Link>
            </div>
          </div>
          <div className="text-center mb-md-3">
            <br></br><h3 className="h2">{appLabel.version}</h3>
          </div>
          <div className="container pb-4">
          <div className="row mx-lg-5">
            <div className="col-sm-6 col-lg-4 px-lg-5 mb-5">
            <div className="card shadow-none h-100">
             <div className="card-header border-0 text-center">
               <span className="d-block h3 mb-0">{appLabel.versionOne}</span>
               <span className="d-block">
                 <span className="font-size-2 text-primary font-weight-bold">
                   <span className="mr-n2">R&nbsp;</span>
                   <span >{appLabel.versionOnePrice}</span>
                 </span>
                 <span className="font-size-1">{appLabel.perUser}</span>
               </span>
             </div>
             <div className="card-body">
               <div className="media font-size-1 text-body mb-3">
                 <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                 <div className="media-body">
                    {appLabel.versionOneResidence}
                  </div>
                </div>
                <div className="media font-size-1 text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionOneCategory}
                  </div>
                </div>
                <div className="media font-size-1 text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionOneBlock}
                  </div>
                </div>
                <div className="media font-size-1 text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionOneRoles}
                  </div>
                </div>
                <div className="media font-size-1 text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionOneFeeds}
                 </div>
               </div>
               <div className="media font-size-1 text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionOneUsers}
                 </div>
               </div>
               <div className="media font-size-1 text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionOneDays}
                 </div>
               </div>
             </div>
      
           </div>
         </div>
         <div className="col-sm-6 col-lg-4 px-lg-5 mb-5">
           <div className="position-relative">
             <div className="card shadow-lg h-100">
             <div className="card-header border-0 text-center">
                <span className="d-block h3 mb-0"> {appLabel.versionGuarantees} </span>
              </div>
              <div className="card-body">
                <div className="media font-size-1 mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionGuaranteesRisk}
                  </div>
                </div>
                <div className="media font-size-1 mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionGuaranteesContract}
                  </div>
                </div>
                <div className="media font-size-1 mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionGuaranteesFunctional}
                  </div>
                </div>
                <div className="media font-size-1 mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                      {appLabel.versionGuaranteesTraining}
                  </div>
                </div>
                <div className="media font-size-1 mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                  <div className="media-body">
                    {appLabel.versionGuaranteesCreditCards}
                   </div>
                 </div>
               </div>
             </div>
             <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
               <div className="mb-n7 ml-n7">
                 <img className="img-fluid" src="../../assets/svg/components/dots-2.svg" alt="Image Description"/>
               </div>
             </figure>
           </div>
         </div>
         <div className="col-sm-6 col-lg-4 px-lg-5 mb-5">
            <div className="card shadow-none h-100">
                <div className="card-header border-0 text-center">
                  <span className="d-block h3 mb-0">{appLabel.freeVersion} </span>
                  <span className="d-block">
                    <span className="font-size-2 text-primary font-weight-bold">
                      <span className="mr-n2">R&nbsp;</span>
                      <span>{appLabel.freeVersionPrice}</span>
                    </span>
                    <span className="font-size-1">{appLabel.perUser}</span>
                  </span>
                </div>
                <div className="card-body">
                  <div className="media font-size-1 text-body mb-3">
                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                    <div className="media-body">
                     {appLabel.freeVersionResidence}
                    </div>
                  </div>
                  <div className="media font-size-1 text-body mb-3">
                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                    <div className="media-body">
                     {appLabel.freeVersionCategory}
                    </div>
                  </div>
                  <div className="media font-size-1 text-body mb-3">
                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                    <div className="media-body">
                      {appLabel.freeVersionBlock}
                    </div>
                  </div>
                  <div className="media font-size-1 text-body mb-3">
                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                    <div className="media-body">
                      {appLabel.freeVersionRoles}
                    </div>
                  </div>
                  <div className="media font-size-1 text-body mb-3">
                 <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                 <div className="media-body">
                    {appLabel.freeVersionFeeds}
                 </div>
               </div>
               <div className="media font-size-1 text-body mb-3">
                 <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                 <div className="media-body">
                    {appLabel.freeVersionUsers}
                 </div>
               </div>
               <div className="media font-size-1 text-body mb-3">
                 <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                 <div className="media-body">
                    {appLabel.freeVersionDays}
                 </div>
               </div>
             </div>           
           </div>
         </div>
       </div>
          </div>
          <div className="position-relative z-index-2 text-center">
            <div className="d-inline-block font-size-1 border bg-black text-center rounded-pill py-3 px-4">
             {appLabel.readMore}<Link to = "/versions" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false"></Link>
            </div>
          </div><br></br>
          {/* <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
            <br></br><h2 className="h1">{appLabel.booking} </h2>
            <p>{appLabel.bookingDescription}</p>
          </div>
          <div class="row mb-5">
            <article class="col-md-6 col-lg-3 mb-5">
              <div class="card border h-100">                
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="../../assets/svg/logos/guest.jpg" alt="Image Description"/>                 
                </div>
                <div class="card-body">
                <div class="mb-3">
                    <h3>
                      <a class="text-inherit" >{appLabel.space}</a>
                    </h3>
                  </div>
                  <small class="d-block small font-weight-bold text-cap mb-2"> {appLabel.companyName}&nbsp;-&nbsp;{appLabel.residenceName}</small>
                  <div class="mb-3">
                    <h3>
                      <a class="text-inherit" href="course-description.html">{appLabel.residenceDescription}</a>
                    </h3>
                  </div>
                  <div class="d-flex align-items-center">             
                    <div class="d-flex align-items-center ml-auto">
                      <div class="small text-muted">
                        <i class="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.propertyAddress}
                      </div>
                      <small class="text-muted mx-2">|</small>
                      <div class="small text-muted">
                        <i class="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mr-2">           
                      <span class="text-success d-block h5 text-lh-sm mb-0"> {appLabel.newPrice}</span>
                    </div>
                  </div>
                </div>
              </div>
              </article>
              <article class="col-md-6 col-lg-3 mb-5">         
              <div class="card border h-100">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="../../assets/svg/logos/guest.jpg" alt="Image Description"/>
                </div>
                <div class="card-body">
                <div class="mb-3">
                    <h3>
                      <a class="text-inherit" >{appLabel.space}</a>
                    </h3>
                  </div>
                <small class="d-block small font-weight-bold text-cap mb-2"> {appLabel.companyName}&nbsp;-&nbsp;{appLabel.residenceName}</small>
                <div class="mb-3">
                  <h3>
                    <a class="text-inherit" >{appLabel.residenceDescription}</a>
                  </h3>
                </div>
                <div class="d-flex align-items-center">             
                  <div class="d-flex align-items-center ml-auto">
                      <div class="small text-muted">
                        <i class="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.propertyAddress}
                      </div>
                      <small class="text-muted mx-2">|</small>
                      <div class="small text-muted">
                        <i class="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mr-2">                     
                      <span class="text-success d-block h5 text-lh-sm mb-0"> {appLabel.newPrice}</span>
                    </div>
                  </div>
                </div>
              </div>
          </article>
          <article class="col-md-6 col-lg-3 mb-5">        
            <div class="card border h-100">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="../../assets/svg/logos/guest.jpg" alt="Image Description"/>
              </div>
              <div class="card-body">
                  <div class="mb-3">
                    <h3>
                      <a class="text-inherit" >{appLabel.space}</a>
                    </h3>
                  </div>
                <small class="d-block small font-weight-bold text-cap mb-2"> {appLabel.companyName}&nbsp;-&nbsp;{appLabel.residenceName}</small>
                <div class="mb-3">
                  <h3>
                    <a class="text-inherit" >{appLabel.residenceDescription}</a>
                  </h3>
                </div>
                <div class="d-flex align-items-center">            
                  <div class="d-flex align-items-center ml-auto">
                      <div class="small text-muted">
                        <i class="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.propertyAddress}
                      </div>
                      <small class="text-muted mx-2">|</small>
                      <div class="small text-muted">
                        <i class="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mr-2">
                      
                      <span class="text-success d-block h5 text-lh-sm mb-0"> {appLabel.newPrice}</span>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article class="col-md-6 col-lg-3 mb-5">        
            <div class="card border h-100">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="../../assets/svg/logos/guest.jpg" alt="Image Description"/>
              </div>
              <div class="card-body">
                  <div class="mb-3">
                    <h3>
                      <a class="text-inherit" >{appLabel.space}</a>
                    </h3>
                  </div>
                <small class="d-block small font-weight-bold text-cap mb-2"> {appLabel.companyName}&nbsp;-&nbsp;{appLabel.residenceName}</small>
                <div class="mb-3">
                  <h3>
                    <a class="text-inherit" >{appLabel.residenceDescription}</a>
                  </h3>
                </div>
                <div class="d-flex align-items-center">            
                  <div class="d-flex align-items-center ml-auto">
                      <div class="small text-muted">
                        <i class="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.propertyAddress}
                      </div>
                      <small class="text-muted mx-2">|</small>
                      <div class="small text-muted">
                        <i class="fa fa-bed d-block d-sm-inline-block mb-1 mb-sm-0 mr-1"></i>
                        {appLabel.availableRooms}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mr-2">
                      
                      <span class="text-success d-block h5 text-lh-sm mb-0"> {appLabel.newPrice}</span>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div className="position-relative z-index-2 text-center">
            <div className="d-inline-block font-size-1 border bg-black text-center rounded-pill py-3 px-4">
             {appLabel.readMore}<Link to = "/booking" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false"></Link>
            </div>
          </div> */}
          <div className="container space-bottom-0 space-top-1 space-top-lg-1">
            <div className="w-lg-85 mx-lg-auto">
              <div className="card bg-navy text-white overflow-hidden p-4">
                <div className="row justify-content-md-start align-items-md-center text-center text-md-left">
                  <div className="col-md-6 offset-md-3 mb-3 mb-md-0">
                    <h3 className="text-white mb-1">{appLabel.contactQuestion}</h3>
                    <p className="text-white-70 mb-0">{appLabel.contactDescription}</p>
                  </div>
                  <div className="col-md-3 text-md-right">
                    <Link to = "/contact" className="btn btn-light transition-3d-hover" aria-haspopup="true" aria-expanded="false">Contact us</Link>
                  </div>
                </div>
                <figure className="w-25 d-none d-md-block content-centered-y ml-n4">
                 <img className="img-fluid" src="../../assets/svg/illustrations/communication.svg" alt="Image Description"/>
                </figure>
              </div>
            </div>
          </div>           
           <div className="container space-bottom-2 space-top-2" >
            <center>
              <div className="text-center-py-6" >
                <h2>{appLabel.frequetlyAsked}</h2>
                <p>{appLabel.frequentlyAskedDescription}</p>
                <span className="d-block mt-5">
                  <div className="position-relative z-index-2 text-center">
                    <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
                    {appLabel.readMore}<Link to = "/faq" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false"></Link>
                    </div>
                  </div>
                </span> 
              </div>
            </center>
          </div>
        </div>
   </main>
    {/* ========== FOOTER ========== */}
    <Footer />
    {/* ========== END FOOTER ========== */}
  </div>
  );
};
export default Home;

import React, { Fragment, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";

const News= () => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  return (
    <div>
      {/* ========== HEADER ========== */}
         <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        <div className="d-lg-flex space-top-2">
          <div className="container d-lg-flex align-items-lg-center vh-lg-100 space-bottom-1 space-top-3 space-bottom-lg-3 space-lg-0">
            <div className="row justify-content-lg-between align-items-lg-center w-100 mt-lg-9">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <img className="img-fluid" src="../../assets/svg/illustrations/relaxing-man.svg" alt="SVG Illustration"/>
              </div>
              <div className="col-lg-5">
                <br></br><div className="mb-4">
                <h1>News Page coming soon...</h1>
                <p>Our NEWS PAGE is under construction. We will be here soon with awesome features.<br></br>
                As a company, you can already communicate any information to your existing clients via the feed in the system so, on this page, you will be able to post information to communicate to your prospects.</p>
              </div> 
            </div>
           </div>
          </div>
        </div>
     </main>
     {/* ========== FOOTER ========== */}
      <Footer />
     {/* ========== END FOOTER ========== */}
     </div>
  );
};
export default News;

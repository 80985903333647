import React, { Fragment, useEffect } from "react";
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";

const Faq= () => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  
  return (
  <div>
      {/* ========== HEADER ========== */}
         <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
    <main id="content" role="main">
        <br></br>
        <center>
        <div className="border-bottom space-3 space-lg-3">
          <div className="faqtemp">
            <div className="col-lg-7 order-lg-2 mb-7 mb-lg-0">
              <div className="pl-lg-5">
                <div className="bg-navy rounded min-h-450rem p-4">
                  <ul className="nav nav-pills nav-white nav-justified scrollbar-horizontal font-weight-bold" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="pills-one-code-sample-tab" data-toggle="pill" href="#pills-one-code-sample" role="tab" aria-controls="pills-one-code-sample" aria-selected="true">
                        General
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="pills-two-code-sample-tab" data-toggle="pill" href="#pills-two-code-sample" role="tab" aria-controls="pills-two-code-sample" aria-selected="false">
                        Student Accommodation
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="pills-three-code-sample-tab" data-toggle="pill" href="#pills-three-code-sample" role="tab" aria-controls="pills-three-code-sample" aria-selected="false">
                        Estate and Complex
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="pills-four-code-sample-tab" data-toggle="pill" href="#pills-four-code-sample" role="tab" aria-controls="pills-four-code-sample" aria-selected="false">
                        Guest House and BnB
                      </a>
                    </li>
                    {/*<li className="nav-item">
                      <a className="nav-link" id="pills-five-code-sample-tab" data-toggle="pill" href="#pills-five-code-sample" role="tab" aria-controls="pills-five-code-sample" aria-selected="false">
                        Hotel
                      </a>
                      </li>*/}
                  </ul>

                  {/* GENERAL  */}
                  <div className="tab-content">
                    <div className="tab-pane fade pt-5 show active" id="pills-one-code-sample" role="tabpanel" aria-labelledby="pills-one-code-sample-tab">
                      
                      {/* box one*/}
                      <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingSix">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseSix" aria-expanded="false" aria-controls="coursesCollapseSix">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">How to create a business account</span>
                                  </span>
                                </span>
                              </span>
                           </span>
                          </a>
                        </div>
                        <div id="coursesCollapseSix" className="collapse " aria-labelledby="coursesHeadingSix">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">
                                  <div className="faqContent">   
                                    <span className="media-body">
                                      <span> On the website, you click on the sign in button. <br></br>
                                        <center><img  className="faqimage" src="../../assets/faqimages/5.png"></img></center> <br></br>
                                        It takes you to a page where you sign in if you have an account. If you don't, there is a "New to nAcomoda? Register Now for an Individual or a Business account" sentence.<br></br>
                                        <center><img  className="faqimage" src="../../assets/faqimages/6.png"></img></center> <br></br>
                                        Click on 'Business' and fill in your personal information and your company's information.<br></br>
                                        <center><img  className="faqimage" src="../../assets/faqimages/BA2.png"></img></center> <br></br>
                                        After you agree with the Privacy Policy and Terms and Conditions, the CREATE button appears. You click on it to proceed and you will receive an email with your login details ans a welcome message.<br></br>
                                        <center><img  className="faqimage" src="../../assets/faqimages/BA3.png"></img></center> <br></br>
                                        Use those details to login to your account and change the default password to the one you want and voilà!<br></br>
                                        <center><img  className="faqimage" src="../../assets/faqimages/BA4.png"></img></center> <br></br>
                                        You can also create your business account from the Versions page by clicking on 'Get Started' under any version. This does not automatically give you the version. You will get the version you want in the Credits page, after creating your account.
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      {/* box two*/} 
                      <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingSeven">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseSeven" aria-expanded="false" aria-controls="coursesCollapseSeven">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">How to create an individual account</span>
                                  </span>
                                </span>
                              </span>
                           </span>
                          </a>
                        </div>
                        <div id="coursesCollapseSeven" className="collapse " aria-labelledby="coursesHeadingSeven">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">
                                  <div className="faqContent">   
                                    <span className="media-body">
                                      <span>On the website, you click on the sign in button. <br></br>
                                      <center><img  className="faqimage" src="../../assets/faqimages/5.png"></img></center> <br></br>
                                        It takes you to a page where you sign in if you have an account. If you don't, there is a "New to nAcomoda? Register Now for an Individual or a Business account" sentence.<br></br>
                                        <center><img  className="faqimage" src="../../assets/faqimages/6.png"></img></center> <br></br>
                                        Click on 'Individual' and fill in the form that appears.
                                        <center><img  className="faqimage" src="../../assets/faqimages/07.png"></img></center> <br></br>
                                        After you agree with the Privacy Policy and Terms and Conditions, the sign up button appears. You click on it to proceed and you will receive an email with your login details.
                                        <center><img  className="faqimage" src="../../assets/faqimages/08.png"></img></center> <br></br>
                                        Use those details to login and change the default password to the one you want and voilà!
                                        <center><img  className="faqimage" src="../../assets/faqimages/09.png"></img></center> <br></br>
                                      </span>
                                    </span>  
                                  </div>                             
                                </div>                              
                              </div>
                            </div>                         
                          </div>
                        </div>
                      </div>

                      {/*box three*/}
                     {/* <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingOne">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">What is a "category" ?</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                         </a>
                        </div>
                        <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">
                                  <div className="faqContent">   
                                      <span className="media-body">
                                        <span>We can also call it a room category. These are the room categories that a residence has to offer to its clients.
                                          Some of the common ones are: single and sharing for student accommodations. In complexes and estates, we ca talk about 
                                          2 bedrooms, 3 bedrooms appartments and so on for guest houses and hotels. This is where you put the room types you have and their prices.
                                        </span>
                                      </span>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>*/}

                      {/*box four*/}
                     {/* <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingTwo">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwo" aria-expanded="false" aria-controls="coursesCollapseTwo">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">What is a "block" ?</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </a>
                        </div>
                        <div id="coursesCollapseTwo" className="collapse " aria-labelledby="coursesHeadingTwo">
                          <div className="card-body p-0"> 
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">
                                  <div className="faqContent">   
                                    <span className="media-body">
                                      <span>A block is a collection of rooms or units found under the same building. 
                                        Rooms numbers of the same block usually start with the same prefix.</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>*/}

                      {/* box five*/}
                       {/* <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingThree">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThree" aria-expanded="false" aria-controls="coursesCollapseThree">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">After buying credits, can I get a refund?</span>
                                  </span>
                                </span>
                              </span>
                           </span>
                          </a>
                        </div>
                        <div id="coursesCollapseThree" className="collapse " aria-labelledby="coursesHeadingThree">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">
                                  <div className="faqContent">   
                                    <span className="media-body">
                                      <span>Unfortunatly, there is no refund. I should put this under the answer of how to buy credits.
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>  */}

                      {/* box six */} 
                      <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingFour">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseFour" aria-expanded="false" aria-controls="coursesCollapseFour">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">How to buy credits</span>
                                  </span>
                                </span>
                              </span>
                           </span>
                          </a>
                        </div>
                        <div id="coursesCollapseFour" className="collapse " aria-labelledby="coursesHeadingFour">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">
                                    <div className="faqContent">   
                                    <span className="media-body">
                                      <span><p>A credit is the currency you use to manage your property in the system. You have the freedom to choose the quantity of credits you want to buy.
                                        1 Credit=R1. To buy credit, just specify the number of people you want to manage in the system and for how many days, and the system will automatically 
                                        tell you how many credits are enough. The same credits allow you to be listed on the Booking page: the more credits you have in your account, 
                                        the better your position in the Booking page.</p> 
                                        <p>So here is how to buy your credits:
                                        After logging in as a super admin, you click on the Credit button.</p>
                                        <center><img  className="faqimage" src="../../assets/faqimages/CR0.png"></img></center> <br></br>
                                        <p>1. Version: the version of the system you are currently using.<br></br>
                                           2. Active Users: this is the number of active users you currently have over the number of users you bought.
                                              Let's say you see 4500/5000 here. This means you are currently managing 4500 people out of the 5000 you bought. You can still add 500.<br></br>
                                           3. Days: the number of days left from your purchase to manage your people. It goes down by one everyday, starting from the number of days you bought.
                                              Let's say you bought for 100 days, the following day it will get to 99, the next day to 98...until it reaches 0.<br></br>
                                           4. Credits: the amount of credits (or money) you have available in your account. It goes down everyday. If it reaches '0' (which happens when the number of days also reaches '0'),
                                              the company is deactivated (with all the residences) and the users can't login anymore, expect the super admin of the company.
                                              Here is the formula to know by which amount the credits reduces everyday: number of people bought x version price.</p>
                                        <p>To proceed with your purchase, you click on the BUY button of the version you want to get.</p>
                                        <center><img  className="faqimage" src="../../assets/faqimages/CR1.png"></img></center> <br></br>
                                        <p>A popup appears where you then enter the number of users you want to manage with system (customer and admins together) and
                                          for how many days you want to manage them, then the system automatically tells you how much is enough. If you already have a balance
                                          from a previous purchase, the system will substract it from the total you need to pay and just give you the difference to get to the amount you need.
                                          You then click on the PROCEED button to pay and voilà ! There is no refund for the money spent on buying credits. </p>
                                          <center><img  className="faqimage" src="../../assets/faqimages/CR2.png"></img></center> <br></br>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* box seven */}
                      
                      
                      {/* box eight*/} 
                      <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingOne">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">                         
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">Listing my residences on the Booking page is FREE ?</span>
                                  </span>
                                </span>
                              </span>
                           </span>
                          </a>
                        </div>
                        <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">  
                                  <div className="faqContent">                                
                                    <span className="media-body">
                                      <span>Yes! The listing and the property management are 2 different systems, but we don't charge you for the listing. When you buy credits to manage your residence(s), the listing is given to you as a gift.
                                        This means when your credits finish, the residences are also removed from the Booking page to avoid the case where you get a client but are unable to manage them because you don't have credits anymore. On top of the listing being free, we also don't take any commission on the bookings you get from our system.
                                      </span>
                                    </span>    
                                  </div>                           
                                </div>                              
                              </div>
                            </div>                         
                          </div>
                        </div>
                      </div>

                      {/* box nine*/}             
                      {/* <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingTwo">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwo" aria-expanded="false" aria-controls="coursesCollapseTwo">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">How do we change a super admin ?</span>
                                  </span>
                                </span>
                              </span>
                           </span>
                          </a>
                        </div>
                        <div id="coursesCollapseTwo" className="collapse " aria-labelledby="coursesHeadingTwo">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">  
                                  <div className="faqContent">                                
                                    <span className="media-body">
                                      <span>A super admin has such an important role in the management of a company with the system that, for your security, we do not allow a super admin to be changed directly from the system.
                                        Since we want to make sure that this request really comes from you as the company, to change a super admin you need to send us an email to contact@nacomoda.com with the subject line "Super admin change request". 
                                        In the email, please send the following information about the current super admin:<br></br>
                                        - Company name<br></br>
                                        - Identity document picture or scan<br></br>
                                        - Username<br></br>
                                        In the same email, you also need to send us the following information about the new super admin:<br></br>
                                        - Identity document picture or scan<br></br>
                                        - Username they want to have in the system<br></br>
                                        - Proof that they work for the company<br></br>
                                        Once we accept the request, we will generate a default password to allow the new super admin to login and change to the password they want. These login details will be sent in the email stating that the request has been accepted.<br></br>
                                        This might be a lot to ask, but we take the security of your business data very seriously, that is why we ask for these.
                                      </span>
                                    </span>    
                                  </div>                            
                                </div>                              
                              </div>
                            </div>                          
                          </div>
                        </div>
                      </div> */}
                      
                      {/* box ten*/}
                      {/*                    
                      <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingOne">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">How to sign up</span>
                                  </span>
                                </span>
                              </span>
                           </span>
                          </a>
                        </div>
                        <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                          <div className="card-body p-0">
                           <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">
                                  <div className="faqContent">   
                                    <span className="media-body">
                                      <span>Python 2 versus Python 3</span>
                                    </span>  
                                  </div>                           
                                </div>                             
                              </div>
                            </div>                         
                          </div>
                        </div>
                      </div>                           
                      */}    
                    </div>
                    
                    {/* STUDENT ACCOMMODATION */} 
                    {/*box one*/}
                    <div className="tab-pane fade text-black-70" id="pills-two-code-sample" role="tabpanel" aria-labelledby="pills-two-code-sample-tab">
                      <div className="tab-pane fade pt-5 show active" id="pills-one-code-sample" role="tabpanel" aria-labelledby="pills-one-code-sample-tab">
                        <div className="nacomodausers">SUPER ADMIN</div>                 
                        <div className="card border mb-1 ">
                          <div className="card-header card-collapse" id="coursesHeadingEleven">
                            <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseEleven" aria-expanded="false" aria-controls="coursesCollapseEleven">
                              <span className="row ">
                                <span className="col-12">
                                  <span className="media">
                                    <span className="card-btn-toggle mr-3 ml-0">
                                      <span className="card-btn-toggle-active">-</span>
                                      <span className="card-btn-toggle-default">+</span>
                                    </span>
                                    <span className="media-body">
                                      <span className="text-body font-weight-bold mr-5">How to create a Student Accommodation</span>
                                    </span>
                                  </span>
                                </span>
                             </span>              
                            </a>
                          </div>
                          <div id="coursesCollapseEleven" className="collapse " aria-labelledby="coursesHeadingEleven">
                            <div className="card-body p-0">                    
                              <div className="border-bottom py-3 pr-3 pl-6">
                                <div className="row">
                                  <div className="col-12">    
                                    <div className="faqContent">                           
                                      <span className="media-body">
                                        <span><p>To create a student accommodation of your company, you click on the ADD NEW button.</p>
                                            <center><img  className="faqimage" src="../../assets/faqimages/C1.png"></img></center>
                                            <p>You are then taken to the below page, to fill in the student accommodation's information. For the residence type, make sure to choose 'Student Accommodation'.</p>		
                                            <center><img  className="faqimage" src="../../assets/faqimages/21.png"></img></center>
                                            <p>After entering the student accommodation's information, you get the following popup message.</p>		
                                            <center><img  className="faqimage" src="../../assets/faqimages/22.png"></img></center>
                                        </span>
                                      </span>  
                                    </div>                            
                                  </div>                           
                                </div>
                              </div>                         
                            </div>
                          </div>
                        </div>    

                        {/*box two*/}
                        <div className="card border mb-1">
                          <div className="card-header card-collapse" id="coursesHeadingTwelve">
                            <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwelve" aria-expanded="false" aria-controls="coursesCollapseTwelve">                           
                              <span className="row">
                                <span className="col-12">
                                  <span className="media">
                                    <span className="card-btn-toggle mr-3 ml-0">
                                      <span className="card-btn-toggle-active">-</span>
                                      <span className="card-btn-toggle-default">+</span>
                                    </span>
                                    <span className="media-body">
                                      <span className="text-body font-weight-bold mr-5">How to write (or update) a student accommodation's banking details</span>
                                    </span>
                                  </span>
                                </span>
                             </span> 
                            </a>
                          </div>
                          <div id="coursesCollapseTwelve" className="collapse " aria-labelledby="coursesHeadingTwelve">
                            <div className="card-body p-0">
                              <div className="border-bottom py-3 pr-3 pl-6">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="faqContent">   
                                      <span className="media-body">
                                        <span><p>To write or update the banking details of the Student Accommodation (this is the account where tenants pay the property), you go to ‘Residence Profile’ and then click on ‘bank info.’ In that page, you can also set the admin price that clients pay to move in.
                                          You can also write your PayFast information here, to receive online payments.</p>		
                                          <center><img  className="faqimage" src="../../assets/faqimages/23.png"></img></center>
                                          <p>The record status in this page shows the person who last updated any information on this page. Actually, in any page where you see a ‘record status’, its role is to notify on who last updated any information on the page.</p>
                                        </span>
                                      </span>    
                                    </div>                              
                                  </div>                                
                                </div>
                              </div>                              
                            </div>
                          </div>
                        </div>

                         {/*box three*/}
                         

                        {/* box four*/}           
                        <div className="card border mb-1">
                          <div className="card-header card-collapse" id="coursesHeadingThirteen">
                            <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirteen" aria-expanded="false" aria-controls="coursesCollapseThirteen">                            
                              <span className="row">
                                <span className="col-12">
                                  <span className="media">
                                    <span className="card-btn-toggle mr-3 ml-0">
                                      <span className="card-btn-toggle-active">-</span>
                                      <span className="card-btn-toggle-default">+</span>
                                    </span>
                                    <span className="media-body">
                                      <span className="text-body font-weight-bold mr-5">How to create an admin (or employee)</span>
                                    </span>
                                  </span>
                                </span>
                              </span>                                
                            </a>
                          </div>
                          <div id="coursesCollapseThirteen" className="collapse " aria-labelledby="coursesHeadingThirteen">
                            <div className="card-body p-0">                         
                              <div className="border-bottom py-3 pr-3 pl-6">
                                <div className="row">
                                  <div className="col-12"> 
                                    <div className="faqContent">                     
                                      <span className="media-body">
                                        <span>
                                        <p>Now that the student accommodation is created, you can create an admin to continue the process (or you can continue yourself if you prefer because anything an admin can do, a super admin can do). To create the admin, you need to create a sub-role under the admin role first. A sub-role is a position held by someone in your company. Tenant and house comm are examples of sub-roles for customers. 
                                        For admins, we can say cleaner, security, manager and so on. Creating different su-roles allows you to group your people, so you can send a message to your tenants only for example. Creating sub-roles also allows you to decide on what each type of
                                        user in your company can do in the system, by restricting their functionalities if you want. For example, you can decide that tenants can receive feeds but they can't create them.
                                        To do that, you create a sub-role with its menu (the menu you want the people under that sub-role to get). </p>
                                        <p>The first step is to click on the ‘manage sub-role’ sub-menu under the ‘company’ menu. On the page that appears (as shown below), you click on the ADD NEW button.</p>			
                                        <center><img  className="faqimage" src="../../assets/faqimages/25.png"></img></center>
                                        <p>After clicking on the ADD NEW button, you fill in the form as it appears:<br></br>
                                           -	For the parent role name, you choose the Administrator/ADM option.<br></br>
                                           -	You enter the sub-role name. For example secretary, cleaner, security, etc. Remember, admins are your employees.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/26.png"></img></center>
                                        <p>You then click on the CREATE button and you receive a popup message asking you to assign the menus to the sub-role you just created.<br></br>
                                           These are the menus you want the people under the sub-role to get when you create them.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/27.png"></img></center>
                                        <p>You close the popup by clicking on the OK button, then you click on ‘menu’ to assign the menus. You will be taken to the following page:</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/28.png"></img></center>
                                        <p>You can edit the sub-role name by clicking on EDIT SUB-NAME. To assign the menus, you click on the CREATE MENU button.<br></br>
                                           You are then taken to the following page:</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/29.png"></img></center>
                                        <p>The menus appear and for you to assign them to the sub-role, you click on them. Let’s say you want to give the Residence Profile sub-menu and some of its privileges. You will click on ‘Company’ and then on Residence Profile. 
                                          You will then see the different privileges under the Residence Profile sub-menu, that you can assign to the sub-role. You then just click on the checkboxes and when you are done, you click on Add Privilege(s). You use the same process for the other menus that you want to give too. 
                                          This means that, if you don’t do this process for a certain menu, the people under this sub-role will not get that menu in their accounts. When you are done, you click on the CREATE button.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/30.png"></img></center>
                                        <p>In case you want to add some menus that you didn’t give in the beginning, you can just click ‘menu’ again, but this time you will see the menus you already gave and the button to click to add more menus will be ADD TO MENU.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/31.png"></img></center>
                                        <p>After clicking on ADD TO MENU, you will see the menus you didn’t give (unless you already gave the sub-role all the menus). You then just click on the menu you want to add, click on the privileges you want to give, click on ADD and voila!</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/32.png"></img></center>
                                        <p>To delete a menu from a sub-role, you click on the menu and then on the delete icon top-left to delete it. You can also delete a submenu by clicking on the 'delete' icon at its left.</p>			
                                        <center><img  className="faqimage" src="../../assets/faqimages/subrole1.png"></img></center>
                                        <p>You can now proceed with the creation of the admin. You click on ‘admin’ under the ‘user’ menu.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/admin1.png"></img></center>
                                        <p>On the page that appears, you click on ADD NEW and you are taken to the page below where you enter the admin email and choose the sub-role you want to give them. You can now click on the SEND button to invite them into the company via a link (that will go to the email you just provided).</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/admin2.png"></img></center>
                                        </span>
                                      </span>  
                                    </div>                             
                                  </div>                               
                                </div>
                              </div>                             
                            </div>
                          </div>
                        </div>  

                        {/* box five */}   
                        <div className="nacomodausers">ADMIN</div>     
                        <div className="card border mb-1">
                          <div className="card-header card-collapse" id="coursesHeadingFourteen">
                            <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseFourteen" aria-expanded="false" aria-controls="coursesCollapseFourteen">
                              <span className="row">
                                <span className="col-12">
                                  <span className="media">
                                    <span className="card-btn-toggle mr-3 ml-0">
                                      <span className="card-btn-toggle-active">-</span>
                                      <span className="card-btn-toggle-default">+</span>
                                    </span>
                                    <span className="media-body">
                                      <span className="text-body font-weight-bold mr-5">How to upload a tenancy agreement and a stamp</span>
                                    </span>
                                  </span>
                                </span>
                              </span> 
                            </a>
                          </div>
                          <div id="coursesCollapseFourteen" className="collapse " aria-labelledby="coursesHeadingFourteen">
                            <div className="card-body p-0">
                              <div className="border-bottom py-3 pr-3 pl-6">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="faqContent">   
                                      <span className="media-body">
                                        <span>
                                          <p>Before you create the customers, you need upload the tenancy agreement otherwise the customers won’t see it before moving in. Here is how you upload the tenancy agreement: you click on ‘Residence Profile’ under the ‘company’ menu. 
                                            You then scroll down to the ‘documents’ section to click on ‘upload new.’ From there, you upload both the tenancy agreement and the stamp to be placed on the proof of residence your customers request directly from the system. 
                                            This is to make it easy for the customers to get their true proof of residence, while avoiding fake ones (the ones without a stamp) at the same time. When you are done, you just click on UPLOAD and voila!</p>		
                                          <center><img  className="faqimage" src="../../assets/faqimages/42.png"></img></center>
                                        </span>
                                      </span>
                                    </div>
                                  </div>                               
                                </div>
                              </div>                             
                            </div>
                          </div>
                        </div>

                          {/*box six*/}
                        <div className="card border mb-1">
                          <div className="card-header card-collapse" id="coursesHeadingThirtySeven1">
                            <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtySeven1" aria-expanded="false" aria-controls="coursesCollapseThirtySeven1">
                              <span className="row">
                                <span className="col-12">
                                  <span className="media">
                                    <span className="card-btn-toggle mr-3 ml-0">
                                      <span className="card-btn-toggle-active">-</span>
                                      <span className="card-btn-toggle-default">+</span>
                                    </span>
                                    <span className="media-body">
                                      <span className="text-body font-weight-bold mr-5">How to create an additional service</span>
                                    </span>
                                  </span>
                                </span>
                              </span>                
                            </a>
                          </div>
                          <div id="coursesCollapseThirtySeven1" className="collapse " aria-labelledby="coursesHeadingThirtySeven1">
                            <div className="card-body p-0">                
                              <div className="border-bottom py-3 pr-3 pl-6">
                                <div className="row">
                                  <div className="col-12"> 
                                      <div className="faqContent">                             
                                      <span className="media-body">
                                        <span> <p>An additional service is any service your customers can choose to benefit from or not. For example, if your residence offers transportation for an additional R200/month on the rent, you can create the additional service "Transportation" and write "200" for the price.
                                            An additional service is optional. Anything that's mandatory should just be added on the rent amount.</p>
                                            <p>To create an additional service, go to 'manage category' and then click on 'Service'. You then click on ADD NEW and you are taken to the page where you can write the additionnal service's name and price.
                                            The description is optional since it's not going to appear for the clients.</p>
                                            <center><img  className="faqimage" src="../../assets/faqimages/service.png"></img></center><br></br>
                                            You then click on CREATE and voila!
                                        </span>
                                      </span>  
                                      </div>                       
                                  </div>                        
                                </div>
                              </div>        
                            </div>
                          </div>
                        </div>  

                       {/* box seven*/}
                      <div className="card border mb-1">
                        <div className="card-header card-collapse" id="coursesHeadingSixteen">
                          <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseSixteen" aria-expanded="false" aria-controls="coursesCollapseSixteen">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">How to create a room category</span>
                                  </span>
                                </span>
                              </span>
                            </span>                                          
                          </a>
                        </div>
                        <div id="coursesCollapseSixteen" className="collapse " aria-labelledby="coursesHeadingSixteen">
                          <div className="card-body p-0">
                            <div className="border-bottom py-3 pr-3 pl-6">
                              <div className="row">
                                <div className="col-12">  
                                  <div className="faqContent">                                  
                                    <span className="media-body">
                                        <span>
                                          <p> These are the room categories that the residence offers to its clients.
                                              Some of the common ones for student accommodations are single and sharing. This is where you put the room types you have and their prices.<br></br> 
                                              To create a room category, you click on ‘manage category’ under the ‘company’ menu.</p>		
                                          <center><img  className="faqimage" src="../../assets/faqimages/48.png"></img></center><br></br>
                                            To add a new room category, you click on ADD NEW. <br></br>
                                          <center><img  className="faqimage" src="../../assets/faqimages/49.png"></img></center>
                                            <p>You are then asked to provide the following information:<br></br>
                                            -	The room category name, <br></br>
                                            - The category description: this is meant to market the category for your prospects in the Booking page, <br></br>
                                            -	The deposit amount, the number of people who can occupy that room (for a sharing room, it can be 2 for example), <br></br>
                                            -	The rental price of a room of this category, <br></br>
                                            -	The daily price (this price will be used in case a customer moves in at the end of the month and can’t pay the full rent amount for that month),<br></br>
                                            -	Whether the rent of the first month is on special or not. If it is, specify the amount,<br></br>
                                            - You can select amoung the services you just created, the ones you offer the clients who go for this category,<br></br>
                                            - The points of interests: these are the things that can further convice your prospects to stay at your place. For example, you can write "5km from Mall of Africa" or "Free wifi" or "10km from UJ", etc.<br></br>
                                            When you are done, you click on CREATE and voila!</p>
                                            <p>Now you can go ahead and click on the "Images" button, to upload the images that will attract your customers: the front image is the one they will see on the Booking page and the additional ones are the ones they will see when they click to see more.<br></br>
                                            <center><img  className="faqimage" src="../../assets/faqimages/73.png"></img></center><br></br>
                                            After selecting a picture from your device, you click on UPLOAD to add it. To change the front image, just upload a new one. To delete an additional image, click on 'Edit' and select the images you want to delete then click on 'Remove selected'.</p>
                                            <p>After creating a category, you have to activate it for it to appear on the Booking page. This means you can also deactivate it if you don't want it to appear on the Booking page for a while.</p>
                                      </span>
                                    </span>    
                                  </div>                           
                                </div>                           
                             </div>
                           </div>                         
                         </div>
                       </div>
                     </div>

                     {/* box eight*/}
                     <div className="card border mb-1">
                       <div className="card-header card-collapse" id="coursesHeadingSeventeen">
                         <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseSeventeen" aria-expanded="false" aria-controls="coursesCollapseSeventeen">
                            <span className="row">
                              <span className="col-12">
                                <span className="media">
                                  <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-active">-</span>
                                    <span className="card-btn-toggle-default">+</span>
                                  </span>
                                  <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">How to create a block</span>
                                  </span>
                                </span>
                             </span>
                           </span>                     
                         </a>
                      </div>
                      <div id="coursesCollapseSeventeen" className="collapse " aria-labelledby="coursesHeadingSeventeen">
                        <div className="card-body p-0">
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">     
                                <div className="faqContent">                              
                                  <span className="media-body">
                                    <span>
                                      <p>A block is a collection of rooms found under the same building or floor. Room numbers of the same block usually start with the same prefix.<br></br> 
                                         After creating the room categories, you now go to ‘manage block’ to create the residence blocks.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/50.png"></img></center>
                                      <p>To create a block, you click on ADD NEW. You are then asked to enter the block name and prefix. This prefix will be used in front of the room numbers of the block. You also enter the number of rooms contained in this block, for the system to automatically create them for you.</p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/51.png"></img></center>
                                      <p>After giving all this information, you click on CREATE to finalize the process. The rooms are now created and you can assign categories to each, those categories you created under ‘manage category’. You just click on ‘choose category’ and select the one you want to assign to the room.
                                        If the number of people who can stay in a room category is more than one, assigning that category to the room splits it into that number. For example A1-A and A1-B if you assign a 2 people category to the A1 room. This will help you to identify each tenant individually, even if they share a room.
                                      </p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/52.png"></img></center>
                                      <p>On this page, you can also see the room numbers, the tenants occupying each room and whether a room is available or not.</p>
                                    </span>
                                  </span>   
                                </div>                    
                              </div>                            
                            </div>
                          </div>                         
                        </div>
                      </div>
                    </div>

                    {/* box nine*/}
                    <div className="card border mb-1">
                          <div className="card-header card-collapse" id="coursesHeadingFifteen">
                            <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseFifteen" aria-expanded="false" aria-controls="coursesCollapseFifteen">
                              <span className="row">
                                <span className="col-12">
                                  <span className="media">
                                    <span className="card-btn-toggle mr-3 ml-0">
                                      <span className="card-btn-toggle-active">-</span>
                                      <span className="card-btn-toggle-default">+</span>
                                    </span>
                                    <span className="media-body">
                                      <span className="text-body font-weight-bold mr-5">How to create a customer</span>
                                    </span>
                                  </span>
                                </span>
                              </span>  
                            </a>
                          </div>
                          <div id="coursesCollapseFifteen" className="collapse " aria-labelledby="coursesHeadingFifteen">
                            <div className="card-body p-0">
                              <div className="border-bottom py-3 pr-3 pl-6">
                                <div className="row">
                                  <div className="col-12">   
                                    <div className="faqContent">                                   
                                      <span className="media-body">
                                        <span>
                                        <p>To create the customers, you (the admin) need to create a sub-role under the customer role first. A sub-role is a position held by someone in your company. Tenant and house comm are examples of sub-roles for customers. For admins, we can say
                                        cleaner, security, manager and so on. Creating different su-roles allows you to group your people, so you can send a message to your tenants only for example. Creating sub-roles also allows you to decide on what each type of
                                        user in your company can do in the system, by restricting their functionalities if you want. For example, you can decide that tenants can receive feeds but they can't create them.</p>
                                        <p>To create a sub-role, you click on ‘manage sub-role’ under the ‘company’ menu. Provided the super admin gave you the right to get this menu. When you click, you will see the sub-roles the super admin already created for the residence.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/43.png"></img></center>
                                        <p>To create the sub-role, you click on ADD NEW and you are taken to the following page:</p>	
                                        <center><img  className="faqimage" src="../../assets/faqimages/44.png"></img></center>
                                        <p>You choose Customer/CUS for the parent sub-role and you write the sub-role name in the sub-role name field. You then click on CREATE.</p>
                                        <center><img  className="faqimage" src="../../assets/faqimages/440.png"></img></center>
                                        <p>The next step is to assign menus to the sub-role, to determine the menus these customers will get.</p>	
                                        <center><img  className="faqimage" src="../../assets/faqimages/45.png"></img></center>
                                        <p>You can edit the sub-role name by clicking on EDIT SUB-NAME. To assign the menus, you click on the CREATE MENU button. You are then taken to the following page:</p>	
                                        <center><img  className="faqimage" src="../../assets/faqimages/46.png"></img></center>
                                        <p>The menus appear and for you to assign them to the sub-role, you click on them. Let’s say you want to give the Profile menu and some of its privileges. You will click on ‘Profile.’ You will then see the different privileges under the Profile menu that you can assign to the sub-role. 
                                           You then just click on the checkboxes and when you are done, you click on Add Privilege(s). You use the same process for the other menus that you want to give too. This means that, if you don’t do this process for a certain menu, the people under this sub-role will not get that menu in their accounts. When you are done, you click on the CREATE button.</p>	
                                        <center><img  className="faqimage" src="../../assets/faqimages/47.png"></img></center>
                                        <p>To add or delete a menu, you just proceed as explained earlier under 'How to create an admin' for the super admin.</p>
                                        <p>You can now proceed with the creation of the customer. You click on 'customer' under the ‘user’ menu.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/34.png"></img></center>
                                        <p>On the page that appears, you click on ADD NEW and you are taken to the page below where you enter the necessary information. </p>
                                        <center><img  className="faqimage" src="../../assets/faqimages/340.png"></img></center>
                                        <p>Before you send them the link to join the company (to the email address you just wrote), you can see their profile by clicking on VIEW PROFILE.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/35.png"></img></center>
                                        <p>After viewing their profile, the 'SEND PROFORMA' button appears so you can create the request. This action creates a Move Request you can check on your (the admin) Dashboard in order to approve the client's payment and let them in the residence.</p>
                                       </span>
                                     </span>  
                                    </div>                   
                                  </div>                            
                                </div>
                              </div>                         
                            </div>
                         </div>
                       </div> 

                    {/* box ten*/}
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingHeighteen">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseHeighteen" aria-expanded="false" aria-controls="coursesCollapseHeighteen">
                          <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to approve a move in request</span>
                                </span>
                              </span>
                            </span>
                          </span>                                             
                        </a>
                      </div>
                      <div id="coursesCollapseHeighteen" className="collapse " aria-labelledby="coursesHeadingHeighteen">
                        <div className="card-body p-0">
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">   
                                <div className="faqContent">                               
                                  <span className="media-body">
                                    <span>
                                      <p>To approve a customer’s move in request, you click on 'Move Requests' from your Dashboard. All the move in and move out requests then appear.</p>		                                    
                                      <center><img  className="faqimage" src="../../assets/faqimages/55.png"></img></center>
                                      <p>You can click on ‘view’ to see the move in request details of a specific customer.</p>	   
                                      <center><img  className="faqimage" src="../../assets/faqimages/cs56.png"></img></center>
                                      <p>You can enter the amount paid by the tenant to move in and click on EVALUATE to share the amount between the deposit, admin fee, balance, rent of the current month. 
                                        If the tenant pays more than the move in fee, the rest goes to their balance and will be used to pay for the rent of the next months. If the amount paid is equal or greater than the move in fee, the move in request can be approved and the tenant is automatically activated in the system. 
                                        On this same page, you see the proof of payment uploaded by the client, to know which amount to evaluate.</p>		                             
                                      <center><img  className="faqimage" src="../../assets/faqimages/cs57.png"></img></center>
                                      <p>Once this is done, you can see the tenant in the room number you gave to him/her, in the ‘manage block’ sub-menu. The room is now unavailable for other tenants to move in.</p>		                                          
                                      <center><img  className="faqimage" src="../../assets/faqimages/58.png"></img></center>
                                      <p>You can also see the list of all the current tenants, and whether they have paid the current month or not yet by clicking on 'customer' in the 'user' sub role.</p>	                                             
                                      <center><img  className="faqimage" src="../../assets/faqimages/59.png"></img></center>
                                      <p>If a tenant is leaving for a while (at the end of the year for example) but is coming back, you can deactivate their account and reactivate it when they come back. This will prevent them from getting the statement when you generate it. To do that, you just click on VIEW to see the user’s profile then you click on the Activate/Deactivate button.
                                        When you reactivate the tenant, you are directly taken to a page to send them a new proforma, since they are moving in again. Since he already stayed at your residence, you have the choice of making them pay for the deposit and the admin fee again or not.</p>	                      
                                      <center><img  className="faqimage" src="../../assets/faqimages/60.png"></img></center>
                                      <p>You (the admin) can see the statement of a user by clicking on ‘statement’ then VIEW MORE. In that page, the ‘outstanding orders’ represents the amount the tenant owes for the current month. The settlement is the amount a user owes to the company from previous months.</p>		                                 
                                      <center><img  className="faqimage" src="../../assets/faqimages/61.png"></img></center>
                                    </span>
                                  </span>   
                                </div>                          
                              </div>                            
                            </div>
                          </div>                          
                        </div>
                      </div>
                    </div> 

                    {/*box eleven*/}
                    <div className="card border mb-1">
                    <div className="card-header card-collapse" id="coursesHeadingTwelve12">
                      <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwelve12" aria-expanded="false" aria-controls="coursesCollapseTwelve12">                           
                        <span className="row">
                          <span className="col-12">
                            <span className="media">
                              <span className="card-btn-toggle mr-3 ml-0">
                                <span className="card-btn-toggle-active">-</span>
                                <span className="card-btn-toggle-default">+</span>
                              </span>
                              <span className="media-body">
                                <span className="text-body font-weight-bold mr-5">How to generate your customers' invoices</span>
                              </span>
                            </span>
                          </span>
                        </span> 
                      </a>
                    </div>
                    <div id="coursesCollapseTwelve12" className="collapse " aria-labelledby="coursesHeadingTwelve12">
                      <div className="card-body p-0">
                        <div className="border-bottom py-3 pr-3 pl-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="faqContent">   
                                <span className="media-body">
                                  <span> By "customers' invoice", I mean the amount your tenants need to pay you. After generating it (for the month you want), your customers will get something like this on their side:<br></br>
                                  <center><img  className="faqimage" src="../../assets/faqimages/69.png"></img></center><br></br>
                                  <p>In the above example, you have generated the statement for your customers to pay the June rent. Once the payment is made, they will just have to click on the 'Details' button to send you their proof of payment.
                                  Or they can pay you online on that page if they prefer, by clicking on the PAY NOW button. Whatever the payment method they use, after submitting the proof of payment they come back to the previous page to click on ASK FOR APPROVAL.
                                  This will send you an email notifying you that a customer has paid their rent and wants you to look at their proof of payment to approve their payment.</p>
                                  <center><img  className="faqimage" src="../../assets/faqimages/70.png"></img></center><br></br>
                                    Here is how to generate the invoice: you go to the 'Residence Profile' under the 'Company' menu. You then click on the 'Generate Invoice' button.<br></br> 
                                    <center><img  className="faqimage" src="../../assets/faqimages/72.png"></img></center><br></br>
                                    In the next page, you will be asked to choose the month you want to generate the invoices for.
                                    On the calendar, you select a date of the month you want and click on PROCEED to generate the invoices. This will generate the invoices for the customers you have in the residence.<br></br>
                                    <center><img  className="faqimage" src="../../assets/faqimages/invoice.png"></img></center>
                                    When a super admin creates a residence, they are asked to write the payment date. This is the date of the month when you want your customers to pay the business so, we will use it to generate the invoices. 
                                    For example, let's say the super admin chose the "3rd" as the payment date for a residence and you generate the June invoice for the residence. The tenants of this residence will see "June the 3rd" as the paymet date for June.<br></br>
                                  </span>
                                </span>    
                              </div>                              
                            </div>                                
                          </div>
                        </div>                              
                      </div>
                    </div>
                  </div>

                     {/*box twelve*/}
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingThirty03">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirty03" aria-expanded="false" aria-controls="coursesCollapseThirty03">
                          <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to send a feed</span>
                                </span>
                              </span>
                            </span>
                          </span> 
                        </a>
                      </div>
                      <div id="coursesCollapseThirty03" className="collapse " aria-labelledby="coursesHeadingThirty03">
                        <div className="card-body p-0">                     
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">  
                                  <div className="faqContent">                            
                                  <span className="media-body">
                                    <span>
                                    <p>You click on 'Manage feed' under the 'Communication' menu and you click on ADD NEW. 
                                      You get a popup asking you to choose the subrole with whom you want to communicate (this means only the people to whom you assigned this sub role will get the message), the subject of the message and the message itself. </p>   
                                    <center><img  className="faqimage" src="../../assets/faqimages/feed.png"></img></center> <br></br>
                                    <p>You then click on SEND and next time they go to their Dashboard, they see the message.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/feed1.png"></img></center> <br></br>
                                    <p>Even after sending a feed, you can still edit its content and subject by clicking on the EDIT button.</p>                           
                                    </span>
                                  </span>
                                  </div>                          
                              </div>                        
                            </div>
                          </div>                     
                        </div>
                      </div>
                    </div>          

                    {/* box twelve*/}
                    <div className="nacomodausers">CUSTOMER</div>  
                    <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingCent">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseCent" aria-expanded="false" aria-controls="coursesCollapseCent">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to make your first payment to move in</span>
                             </span>
                           </span>
                         </span>
                       </span>           
                     </a>
                   </div>
                   <div id="coursesCollapseCent" className="collapse " aria-labelledby="coursesHeadingCent">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                             <div className="faqContent">                            
                               <span className="media-body">
                                 <span>
                                    <p>After the company creates the request for you on their side, you login to your account and click on 'My Bookings' from your Dashboard then 'view' the request you want to ask approval for. 
                                      Let's point that ask for approvals are only for move in requests you made to estates, complexes or student accommodations, not to the other residence types.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/67.png"></img></center>
                                    <p>You now see your proforma (that has the amount to pay) and the company's tenancy agreement (if they uploaded one). You can upload your proof of payment and ask for approval by clicking on the 'Ask for Approval' button.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/68.png"></img></center>
                                 </span>
                               </span>   
                              </div>                       
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div> 

                 {/*box thirteen*/}
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingNineteen">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseNineteen" aria-expanded="false" aria-controls="coursesCollapseNineteen">
                          <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to ask for approval of a monthly payment (when you already stay at the residence)</span>
                                </span>
                              </span>
                            </span>
                         </span>
                        </a>
                      </div>
                      <div id="coursesCollapseNineteen" className="collapse " aria-labelledby="coursesHeadingNineteen">
                        <div className="card-body p-0">
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">
                                <div className="faqContent">   
                                  <span className="media-body">
                                    <span>
                                      <p>To ask for approval after making a payment, you go to ‘profile’ then to ‘statement’. After clicking on VIEW DETAILS, your statement appears.</p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/69.png"></img></center>
                                      <p>To upload your proof of payment for a specific month, you then click on ‘Details’ next to the month you want the payment to be approved. From that page, you can also make an online payment to the residence by using the PAY NOW button.</p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/70.png"></img></center>
                                      <p>When you are done, you click on the BACK button to ask for approval by clicking on the ASK FOR APPROVAL button.</p>
                                    </span>
                                  </span>  
                                </div>               
                              </div>                           
                            </div>
                          </div>                          
                        </div>
                      </div>
                    </div>  
                                             
                    {/* box fourteen*/}
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingTwenty">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwenty" aria-expanded="false" aria-controls="coursesCollapseTwenty">
                          <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to get your proof of residence</span>
                                </span>
                              </span>
                            </span>
                         </span> 
                        </a>
                      </div>
                      <div id="coursesCollapseTwenty" className="collapse " aria-labelledby="coursesHeadingTwenty">
                        <div className="card-body p-0">                          
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">    
                                <div className="faqContent">                             
                                  <span className="media-body">
                                    <span>
                                      <p>To get your proof of residence, click on ‘Profile’ then on the 'Document' tab. You now click on ‘Proof of Residence’ and that will download your proof of residence with your details and the residence’s stamp (if they have uploaded one).</p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/71.png"></img></center> 
                                    </span>
                                  </span>    
                                </div>                         
                              </div>                           
                            </div>
                          </div>                          
                        </div>
                      </div>
                    </div>
                 </div>
                </div>

                {/* ESTATE/COMPLEX */}
                <div className="tab-pane fade text-black-70 " id="pills-three-code-sample" role="tabpanel" aria-labelledby="pills-three-code-sample-tab">
                  <div className="tab-pane fade pt-5 show active" id="pills-one-code-sample" role="tabpanel" aria-labelledby="pills-one-code-sample-tab">
                    <div className="nacomodausers">SUPER ADMIN</div> 
                    {/*box one*/}                                    
                    <div className="card border mb-1 ">
                      <div className="card-header card-collapse" id="coursesHeadingTwentyone">
                       <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentyone" aria-expanded="false" aria-controls="coursesCollapseTwentyone">
                          <span className="row ">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to create an estate or a complex</span>
                                </span>
                              </span>
                            </span>
                         </span>                         
                        </a>
                      </div>
                      <div id="coursesCollapseTwentyone" className="collapse " aria-labelledby="coursesHeadingTwentyone">
                        <div className="card-body p-0">                   
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12"> 
                                <div className="faqContent">                                  
                                  <span className="media-body">
                                    <span>
                                      <p>To create an estate or a complex of your company, you click on the ADD NEW button.</p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/C1.png"></img></center>
                                      <p>You are then taken to the below page, to fill in the estate or the complex information. For the residence type, make sure to choose 'Estate or Complex'.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/21.png"></img></center>
                                      <p>After entering the estate or the complex information, you get the following popup message.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/22.png"></img></center>
                                    </span>
                                  </span>   
                                </div>                          
                              </div>                           
                            </div>
                          </div>                         
                        </div>
                      </div>
                    </div>    
                    {/*box two*/}     
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingTwentytwo">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentytwo" aria-expanded="false" aria-controls="coursesCollapseTwentytwo">
                          <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to write (or update) an estate or a complex's banking details</span>
                                </span>
                              </span>
                            </span>
                          </span>             
                        </a>
                      </div>
                      <div id="coursesCollapseTwentytwo" className="collapse " aria-labelledby="coursesHeadingTwentytwo">
                        <div className="card-body p-0">
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12"> 
                                <div className="faqContent">                                 
                                  <span className="media-body">
                                    <span>
                                      <p>To write or update the banking details of the estate or the complex (this is the account where tenants pay the property), you go to ‘Residence Profile’ and then click on ‘bank info.’ In that page, you can also set the admin price that clients pay to move in.
                                        You can also write your PayFast information here to receive online payments.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/23.png"></img></center>
                                      <p>The record status in this page shows the person who last updated any information on this page. Actually, in any page where you see a ‘record status’, its role is to notify on who last updated any information on the page.</p>
                                    </span>
                                  </span>     
                                </div>                         
                              </div>                          
                            </div>
                          </div>                         
                        </div>
                      </div>
                    </div>

                    {/*box three*/}
                  

                    {/* box four*/}       
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingTwentythree">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentythree" aria-expanded="false" aria-controls="coursesCollapseTwentythree">
                         <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to create an admin (or employee)</span>
                                </span>
                              </span>
                            </span>
                          </span> 
                        </a>
                      </div>
                      <div id="coursesCollapseTwentythree" className="collapse " aria-labelledby="coursesHeadingTwentythree">
                        <div className="card-body p-0">
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">   
                                <div className="faqContent">                             
                                  <span className="media-body">
                                    <span>
                                      <p>Now that the estate or the complex is created, you can create an admin to continue the process (or you can continue yourself if you prefer because anything an admin can do, a super admin can do). To create the admin, you need to create a sub-role under the admin role first. A sub-role is a position held by someone in your company. Tenant and house comm are examples of sub-roles for customers. 
                                        For admins, we can say cleaner, security, manager and so on. Creating different su-roles allows you to group your people, so you can send a message to your tenants only for example. Creating sub-roles also allows you to decide on what each type of
                                        user in your company can do in the system, by restricting their functionalities if you want. For example, you can decide that tenants can receive feeds but they can't create them.
                                        To do that, you create a sub-role with its menu (the menu you want the people under that sub-role to get). </p>
                                      <p>The first step is to click on the ‘manage sub-role’ sub-menu under the ‘company’ menu. On the page that appears (as shown below), you click on the ADD NEW button.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/25.png"></img></center>
                                      <p>After clicking on the ADD NEW button, you fill in the form as it appears:</p>
                                      <p>-	For the parent role name, you choose the Administrator/ADM option.<br></br>
                                         -	You enter the sub-role name. For example secretary, cleaner, security, etc. Remember, admins are your employees.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/26.png"></img></center>
                                      <p>You then click on the CREATE button and you receive a popup message asking you to assign the menus to the sub-role you just created.</p>
                                      <p>These are the menus you want the people under the sub-role to get when you create them.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/27.png"></img></center>
                                      <p>You close the popup by clicking on the OK button, then you click on ‘menu’ to assign the menus. You will be taken to the following page:</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/28.png"></img></center>
                                      <p>You can edit the sub-role name by clicking on EDIT SUB-NAME. To assign the menus, you click on the CREATE MENU button. </p>
                                      <p>You are then taken to the following page:</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/29.png"></img></center>
                                      <p>The menus appear and for you to assign them to the sub-role, you click on them. Let’s say you want to give the Residence Profile sub-menu and some of its privileges. You will click on ‘Company’ and then on Residence Profile. 
                                        You will then see the different privileges under the Business Profile sub-menu, that you can assign to the sub-role. You then just click on the checkboxes and when you are done, you click on Add Privilege(s). You use the same process for the other menus that you want to give too. 
                                        This means that, if you don’t do this process for a certain menu, the people under this sub-role will not get that menu in their accounts. When you are done, you click on the CREATE button.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/30.png"></img></center>
                                      <p>In case you want to add some menus that you didn’t give in the beginning, you can just click ‘menu’ again, but this time you will see the menus you already gave and the button to click to add more menus will be ADD TO MENU.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/31.png"></img></center>
                                      <p>After clicking on ADD TO MENU, you will see the menus you didn’t give (unless you already gave the sub-role all the menus). You then just click on the menu you want to add, click on the privileges you want to give, click on ADD and voila!</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/32.png"></img></center>                                   
                                      <p>To delete a menu from a sub-role, you click on the menu and then on the delete icon top-left to delete it. You can also delete a submenu by clicking on the 'delete' icon at its left.</p>			
                                      <center><img  className="faqimage" src="../../assets/faqimages/subrole1.png"></img></center>
                                      <p>You can now proceed with the creation of the admin. You click on ‘admin’ under the ‘user’ menu.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/admin1.png"></img></center>
                                      <p>On the page that appears, you click on ADD NEW and you are taken to the page below where you enter the admin email and choose the sub-role you want to give them. You can now click on the SEND button to invite them into the company via a link (that will go to the email you just provided).</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/admin2.png"></img></center>
                                    </span>
                                  </span> 
                               </div>              
                            </div>                       
                         </div>
                       </div>                   
                     </div>
                   </div>
                 </div> 
                    
                 {/* box five */}   
                 <div className="nacomodausers">ADMIN</div>     
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingTwentyfour">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentyfour" aria-expanded="false" aria-controls="coursesCollapseTwentyfour">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to upload a tenancy agreement and a stamp</span>
                             </span>
                           </span>
                         </span>
                       </span>         
                     </a>
                   </div>
                   <div id="coursesCollapseTwentyfour" className="collapse " aria-labelledby="coursesHeadingTwentyfour">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                            <div className="faqContent">                            
                                <span className="media-body">
                                  <span>
                                    <p>Before you create the customers, you need upload the tenancy agreement otherwise the customers won’t see it before moving in. Here is how you upload the tenancy agreement: you click on ‘Residence Profile’ under the ‘company’ menu. 
                                      You then scroll down to the ‘documents’ section to click on ‘upload new.’ From there, you upload both the tenancy agreement and the stamp to be placed on the proof of residence your customers request directly from the system. 
                                      This is to make it easy for the customers to get their true proof of residence, while avoiding fake ones (the ones without a stamp) at the same time. When you are done, you just click on UPLOAD and voila!</p>		
                                    <center><img  className="faqimage" src="../../assets/faqimages/42.png"></img></center>
                                  </span>
                                </span> 
                              </div>                         
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>

                  {/*box six*/}
                  <div className="card border mb-1">
                    <div className="card-header card-collapse" id="coursesHeadingThirtySeven2">
                      <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtySeven2" aria-expanded="false" aria-controls="coursesCollapseThirtySeven2">
                        <span className="row">
                          <span className="col-12">
                            <span className="media">
                              <span className="card-btn-toggle mr-3 ml-0">
                                <span className="card-btn-toggle-active">-</span>
                                <span className="card-btn-toggle-default">+</span>
                              </span>
                              <span className="media-body">
                                <span className="text-body font-weight-bold mr-5">How to create an additional service</span>
                              </span>
                            </span>
                          </span>
                        </span>                
                      </a>
                    </div>
                    <div id="coursesCollapseThirtySeven2" className="collapse " aria-labelledby="coursesHeadingThirtySeven2">
                      <div className="card-body p-0">                
                        <div className="border-bottom py-3 pr-3 pl-6">
                          <div className="row">
                            <div className="col-12"> 
                                <div className="faqContent">                             
                                <span className="media-body">
                                  <span> <p>An additional service is any service that your customers can choose to benefit from or not. For example, if your residence offers transportation for an additional R200/month on the rent, you can create the additional service "Transportation" and write "200" for the price.
                                      An additional service is optional. Anything that's mandatory should just be added on the rent amount.</p>
                                      <p>To create an additional service, go to 'manage category' and then click on 'Service'. You then click on ADD NEW and you are taken to the page where you can write the additionnal service's name and its price.
                                      The description is optional since it's not going to appear for the clients.</p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/service.png"></img></center><br></br>
                                      You then click on CREATE and voila!
                                  </span>
                                </span>  
                                </div>                       
                            </div>                        
                          </div>
                        </div>        
                      </div>
                    </div>
                  </div>  

                 {/* box seven*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingTwentysix">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentysix" aria-expanded="false" aria-controls="coursesCollapseTwentysix">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to create an appartment category</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseTwentysix" className="collapse " aria-labelledby="coursesHeadingTwentysix">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">
                              <div className="faqContent">   
                               <span className="media-body">
                                 <span>
                                 <p>These are the appartment categories the residence offers to its clients.
                                    Some of the common ones for estates and complexes are 2 bedrooms, 2.5 bathrooms, etc. This is where you put the appartment types you have and their prices.<br></br> 
                                    To create an appartment category, you click on ‘manage category’ under the ‘company’ menu.</p>		
                                  <center><img  className="faqimage" src="../../assets/faqimages/48.png"></img></center><br></br>
                                   To add a new appartment category, you click on ADD NEW. <br></br>
                                  <center><img  className="faqimage" src="../../assets/faqimages/49.png"></img></center>
                                  <p>You are then asked to provide the following information:<br></br>
                                  -	The appartment category, <br></br>
                                  - The category description: this is meant to market the category for your prospects in the Booking page,<br></br>
                                  -	The deposit amount, <br></br>
                                  -	The rental price of an appartment of this category, <br></br>
                                  -	The daily price (this price will be used in case a customer moves in at the end of the month and can’t pay the full rent amount for that month),<br></br>
                                  -	Whether the rent of the first month is on special or not. If it is, specify the amount,<br></br>
                                  - You can select amoung the services you just created, the ones you offer the clients who go for this category,<br></br>
                                  - The points of interests: these are the things that can further convice your prospects to stay at your place. For example, you can write "5km from Mall of Africa" or "Free wifi" or "10km from UJ", etc.<br></br>
                                  When you are done, you click on CREATE and voila!</p>
                                  <p>Now you can go ahead and click on the "Images" button, to upload the images that will attract your customers: the front image is the one they will see on the Booking page and the additional ones are the ones they will see when they click to see more.<br></br>
                                  <center><img  className="faqimage" src="../../assets/faqimages/73.png"></img></center><br></br>
                                  After selecting a picture from your device, you click on UPLOAD to add it. To change the front image, just upload a new one. To delete an additional image, click on 'Edit' and select the images you want to delete then click on 'Remove selected'.</p>
                                  <p>After creating a category, you have to activate it for it to appear on the Booking page. This means you can also deactivate it if you don't want it to appear on the Booking page for a while.</p>
                                 </span>
                               </span> 
                              </div>                          
                           </div>                         
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div>

                 {/* box eight*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingTwentyseven">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentyseven" aria-expanded="false" aria-controls="coursesCollapseTwentyseven">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to create a block</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseTwentyseven" className="collapse " aria-labelledby="coursesHeadingTwentyseven">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                               <span className="media-body">
                                 <span>
                                 <p>A block is a collection of appartments found under the same building or floor. Appartment numbers of the same block usually start with the same prefix.<br></br> 
                                    After creating the appartment categories, you now go to ‘manage block’ to create the residence blocks.</p>		
                                  <center><img  className="faqimage" src="../../assets/faqimages/50.png"></img></center>
                                  <p>To create a block, you click on ADD NEW. You are then asked to enter the block name and prefix. This prefix will be used in front of the appartment numbers of the block. You also enter the number of appartments contained in this block, for the system to automatically create them for you.</p>
                                  <center><img  className="faqimage" src="../../assets/faqimages/51.png"></img></center>
                                  <p>After giving all this information, you click on CREATE to finalize the process. The appartments are now created and you can assign categories to each, those categories you created under ‘manage category’. You just click on ‘choose category’ and select the one you want to assign to the appartment. </p>
                                  <center><img  className="faqimage" src="../../assets/faqimages/52.png"></img></center>
                                  <p>On this page, you can also see the appartment numbers, the tenants occupying each appartment and whether an appartment is available or not.</p>
                                 </span>
                               </span> 
                              </div>         
                           </div>                        
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>

                 {/* box nine*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingTwentyfive">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentyfive" aria-expanded="false" aria-controls="coursesCollapseTwentyfive">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to create a customer</span>
                             </span>
                           </span>
                         </span>
                       </span>                
                     </a>
                   </div>
                   <div id="coursesCollapseTwentyfive" className="collapse " aria-labelledby="coursesHeadingTwentyfive">
                     <div className="card-body p-0">                
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">    
                              <div className="faqContent">                          
                               <span className="media-body">
                                 <span>
                                 <p>To create the customers, you (the admin) need to create a sub-role under the customer role first. A sub-role is a position held by someone in your company. Tenant and house comm are examples of sub-roles for customers. For admins, we can say
                                  cleaner, security, manager and so on. Creating different su-roles allows you to group your people, so you can send a message to your tenants only for example. Creating sub-roles also allows you to decide on what each type of
                                  user in your company can do in the system, by restricting their functionalities if you want. For example, you can decide that tenants can receive feeds but they can't create them.</p>
                                  <p>To create a sub-role, you click on ‘manage sub-role’ under the ‘company’ menu. Provided the super admin gave you the right to get this menu. When you click, you will see the sub-roles the super admin already created for the residence.</p>		
                                  <center><img  className="faqimage" src="../../assets/faqimages/43.png"></img></center>
                                  <p>To create the sub-role, you click on ADD NEW and you are taken to the following page:</p>	
                                  <center><img  className="faqimage" src="../../assets/faqimages/44.png"></img></center>
                                  <p>You choose Customer/CUS for the parent sub-role and you write the sub-role name in the sub-role name field. You then click on CREATE.</p>
                                  <center><img  className="faqimage" src="../../assets/faqimages/440.png"></img></center>
                                  <p>The next step is to assign menus to the sub-role, to determine the menus these customers will get.</p>	
                                  <center><img  className="faqimage" src="../../assets/faqimages/45.png"></img></center>
                                  <p>You can edit the sub-role name by clicking on EDIT SUB-NAME. To assign the menus, you click on the CREATE MENU button. You are then taken to the following page:</p>	
                                  <center><img  className="faqimage" src="../../assets/faqimages/46.png"></img></center>
                                  <p>The menus appear and for you to assign them to the sub-role, you click on them. Let’s say you want to give the Profile menu and some of its privileges. You will click on ‘Profile.’ You will then see the different privileges under the Profile menu that you can assign to the sub-role. 
                                      You then just click on the checkboxes and when you are done, you click on Add Privilege(s). You use the same process for the other menus that you want to give too. This means that, if you don’t do this process for a certain menu, the people under this sub-role will not get that menu in their accounts. When you are done, you click on the CREATE button.</p>	
                                  <center><img  className="faqimage" src="../../assets/faqimages/47.png"></img></center>
                                  <p>To add or delete a menu, you just proceed as explained earlier under 'How to create an admin' for the super admin.</p>
                                  <p>You can now proceed with the creation of the customer. You click on 'customer' under the ‘user’ menu.</p>		
                                  <center><img  className="faqimage" src="../../assets/faqimages/34.png"></img></center>
                                  <p>On the page that appears, you click on ADD NEW and you are taken to the page below where you enter the necessary information. </p>
                                  <center><img  className="faqimage" src="../../assets/faqimages/340.png"></img></center>
                                  <p>Before you send them the link to join the company (to the email address you just wrote), you can see their profile by clicking on VIEW PROFILE.</p>		
                                  <center><img  className="faqimage" src="../../assets/faqimages/35.png"></img></center>
                                  <p>After viewing their profile, the 'SEND PROFORMA' button appears so you can create the request. This action creates a Move Request you can check on your (the admin) Dashboard in order to approve the client's payment and let them in the residence.</p>
                                 </span>
                               </span>  
                             </div>                       
                           </div>                        
                         </div>
                       </div>        
                     </div>
                   </div>
                 </div>  

                 {/* box ten*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingTwentyheight">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentyheight" aria-expanded="false" aria-controls="coursesCollapseTwentyheight">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to approve a move in request</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseTwentyheight" className="collapse " aria-labelledby="coursesHeadingTwentyheight">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">
                              <div className="faqContent">   
                               <span className="media-body">
                                 <span>
                                 <p>To approve a customer’s move in request, you click on 'Move Requests' from your Dashboard. All the move in and move out requests then appear.</p>		                                    
                                  <center><img  className="faqimage" src="../../assets/faqimages/55.png"></img></center>
                                  <p>You can click on ‘view’ to see the move in request details of a specific customer.</p>	   
                                  <center><img  className="faqimage" src="../../assets/faqimages/cs56.png"></img></center>
                                  <p>You can enter the amount paid by the tenant to move in and click on EVALUATE to share the amount between the deposit, admin fee, balance, rent of the current month. 
                                    If the tenant pays more than the move in fee, the rest goes to their balance and will be used to pay for the rent of the next months. If the amount paid is equal or greater than the move in fee, the move in request can be approved and the tenant is automatically activated in the system. 
                                    On this same page, you see the proof of payment uploaded by the client, to know which amount to evaluate.</p>		                             
                                  <center><img  className="faqimage" src="../../assets/faqimages/cs57.png"></img></center>
                                  <p>Once this is done, you can see the tenant in the appartment number you gave to him/her, in the ‘manage block’ sub-menu. The appartment is now unavailable for other tenants to move in.</p>		                                          
                                  <center><img  className="faqimage" src="../../assets/faqimages/58.png"></img></center>
                                  <p>You can also see the list of all the current tenants, and whether they have paid the current month or not yet by clicking on 'customer' in the 'user' sub role.</p>	                                             
                                  <center><img  className="faqimage" src="../../assets/faqimages/59.png"></img></center>
                                  <p>If a tenant is leaving for a while (at the end of the year for example) but is coming back, you can deactivate their account and reactivate it when they come back. This will prevent them from getting the statement when you generate it. To do that, you just click on VIEW to see the user’s profile then you click on the Activate/Deactivate button.
                                    When you reactivate the tenant, you are directly taken to a page to send them a new proforma, since they are moving in again. Since he already stayed at your residence, you have the choice of making them pay for the deposit and the admin fee again or not.</p>	                      
                                  <center><img  className="faqimage" src="../../assets/faqimages/60.png"></img></center>
                                  <p>You (the admin) can see the statement of a user by clicking on ‘statement’ then VIEW MORE. In that page, the ‘outstanding orders’ represents the amount the tenant owes for the current month. The settlement is the amount a user owes to the company from previous months.</p>		                                 
                                  <center><img  className="faqimage" src="../../assets/faqimages/61.png"></img></center>
                                 </span>
                               </span>
                              </div>
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div> 

                  {/*box eleven*/}
                 <div className="card border mb-1">
                    <div className="card-header card-collapse" id="coursesHeadingTwelve2">
                      <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwelve2" aria-expanded="false" aria-controls="coursesCollapseTwelve2">                           
                        <span className="row">
                          <span className="col-12">
                            <span className="media">
                              <span className="card-btn-toggle mr-3 ml-0">
                                <span className="card-btn-toggle-active">-</span>
                                <span className="card-btn-toggle-default">+</span>
                              </span>
                              <span className="media-body">
                                <span className="text-body font-weight-bold mr-5">How to generate your customers' invoices</span>
                              </span>
                            </span>
                          </span>
                        </span> 
                      </a>
                    </div>
                    <div id="coursesCollapseTwelve2" className="collapse " aria-labelledby="coursesHeadingTwelve2">
                      <div className="card-body p-0">
                        <div className="border-bottom py-3 pr-3 pl-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="faqContent">   
                                <span className="media-body">
                                <span> By "customers' invoice", I mean the amount your tenants need to pay you. After generating it (for the month you want), your customers will get something like this on their side:<br></br>
                                  <center><img  className="faqimage" src="../../assets/faqimages/69.png"></img></center><br></br>
                                  <p>In the above example, you have generated the statement for your customers to pay the June rent. Once the payment is made, they will just have to click on the 'Details' button to send you their proof of payment.
                                  Or they can pay you online on that page if they prefer, by clicking on the PAY NOW button. Whatever the payment method they use, after submitting the proof of payment they come back to the previous page to click on ASK FOR APPROVAL.
                                  This will send you an email notifying you that a customer has paid their rent and wants you to look at their proof of payment to approve their payment.</p>
                                  <center><img  className="faqimage" src="../../assets/faqimages/70.png"></img></center><br></br>
                                    Here is how to generate the invoice: you go to the 'Residence Profile' under the 'Company' menu. You then click on the 'Generate Invoice' button.<br></br> 
                                    <center><img  className="faqimage" src="../../assets/faqimages/72.png"></img></center><br></br>
                                    In the next page, you will be asked to choose the month you want to generate the invoices for.
                                    On the calendar, you select a date of the month you want and click on PROCEED to generate the invoices. This will generate the invoices for the customers you have in the residence.<br></br>
                                    <center><img  className="faqimage" src="../../assets/faqimages/invoice.png"></img></center>
                                    When a super admin creates a residence, they are asked to write the payment date. This is the date of the month when you want your customers to pay the business so, we will use it to generate the invoices. 
                                    For example, let's say the super admin chose the "3rd" as the payment date for a residence and you generate the June invoice for the residence. The tenants of this residence will see "June the 3rd" as the paymet date for June.<br></br>
                                  </span>
                                </span>    
                              </div>                              
                            </div>                                
                          </div>
                        </div>                              
                      </div>
                    </div>
                  </div>

                 {/*box twelve*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirty01">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirty01" aria-expanded="false" aria-controls="coursesCollapseThirty01">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to send a feed</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseThirty01" className="collapse " aria-labelledby="coursesHeadingThirty01">
                     <div className="card-body p-0">                     
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                               <span className="media-body">
                                 <span>
                                 <p>You click on 'Manage feed' under the 'Communication' menu and you click on ADD NEW. 
                                      You get a popup asking you to choose the subrole with whom you want to communicate (this means only the people to whom you assigned this sub role will get the message), the subject of the message and the message itself. </p>   
                                    <center><img  className="faqimage" src="../../assets/faqimages/feed.png"></img></center> <br></br>
                                    <p>You then click on SEND and next time they go to their Dashboard, they see the message.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/feed1.png"></img></center> <br></br>
                                    <p>Even after sending a feed, you can still edit its content and subject by clicking on the EDIT button.</p>                           
                                 </span>
                               </span>
                              </div>                          
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>    

                 {/* box twelve*/}
                 <div className="nacomodausers">CUSTOMER</div>  
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingMille">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseMille" aria-expanded="false" aria-controls="coursesCollapseMille">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to make your first payment to move in</span>
                             </span>
                           </span>
                         </span>
                       </span>           
                     </a>
                   </div>
                   <div id="coursesCollapseMille" className="collapse " aria-labelledby="coursesHeadingMille">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">   
                              <div className="faqContent">                           
                               <span className="media-body">
                                 <span>
                                    <p>After the company creates the request for you on their side, you login to your account and click on 'My Bookings' from your Dashboard then view the request you want to ask approval for. 
                                      Let's point that ask for approvals are only for move in requests you made to estates, complexes or student accommodations, not to the other residence types.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/67.png"></img></center>
                                    <p>You now see your proforma (that has the amount to pay) and the company's tenancy agreement (if they uploaded one). You can upload your proof of payment and ask for approval by clicking on the 'Ask for Approval' button.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/68.png"></img></center>
                                 </span>
                               </span>    
                              </div>                      
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div> 

                 {/* box thirteen*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingTwentynine">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwentynine" aria-expanded="false" aria-controls="coursesCollapseTwentynine">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to ask for approval of a monthly payment (when you already stay at the residence)</span>
                             </span>
                           </span>
                         </span>
                       </span>           
                     </a>
                   </div>
                   <div id="coursesCollapseTwentynine" className="collapse " aria-labelledby="coursesHeadingTwentynine">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                               <span className="media-body">
                                 <span>
                                    <p>To ask for approval after making a payment, you go to ‘profile’ then to ‘statement’. After clicking on VIEW MORE, your statement appears.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/69.png"></img></center>
                                    <p>To upload your proof of payment for a specific month, you then click on ‘details’ next to the month you want the payment to be approved.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/70.png"></img></center>
                                    <p>When you are done, you click on the BACK button to ask for approval by clicking on the ASK FOR APPROVAL button.</p>
                                 </span>
                               </span>   
                              </div>                       
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div>  

                 {/* box fourteen*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirty">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirty" aria-expanded="false" aria-controls="coursesCollapseThirty">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to get your proof of residence</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseThirty" className="collapse " aria-labelledby="coursesHeadingThirty">
                     <div className="card-body p-0">                     
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                               <span className="media-body">
                                 <span>
                                    <p>To get your proof of residence, click on ‘Profile’ then on the 'Document' tab. You now click on ‘Proof of Residence’ and that will download your proof of residence with your details and the residence’s stamp (if they have uploaded one).</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/71.png"></img></center>                            
                                 </span>
                               </span>
                              </div>                          
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>            
                </div>
             </div>

             {/*GUEST HOUSE/BnB*/}
             <div className="tab-pane fade text-black-70 " id="pills-four-code-sample" role="tabpanel" aria-labelledby="pills-four-code-sample-tab">
                <div className="tab-pane fade pt-5 show active" id="pills-one-code-sample" role="tabpanel" aria-labelledby="pills-one-code-sample-tab">                 
                  <div className="nacomodausers">SUPER ADMIN</div>
                     {/*box one*/}                                    
                     <div className="card border mb-1 ">
                      <div className="card-header card-collapse" id="coursesHeadingThirtyone">
                       <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtyone" aria-expanded="false" aria-controls="coursesCollapseThirtyone">
                          <span className="row ">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to create a Guest House or a BnB</span>
                                </span>
                              </span>
                            </span>
                         </span>                         
                        </a>
                      </div>
                      <div id="coursesCollapseThirtyone" className="collapse " aria-labelledby="coursesHeadingThirtyone">
                        <div className="card-body p-0">                   
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12"> 
                                <div className="faqContent">                                  
                                  <span className="media-body">
                                    <span>
                                    <p>To create a Guest House or a BnB of your company, you click on the ADD NEW button.</p>
                                        <center><img  className="faqimage" src="../../assets/faqimages/C1.png"></img></center>
                                        <p>You are then taken to the below page, to fill in the Guest House or the BnB's information. For the residence type, make sure to choose 'Guest House or BnB'.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/21.png"></img></center>
                                        <p>After entering the Guest House or the BnB's information, you get the following popup message.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/22.png"></img></center>
                                    </span>
                                  </span>   
                                </div>                          
                              </div>                           
                            </div>
                          </div>                         
                        </div>
                      </div>
                    </div> 

                    {/* box two*/}       
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingThirtyTwo">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtyTwo" aria-expanded="false" aria-controls="coursesCollapseThirtyTwo">                           
                          <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to write (or update) a Guest House or a BnB's banking details</span>
                                </span>
                              </span>
                            </span>
                          </span> 
                        </a>
                      </div>
                      <div id="coursesCollapseThirtyTwo" className="collapse " aria-labelledby="coursesHeadingThirtyTwo">
                        <div className="card-body p-0">
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">
                                <div className="faqContent">   
                                  <span className="media-body">
                                    <span><p>To write or update the banking details of the Guest House or the BnB (this is the account where guests pay the property), you go to ‘Residence Profile’ and then click on ‘bank info.’ In that page, you can also set the admin price that clients pay to move in.
                                      Since customers are going to pay you online, you have to write your PayFast information on this page to receive your payments.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/23.png"></img></center>
                                      <p>The record status in this page shows the person who last updated any information on this page. Actually, in any page where you see a ‘record status’, its role is to notify on who last updated any information on the page.</p>
                                    </span>
                                  </span> 
                                </div>                                 
                              </div>                                
                            </div>
                          </div>                              
                        </div>
                      </div>
                    </div>

                    {/* box three*/}           
                    <div className="card border mb-1">
                      <div className="card-header card-collapse" id="coursesHeadingThirtyThree">
                        <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtyThree" aria-expanded="false" aria-controls="coursesCollapseThirtyThree">                            
                          <span className="row">
                            <span className="col-12">
                              <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                  <span className="card-btn-toggle-active">-</span>
                                  <span className="card-btn-toggle-default">+</span>
                                </span>
                                <span className="media-body">
                                  <span className="text-body font-weight-bold mr-5">How to create an admin (or employee)</span>
                                </span>
                              </span>
                            </span>
                          </span>                                
                        </a>
                      </div>
                      <div id="coursesCollapseThirtyThree" className="collapse " aria-labelledby="coursesHeadingThirtyThree">
                        <div className="card-body p-0">                         
                          <div className="border-bottom py-3 pr-3 pl-6">
                            <div className="row">
                              <div className="col-12">  
                                <div className="faqContent">                    
                                  <span className="media-body">
                                    <span>
                                    <p>Now that the guest house is created, you can create an admin to continue the process (or you can continue yourself if you prefer because anything an admin can do, a super admin can do). To create the admin, you need to create a sub-role under the admin role first. A sub-role is a position held by someone in your company. Tenant and house comm are examples of sub-roles for customers. 
                                        For admins, we can say cleaner, security, manager and so on. Creating different su-roles allows you to group your people, so you can send a message to your tenants only for example. Creating sub-roles also allows you to decide on what each type of
                                        user in your company can do in the system, by restricting their functionalities if you want. For example, you can decide that tenants can receive feeds but they can't create them.
                                        To do that, you create a sub-role with its menu (the menu you want the people under that sub-role to get). </p>
                                        <p>The first step is to click on the ‘manage sub-role’ sub-menu under the ‘company’ menu. On the page that appears (as shown below), you click on the ADD NEW button.</p>			
                                        <center><img  className="faqimage" src="../../assets/faqimages/25.png"></img></center>
                                        <p>After clicking on the ADD NEW button, you fill in the form as it appears:<br></br>
                                           -	For the parent role name, you choose the Administrator/ADM option.<br></br>
                                           -	You enter the sub-role name. For example secretary, cleaner, security, etc. Remember, admins are your employees.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/26.png"></img></center>
                                        <p>You then click on the CREATE button and you receive a popup message asking you to assign the menus to the sub-role you just created.<br></br>
                                           These are the menus you want the people under the sub-role to get when you create them.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/27.png"></img></center>
                                        <p>You close the popup by clicking on the OK button, then you click on ‘menu’ to assign the menus. You will be taken to the following page:</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/28.png"></img></center>
                                        <p>You can edit the sub-role name by clicking on EDIT SUB-NAME. To assign the menus, you click on the CREATE MENU button.<br></br>
                                           You are then taken to the following page:</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/29.png"></img></center>
                                        <p>The menus appear and for you to assign them to the sub-role, you click on them. Let’s say you want to give the Residence Profile sub-menu and some of its privileges. You will click on ‘Company’ and then on Residence Profile. 
                                          You will then see the different privileges under the Residence Profile sub-menu, that you can assign to the sub-role. You then just click on the checkboxes and when you are done, you click on Add Privilege(s). You use the same process for the other menus that you want to give too. 
                                          This means that, if you don’t do this process for a certain menu, the people under this sub-role will not get that menu in their accounts. When you are done, you click on the CREATE button.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/30.png"></img></center>
                                        <p>In case you want to add some menus that you didn’t give in the beginning, you can just click ‘menu’ again, but this time you will see the menus you already gave and the button to click to add more menus will be ADD TO MENU.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/31.png"></img></center>
                                        <p>After clicking on ADD TO MENU, you will see the menus you didn’t give (unless you already gave the sub-role all the menus). You then just click on the menu you want to add, click on the privileges you want to give, click on ADD and voila!</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/32.png"></img></center>
                                        <p>To delete a menu from a sub-role, you click on the menu and then on the delete icon top-left to delete it. You can also delete a submenu by clicking on the 'delete' icon at its left.</p>			
                                        <center><img  className="faqimage" src="../../assets/faqimages/subrole1.png"></img></center>
                                        <p>You can now proceed with the creation of the admin. You click on ‘admin’ under the ‘user’ menu.</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/admin1.png"></img></center>
                                        <p>On the page that appears, you click on ADD NEW and you are taken to the page below where you enter the admin email and choose the sub-role you want to give them. You can now click on the SEND button to invite them into the company via a link (that will go to the email you just provided).</p>		
                                        <center><img  className="faqimage" src="../../assets/faqimages/admin2.png"></img></center>
                                    </span>
                                  </span>    
                                </div>                           
                              </div>                               
                            </div>
                          </div>                             
                        </div>
                      </div>
                    </div>

                    {/* box four */}   
                 <div className="nacomodausers">ADMIN</div>     
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirtyFour">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtyFour" aria-expanded="false" aria-controls="coursesCollapseThirtyFour">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to upload a tenancy agreement and a stamp</span>
                             </span>
                           </span>
                         </span>
                       </span>         
                     </a>
                   </div>
                   <div id="coursesCollapseThirtyFour" className="collapse " aria-labelledby="coursesHeadingThirtyFour">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">   
                              <div className="faqContent">                           
                                <span className="media-body">
                                  <span>
                                    <p>Before you get guests, you need upload the tenancy agreement, otherwise the guests won’t see it before moving in. Here is how you upload the tenancy agreement: you click on ‘Residence Profile’ under the ‘company’ menu. 
                                      You then scroll down to the ‘documents’ section to click on ‘upload new.’ From there, you upload both the tenancy agreement and the stamp to be placed on the proof of residence your customers request directly from the system. 
                                      This is to make it easy for the customers to get their true proof of residence, while avoiding fake ones (the ones without a stamp) at the same time. When you are done, you just click on UPLOAD and voila!</p>		
                                    <center><img  className="faqimage" src="../../assets/faqimages/42.png"></img></center>
                                  </span>
                                </span> 
                              </div>                         
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>

                 {/* box five*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirtyFive">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtyFive" aria-expanded="false" aria-controls="coursesCollapseThirtyFive">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to create the guest sub-role</span>
                             </span>
                           </span>
                         </span>
                       </span>                
                     </a>
                   </div>
                   <div id="coursesCollapseThirtyFive" className="collapse " aria-labelledby="coursesHeadingThirtyFive">
                     <div className="card-body p-0">                
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">
                              <div className="faqContent">                              
                               <span className="media-body">
                                 <span>
                                    <p>A sub-role is a position held by someone in your company. Tenant and guest are examples of sub-roles for customers. For admins, we can say
                                      cleaner, security, manager and so on. Creating different sub-roles allows you to group your people, so you can send a message to your guests only for example. Creating sub-roles also allows you to decide on what each type of
                                      user in your company can do in the system, by restricting their functionalities if you want. For example, you can decide that guests can receive feeds but they can't create them.
                                      To get guests for your Guest House, you HAVE TO create ONE customer sub-role that will be the one your guests will automatically get when they book your place at the Booking page.
                                      But you can create as many admin sub-roles as you want.</p> 
                                   <p>To create the ONE customer sub-role, you click on ‘manage sub-role’ under the ‘company’ menu. Provided the super admin gave you the right to get this menu.
                                      When you click, you will see the sub-roles the super admin already created for the residence.</p>		
                                      <center><img  className="faqimage" src="../../assets/faqimages/43.png"></img></center>
                                   <p>To create the sub-role, you click on ADD NEW and you are taken to the following page:</p>	
                                   <center><img  className="faqimage" src="../../assets/faqimages/44.png"></img></center>
                                   <p>You choose Customer/CUS for the parent sub-role and you write the sub-role name in the sub-role name field. You then click on CREATE.</p>
                                   <center><img  className="faqimage" src="../../assets/faqimages/440.png"></img></center>
                                   <p>The next step is to assign menus to the sub-role, to determine the menus these customers will get.</p>	
                                   <center><img  className="faqimage" src="../../assets/faqimages/45.png"></img></center>
                                   <p>You can edit the sub-role name by clicking on EDIT SUB-NAME. To assign the menus, you click on the CREATE MENU button. You are then taken to the following page:</p>	
                                   <center><img  className="faqimage" src="../../assets/faqimages/46.png"></img></center>
                                   <p>The menus appear and for you to assign them to the sub-role, you click on them. Let’s say you want to give the Profile menu and some of its privileges. You will click on ‘Profile.’ You will then see the different privileges under the Profile menu that you can assign to the sub-role. 
                                      You then just click on the checkboxes and when you are done, you click on Add Privilege(s). You use the same process for the other menus that you want to give too. This means that, if you don’t do this process for a certain menu, the people under this sub-role will not get that menu in their accounts. When you are done, you click on the CREATE button.</p>	
                                   <center><img  className="faqimage" src="../../assets/faqimages/47.png"></img></center>
                                   <p>To add or delete a menu, you just proceed as explained earlier under 'How to create an admin' for the super admin.</p>
                                 </span>
                               </span>
                              </div>                         
                           </div>                        
                         </div>
                       </div>        
                     </div>
                   </div>
                 </div>

                 {/*box six*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirtySeven">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtySeven" aria-expanded="false" aria-controls="coursesCollapseThirtySeven">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to create an additional service</span>
                             </span>
                           </span>
                         </span>
                       </span>                
                     </a>
                   </div>
                   <div id="coursesCollapseThirtySeven" className="collapse " aria-labelledby="coursesHeadingThirtySeven">
                     <div className="card-body p-0">                
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12"> 
                              <div className="faqContent">                             
                               <span className="media-body">
                                 <span>
                                 <p>An additional service is any service your customers can choose to benefit from or not. For example, if your residence offers breakfast for an additional R100/guest/day on the booking fee, you can create the additional service "Breakfast" and write "100" for the price.
                                    An additional service is optional. Anything that's mandatory should just be added on the space category price, which is the price/night to book the space.</p>
                                    <p>To create an additional service, go to 'manage category' and then click on 'Service'. You then click on ADD NEW and you are taken to the page where you can write the additionnal service's name and price.
                                    The description is optional since it's not going to appear for the clients.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/service.png"></img></center><br></br>
                                    You then click on CREATE and voila!
                                 </span>
                               </span>  
                              </div>                       
                           </div>                        
                         </div>
                       </div>        
                     </div>
                   </div>
                 </div> 

                 {/* box seven*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirtyEight">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtyEight" aria-expanded="false" aria-controls="coursesCollapseThirtyEight">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to create a space category</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseThirtyEight" className="collapse " aria-labelledby="coursesHeadingThirtyEight">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">
                              <div className="faqContent">   
                               <span className="media-body">
                                 <span>
                                    <p>Here is how to create the categories of the spaces your guests will be booking. You click on ‘manage category’ under the ‘company’ menu.</p>		
                                    <center><img  className="faqimage" src="../../assets/faqimages/guestcat.png"></img></center><br></br>
                                    <p> To add a new space category, you click on ADD NEW. You are then asked to:<br></br>
                                       -	Enter the category name (for example ‘VIP’), <br></br>
                                       -	Choose your pricing type: you can choose to price by 'number of people' or by 'age range'.<br></br>
                                    <b>If you choose to price by 'no of people', we ask you for the following information:</b><br></br>
                                    <center><img  className="faqimage" src="../../assets/faqimages/nop.png"></img></center><br></br>
                                    1. no of people: this is the number of people who can stay together in a space of your guest house. For example, if you will assign this category to a pool that you rent and you don't want more than
                                    20 people to use the pool at the same time, you write 20 under 'no of people'. If you will assign this category to a room people can book to have meetings and you don't want them to book it for more than
                                    100 people, you write 100 in this field. If it's for the house itself and it can accommodate up to 4 people, you write 4 here. I hope it's clear. <br></br>
                                    2. Daily Price: we can also call it 'price per night'. This is the price those 4 people (using the latest example) pay per day to rent the space. Whether it's only one person booking or they are 4, this same price is going to appear.
                                    Choosing to price by 'no of people' means you are saying to your customers: this is the daily price (or price per night) to rent my space whether you are alone or you reach the maximum number of people I allow for this space.<br></br>
                                    3. Max Extra people: you can say that the house can accommodate up to 4 people based on the size(s) of the bedroom(s) but you are accepting up to 2 extra guests who will spend the night in the living room for example.
                                    You write 2 under this field then, which brings the maximum number of possible guests to 6. You are then required to enter how much you charge per extra guest, based on their age. If you want all the extra guests to pay the same amount, 
                                    then you just click on Add Range and add one age range that covers everybody, 0-100 for example, and you write the price. But if you want different prices for different ages, you enter the number of age ranges you want and you write the price for each. <br></br>
                                    <b>If you choose to price by 'age range', we ask you for the following information:</b><br></br>
                                    <center><img  className="faqimage" src="../../assets/faqimages/age.png"></img></center><br></br>
                                    1. After clicking on the ADD RANGE button, you are asked to enter the number of age ranges you want and then the daily price associated with each, to rent the space.<br></br>
                                    2. The maximum number of people who can rent the space at the same time (or in one booking).<br></br>  
                                    </p>
                                    <p>-	The service name: here you just add the services you want to propose to the people who will book the space attached to this category. You can change your mind and delete a service by clicking on the red cross next to it. </p>
                                    <p>Now that you have created the sub-role, the space categories and the spaces themselves, you (the admin) are ready to receive guests.</p>
                                 </span>
                                </span>
                              </div>                           
                           </div>                         
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div>
                
                {/* box eight*/}
                <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirtySix">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtySix" aria-expanded="false" aria-controls="coursesCollapseThirtySix">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to create a space</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseThirtySix" className="collapse " aria-labelledby="coursesHeadingThirtySix">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">    
                              <div className="faqContent">                          
                               <span className="media-body">
                                 <span>
                                    <p>A space is everything your clients can rent by itself in the Guest House or the BnB. If you allow clients to rent the pool, it's a space. If they can rent the garden or a certain venue for a party, it's a space. The house itself is obviously a space, etc.
                                      To create the guest house or the bnb's spaces, go to ‘manage block’.</p>		
                                    <center><img  className="faqimage" src="../../assets/faqimages/sp1.png"></img></center>
                                    <p>To create a space, you click on ADD NEW. You are then asked to enter the space name, choose its category (which will assign all the pricing information of the category to this space), write its description (a few words to advertise the space). 
                                      You are also asked to provide the points of interests: these are the things that can further convice your prospects to stay at your place. For example, you can write "5km from Mall of Africa" or "Free wifi" or "10km from UJ", etc.
                                      When you are done providing all this information, you click on CREATE and voilà ! 
                                    </p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/sp2.png"></img></center>              
                                    <p>Now you can go ahead and click on the "Images" button, to upload the images that will attract your customers: the front image is the one they will see on the Booking page and the additional ones are the ones they will see when they click to see more.<br></br>
                                    <center><img  className="faqimage" src="../../assets/faqimages/73.png"></img></center><br></br>
                                    After selecting a picture from your device, you click on UPLOAD to add it. To change the front image, just upload a new one. To delete an additional image, click on 'Edit' and select the images you want to delete then click on 'Remove selected'.</p>
                                    <p>To see the guests who booked this space, click on the 'Bookings' button.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/sp3.png"></img></center>
                                    <p>On this page, you can see the usernames of your guests and their move in and move out dates so you can plan how to make their stay enjoyable.</p>
                                    <p>After creating a space, you have to activate it for it to appear on the Booking page. This means you can also deactivate it if you don't want it to appear on the Booking page for a while.</p>
                                 </span>
                               </span>    
                              </div>      
                           </div>                        
                         </div>
                       </div>
                     </div>
                   </div>
                 </div> 

                 {/* box nine*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirtyNine">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirtyNine" aria-expanded="false" aria-controls="coursesCollapseThirtyNine">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to approve a move in request and view its receipt</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseThirtyNine" className="collapse " aria-labelledby="coursesHeadingThirtyNine">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">
                              <div className="faqContent">   
                               <span className="media-body">
                                 <span>
                                    <p>To approve a customer’s move in request, from your dashboard you click on 'Move Requests' and all the requests appear.</p>		                                          
                                    <center><img  className="faqimage" src="../../assets/faqimages/55.png"></img></center>
                                    <p>You then click on the 'View' button next to the request you want to approve so you can see all the request details.
                                      It's only when you approve the request that the guest will have the option to pay on their side.</p>	                                         
                                    <center><img  className="faqimage" src="../../assets/faqimages/56.png"></img></center>
                                    <p>Once the client pays, you can see them in the list of clients who have booked that space by clicking on the 'booking' button of the space in the 'manage block' menu as explained in "how to create a space".
                                      The payment also makes the dates booked by the client unavailable in the calendar of the space so, other clients can not book them.</p>	                                 
                                    <p>You (the admin) can see the receipt of a guest on the 'customer' page (under the User menu).
                                    <center><img  className="faqimage" src="../../assets/faqimages/57.png"></img></center><br></br>
                                      Click on the 'view' button next to the guest you want, and on the next page click on 'statement'.
                                      You will see a list of booking details for all the times they stayed at your place. Click to view the one you want and voila! </p>		                                          
                                    <center><img  className="faqimage" src="../../assets/faqimages/62.png"></img></center>
                                 </span>
                               </span>
                             </div>
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div> 

                  {/* box ten*/}
                  <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingThirty02">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseThirty02" aria-expanded="false" aria-controls="coursesCollapseThirty02">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to send a feed</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseThirty02" className="collapse " aria-labelledby="coursesHeadingThirty02">
                     <div className="card-body p-0">                     
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                               <span className="media-body">
                                 <span>
                                    <p>You click on 'Manage feed' under the 'Communication' menu and you click on ADD NEW. 
                                      You get a popup asking you to choose the subrole with whom you want to communicate (this means only the people to whom you assigned this sub role will get the message), the subject of the message and the message itself. </p>   
                                    <center><img  className="faqimage" src="../../assets/faqimages/feed.png"></img></center> <br></br>
                                    <p>You then click on SEND and next time they go to their Dashboard, they see the message.</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/feed1.png"></img></center> <br></br>
                                    <p>Even after sending a feed, you can still edit its content and subject by clicking on the EDIT button.</p>                           
                                 </span>
                               </span>
                              </div>                          
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>      

                 {/* box eleven*/}
                 <div className="nacomodausers">CUSTOMER</div>  
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingFourty">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseFourty" aria-expanded="false" aria-controls="coursesCollapseFourty">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to book a space of a guest house or a bnb</span>
                             </span>
                           </span>
                         </span>
                       </span>           
                     </a>
                   </div>
                   <div id="coursesCollapseFourty" className="collapse " aria-labelledby="coursesHeadingFourty">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12"> 
                              <div className="faqContent">                             
                               <span className="media-body">
                                 <span>
                                    <p>Let me start by pointing that, for the next explainations, I will use different scenarios to be complete in my explaination so, don't be confused to see that the figures change from one picture to another.</p>
                                    <p>To book a space, go to the Booking page on the website and click on the space you want to see more of.<br></br>
                                    <center><img  className="faqimage" src="../../assets/faqimages/b1.png"></img></center> <br></br>
                                      On the next page, select your move in and move out dates. The dates that are unavailable are the ones that are already booked by other customers.
                                      After the dates, enter the number of guests. Here it depends on how the space is priced:<br></br>
                                      - If the space is priced based on the number of people: you are required to answer if you are more than the number of people the space can accommodate. For example, a space that prices by the number of people can say that
                                      for up to 4 people, the price per night is R800. This means that, whether you are 1,2,3 or 4 guests, you only pay R800 for the night. In other words, if you answer 'no' to the question of knowing whether you are more than 4 or not, you will be charged R800.
                                      If you answer 'yes', you are then required to enter the number of the extra guests who are coming, based on their age. The guest house or the bnb has a maximum number of guests who can stay at the place at the same time so if you exceed that number, the system will tell you.
                                      Each age range has its price written so you can see how much it will take for each extra guest.<br></br>
                                      <center><img  className="faqimage" src="../../assets/faqimages/b2.png"></img></center> <br></br>
                                      The space can also be priced by the number of people but extra guests are not allowed. In this case, you won't be asked if you are more than the required number and therefore, you won't have any option to write the umber of the extra guests.
                                      <center><img  className="faqimage" src="../../assets/faqimages/b3.png"></img></center> <br></br>
                                      - If the space is priced based on age range: you are directly asked to write the number of guests falling under each age range. This means there is no fixed price for up to a certain number of guests to start with as above, since you are directly charged individually.
                                      <center><img  className="faqimage" src="../../assets/faqimages/b4.png"></img></center> <br></br></p>
                                    <p>Now you can select the additional services you want to benefit from by just entering the number of guest who want each service.
                                      By not entering any number under a service, you are saying that you don't want that service for any of the guests.
                                      <center><img  className="faqimage" src="../../assets/faqimages/b5.png"></img></center> <br></br>
                                    </p>
                                    <p>N.B: the guests and additional services numbers given are multiplied by the number of days to give you the amount required to book the place. 
                                      In other words, we consider that any guest you book for is going to stay at the place from the move in to the move out date selected. And that any additional service selected is going to be provided from the move in to the move out date. <br></br>
                                      For even more information about the space, you can look at their points of interests. When you are done booking, you can click on 'Booking Preview' to see a summary of your booking and if you are happy with it, you provide your username for the guest house or the bnb to know who is booking the place and you click 'confirm'.<br></br>
                                      <center><img  className="faqimage" src="../../assets/faqimages/b6.png"></img></center> <br></br>
                                    </p>
                                    <p>Your booking request has now been submitted to the guest house or the bnb and you can track its status in your account: login to your account and on the Dashboard, click on 'Bookings' to see all your bookings.
                                      You can 'view' the details of the specific booking you just did and cancel if you change your mind. <br></br>
                                      <center><img  className="faqimage" src="../../assets/faqimages/b8.png"></img></center> <br></br>
                                      As soon as the guest house or the bnb approves your request, the 'pay' button appears so you can pay. (You might have to leave the page and come back for the button to appear).
                                      As soon as you pay, your booking is finished and you can occupy the place at the move in date.</p>
                                      <center><img  className="faqimage" src="../../assets/faqimages/b7.png"></img></center> <br></br>
                                 </span>
                               </span> 
                              </div>                         
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div>  

                 {/* box twelve*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingFourtyOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseFourtyOne" aria-expanded="false" aria-controls="coursesCollapseFourtyOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to see your receipts</span>
                             </span>
                           </span>
                         </span>
                       </span> 
                     </a>
                   </div>
                   <div id="coursesCollapseFourtyOne" className="collapse " aria-labelledby="coursesHeadingFourtyOne">
                     <div className="card-body p-0">                     
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                               <span className="media-body">
                                 <span>
                                    <p>After you login, you click on Profile and then Statement and voila!</p>
                                    <center><img  className="faqimage" src="../../assets/faqimages/g71.png"></img></center>                            
                                 </span>
                               </span>
                              </div>                          
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>            
                </div>
               </div> 
     
             {/*HOTEL*/}
              <div className="tab-pane fade text-black-70" id="pills-five-code-sample" role="tabpanel" aria-labelledby="pills-five-code-sample-tab">
                <div className="tab-pane fade pt-5 show active" id="pills-one-code-sample" role="tabpanel" aria-labelledby="pills-one-code-sample-tab">                 
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12"> 
                              <div className="faqContent">                             
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span>
                              </div>                          
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>    
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingTwo">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseTwo" aria-expanded="false" aria-controls="coursesCollapseTwo">    
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up5</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseTwo" className="collapse " aria-labelledby="coursesHeadingTwo">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span>  
                              </div>                         
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div>
                 {/* box three*/}    
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span>   
                              </div>                       
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>    
                 {/* box four */}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                             
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span>   
                              </div>                     
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>
                 {/* box five*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">
                              <div className="faqContent">                             
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span> 
                              </div>                         
                           </div>                         
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>     
                 {/* box six*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12"> 
                              <div className="faqContent">                             
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span>   
                              </div>                       
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>
                 {/* box seven*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                            
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span>   
                              </div>                       
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div>
                 {/* box eight*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12"> 
                              <div className="faqContent">                             
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span> 
                              </div>                          
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>    
                 {/* box nine*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0">
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">    
                              <div className="faqContent">                          
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span> 
                              </div>                         
                           </div>                        
                         </div>
                       </div>                      
                     </div>
                   </div>
                 </div>                  
                  {/* box ten*/}
                 <div className="card border mb-1">
                   <div className="card-header card-collapse" id="coursesHeadingOne">
                     <a className="btn btn-link btn-sm btn-block card-btn p-3"  href="javascript:;" role="button" data-toggle="collapse" data-target="#coursesCollapseOne" aria-expanded="false" aria-controls="coursesCollapseOne">
                       <span className="row">
                         <span className="col-12">
                           <span className="media">
                             <span className="card-btn-toggle mr-3 ml-0">
                               <span className="card-btn-toggle-active">-</span>
                               <span className="card-btn-toggle-default">+</span>
                             </span>
                             <span className="media-body">
                               <span className="text-body font-weight-bold mr-5">How to sign up</span>
                             </span>
                           </span>
                         </span>
                       </span>
                     </a>
                   </div>
                   <div id="coursesCollapseOne" className="collapse " aria-labelledby="coursesHeadingOne">
                     <div className="card-body p-0"> 
                       <div className="border-bottom py-3 pr-3 pl-6">
                         <div className="row">
                           <div className="col-12">  
                              <div className="faqContent">                             
                                <span className="media-body">
                                  <span>Python 2 versus Python 3</span>
                                </span> 
                              </div>                         
                           </div>                        
                         </div>
                       </div>                     
                     </div>
                   </div>
                 </div>  
               </div>
               </div>
           </div>    
          </div>
        </div>
      </div> 
    </div>
  </div>
  </center>
  </main>
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

export default Faq;

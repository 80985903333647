import React, { Fragment, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Footer from "../ui-setup/Footer";

const NewsConstruction= () => {
  useEffect(() => {}, []);
  return (
    <div>
      {/* ========== HEADER ========== */}
      <header
        id="header"
        className="header header-box-shadow-on-scroll header-abs-top header-bg-transparent header-show-hide"
        data-hs-header-options='{
      "fixMoment": 1000,
      "fixEffect": "slide"
    }'
      >
        {/* Search */}
        <div id="searchPushTop" className="search-push-top">
          <div className="container position-relative">
            <div className="search-push-top-content pt-3">
              {/* Close Button */}
              <div className="search-push-top-close-btn">
                <div className="hs-unfold">
                  <a
                    className="js-hs-unfold-invoker btn btn-icon btn-xs btn-soft-secondary mt-2 mr-2"
                    href="#"
                    data-hs-unfold-options='{
            "target": "#searchPushTop",
            "type": "jquery-slide",
            "contentSelector": ".search-push-top"
           }'
                  >
                    <svg
                      width={10}
                      height={10}
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* End Close Button */}
              {/* Input */}
              <form className="input-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Front"
                  aria-label="Search Front"
                />
                <div className="input-group-append">
                  <button type="button" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </form>
              {/* End Input */}
            </div>
          </div>
        </div>
        {/* End Search */}
        <div className="header-section">
          {/* Topbar */}
          <div className="container header-hide-content pt-2">
            <div className="d-flex align-items-center">
              {/* Language */}
              {/* <div className="hs-unfold">
                <a
                  className="js-hs-unfold-invoker dropdown-nav-link dropdown-toggle d-flex align-items-center"
                  href="#"
                  data-hs-unfold-options='{
          "target": "#languageDropdown",
          "type": "css-animation",
          "event": "hover",
          "hideOnScroll": "true"
         }'
                >
                  <img
                    className="dropdown-item-icon mr-2"
                    src="../../assets/vendor/flag-icon-css/flags/4x3/us.svg"
                    alt="SVG"
                  />
                  <span className="d-inline-block d-sm-none">US</span>
                  <span className="d-none d-sm-inline-block">English</span>
                </a>
              </div> */}
              {/* End Language */}
              <div className="ml-auto">
                {/* Links */}
                {/* <div className="nav nav-sm nav-y-0 d-none d-sm-flex ml-sm-auto">
                  <a className="nav-link" href="../pages/faq.html">
                    Help
                  </a>
                  <a className="nav-link" href="../pages/contacts-agency.html">
                    Contacts
                  </a>
                </div> */}

<div className="nav nav-sm nav-y-0 d-none d-sm-flex ml-sm-auto">
              <a className="nav-link" href="../pages/faq.html">Sign up</a>
              {/* <a className="nav-link" href="../pages/contacts-agency.html">Rewards</a> */}
            </div>
                {/* End Links */}
              </div>
            </div>
          </div>
          {/* End Topbar */}
          <div id="logoAndNav" className="container">
            {/* Nav */}
            <nav className="js-mega-menu navbar navbar-expand-lg">
              {/* Logo */}
              <NavLink to = "/home" className="navbar-brand"  aria-label="Front">
                <img
                  src="../../assets/svg/logos/nacomoda_edit1.png"
                  alt="Logo"
                />
              </NavLink>
              {/* End Logo */}
              {/* Responsive Toggle Button */}
              <button
                type="button"
                className="navbar-toggler btn btn-icon btn-sm rounded-circle"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
              >
                <span className="navbar-toggler-default">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
                    />
                  </svg>
                </span>
                <span className="navbar-toggler-toggled">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                    />
                  </svg>
                </span>
              </button>
              {/* End Responsive Toggle Button */}

              {/* Navigation */}
              <div id="navBar" className="collapse navbar-collapse">
                <div className="navbar-body header-abs-top-inner">
                  <ul className="navbar-nav">

                  <NavLink to = "/home" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Home</NavLink>
                  <NavLink to = "/about" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false" >About us</NavLink>
                  <NavLink to = "/versions" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false" >Versions</NavLink>
                  <NavLink to = "/bookings" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Bookings</NavLink>
                  <NavLink to = "/contact" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">Contact</NavLink>
                  <NavLink to = "/news" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">News</NavLink>
                  <NavLink to = "/faq" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false">FAQ</NavLink>
                
                    {/* Button */}
                    <li className="navbar-nav-last-item">
                      <a
                        className="btn btn-sm btn-primary transition-3d-hover"
                        href="https://backoffice.nacomoda.com/"
                        target="_blank"
                      >
                        <i className="fas fa-home"></i>&nbsp;Back Office
                      </a>
                    </li>
                    {/* End Button */}
                  </ul>
                </div>
              </div>



              
              {/* End Navigation */}
            </nav>
            {/* End Nav */}
          </div>
        </div>
      </header>
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
     <main id="content" role="main">
       
    <div className="position-relative">


    <div className="js-slide-d-flex-gradient-x-overlay-sm-navy-bg-img-hero-min-h-620rem" >
       
          <div className="container d-flex align-items-center min-h-620rem">
            <div className="w-lg-40 mr-5">
            
              <div className="media align-items-center mb-3"
                   data-hs-slick-carousel-animation="fadeInUp">
              
                <div className="media-body">
                Breaking news
                </div>
              </div>
          

              <div className="mb-5">
                <h3 className="h1 font-weight-bold text-white"
                    data-hs-slick-carousel-animation="fadeInUp"
                    data-hs-slick-carousel-animation-delay="150">News Title</h3>
                    Company name
              </div>

              <NavLink to = "/newsArticle"   >
              <a className="btn btn-primary btn-wide transition-3d-hover" 
                 data-hs-slick-carousel-animation="fadeInUp"
                 data-hs-slick-carousel-animation-delay="300">Read Article <i className="fas fa-angle-right fa-sm ml-1"></i></a>
           </NavLink>
              
            </div>
          </div>
         
        </div>

        </div>

        
     
     
      
    
  
     
     
    <div className="container space-top-0 space-top-lg-5">
     
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <h2 className="h1">Category name 1 news </h2>
        <p>Category description</p>
      </div>
      

      <div className="row mx-n2 mb-5 mb-md-9">
        <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
     
        <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img9.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
      
       </div>

       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
        
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img10.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
       
       </div>

       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-sm-0">
       
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img11.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
        
       </div>

       <div className="col-sm-6 col-lg-3 px-2">
      
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img12.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
       
        
        </div>
      </div>

      <div className="position-relative z-index-2 text-center">
        <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
          Want to read more on category name news?<NavLink to = "/categoryNews" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false"></NavLink>
        </div>
      </div>
    
     
    </div>
  
    <div className="container space-top-2 space-top-lg-1">
     
   
    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
       <h2 className="h1">Category name 2 news</h2>
       <p>Category description</p>
     </div>
     

     <div className="row mx-n2 mb-5 mb-md-9">
       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
    
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img9.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
      
       </div>

       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
        
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img10.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
       
       </div>

       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-sm-0">
       
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img11.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
        
       </div>

       <div className="col-sm-6 col-lg-3 px-2">
      
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img12.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
       
       
       </div>
     </div>

    
     <div className="position-relative z-index-2 text-center">
        <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
        Want to read more on category name news?<NavLink to = "/categoryNews" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false"></NavLink>
        </div>
      </div>
   
    
   </div>
 
   <div className="container space-top-2 space-top-lg-1">
     
     <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
       <h2 className="h1">Category name 3 news</h2>
       <p>Category description</p>
     </div>
     

     <div className="row mx-n2 mb-5 mb-md-9">
       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
    
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img9.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
      
       </div>

       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
        
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img10.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
       
       </div>

       <div className="col-sm-6 col-lg-3 px-2 mb-3 mb-sm-0">
       
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img11.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
        
       </div>

       <div className="col-sm-6 col-lg-3 px-2">
      
       <NavLink to = "/newsArticle"   className="card h-100 transition-3d-hover" >
           <img className="card-img-top" src="../../assets/img/500x280/img12.jpg" alt="Image Description"/>
           <div className="card-body">
           <span className="d-block small font-weight-bold text-cap mb-2">Company name</span>
              <h5 className="mb-0">News Title</h5>
           </div>
           </NavLink>
       
       </div>
       
     </div>

    
     <div className="position-relative z-index-2 text-center">
        <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
        Want to read more on category name news?<NavLink to = "/categoryNews" className="hs-mega-menu-invoker nav-link" aria-haspopup="true" aria-expanded="false"></NavLink>
        </div>
      </div>
    
   </div><br></br><br></br>
 

    </main>
 
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

export default NewsConstruction;

import React, { Fragment, useEffect, useState } from "react";
import Header from "../ui-setup/Header";
import Footer from "../ui-setup/Footer";
// import { Carousel } from "3d-react-carousal";
import appStatic from "../../config/appStaticData";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import { BOM } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import { ImgCarouselFac } from "../layouts/Facility";

const BookingDescriptionStudent = (props) => {
  const { location } = props;
  const { state } = location;
  const { defaultImgCarourel } = appStatic;
  const [categoryDetail, setCategoryDetail] = useState({});
  const [currency, setCurrency] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [pointInterestData, setPointInterestData] = useState([]);
  const [slides, setSlides] = useState(defaultImgCarourel);

  useEffect(() => {
    getDetailById(state.ggParam);
    window.scrollTo(0, 0);
  }, []);

  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");
    setCurrency(!params.CUR_CODE ? currency : params.CUR_CODE);

    let body = {
      catId: params.CAT_ID,
      // serviceData: selectedSvc,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetAllCategory, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let detail = res.categoryDetails;
        setCategoryDetail(detail);
        setPointInterestData(detail.POINT_INTEREST);
        setServiceData(detail.SERVICE_DATA);
        //get space images
        getFileImage(detail);
      }
    });
  };

  function getFileImage(objParam) {
    // setSlides([])
    const body = {
      coId: objParam.CO_ID,
      catId: objParam.CAT_ID,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCategoryImage, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.DOCUMENTS;
        let tempArr = [];

        if (objList.length > 0) {
          objList.map((item, index) => {
            tempArr.push({
              image: item.DOCUMENT,
            });
          });

          setSlides([...tempArr]);
        }
      }
    });
  }

  return (
    <div>
      {/* ========== HEADER ========== */}

      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}

      <main id="content" role="main">
        {/* Hero Section */}
        <div className="container space-top-3 space-top-sm-3">
          <div className="row">
            <div className="col-lg-7">
              <ImgCarouselFac imageData={slides} automatic={true} />
            </div>
            <div className="col-lg-5">
              {/* Title */}
              <div className="mb-1">
                <h1 className="h2">{categoryDetail.CAT_NAME}</h1>
                <p>
                  <i className="fa fa-building text-danger"></i>&nbsp;
                  {categoryDetail.CO_NAME}&nbsp;-&nbsp;{categoryDetail.LO_NAME}
                </p>
              </div>
              {/* End Title */}

              <div className="mb-2">
                <span>
                  {!categoryDetail.CAT_DESCRIPTION ? (
                    <i>{BOM.Uwfirst("No description")}</i>
                  ) : (
                    <i>{categoryDetail.CAT_DESCRIPTION}</i>
                  )}
                </span>
              </div>
              <div id="shopCartAccordion" className="accordion mb-5">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center ml-auto">
                    <div className="small text-muted">
                      <address>
                        <i className="fa fa-map-pin d-block d-sm-inline-block mb-1 mb-sm-0 mr-1 text-primary"></i>
                        {categoryDetail.LO_ADDRESS}, {categoryDetail.CITY_NAME},{" "}
                        {categoryDetail.STATE_NAME}
                      </address>
                    </div>
                  </div>
                </div>
                {/* End Card */}

                {/* Card */}
                <div className="card border shadow-none">
                  <div
                    className="card-body card-collapse"
                    id="shopCardHeadingEight1"
                  >
                    <a
                      className="btn btn-link btn-block card-btn collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCardEight1"
                      aria-expanded="false"
                      aria-controls="shopCardEight1"
                    >
                      <span className="row align-items-center">
                        <span className="col-9">
                          <span className="media align-items-center">
                            <span className="w-100 max-w-6rem mr-3">
                              <i className="fa fa-list-ol text-primary fa-2x" />
                            </span>
                            <span className="media-body">
                              <span className="d-block font-size-1 font-weight-bold">
                                {appLabel.service}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="col-3 text-right">
                          <span className="card-btn-toggle">
                            <span className="card-btn-toggle-default">+</span>
                            <span className="card-btn-toggle-active">−</span>
                          </span>
                        </span>
                      </span>
                    </a>
                  </div>
                  <div
                    id="shopCardEight1"
                    className="collapse"
                    aria-labelledby="shopCardHeadingEight1"
                    data-parent="#shopCartAccordion"
                  >
                    <div className="card-body">
                      <div className="row mx-n2">
                        {serviceData.length === 0 ? (
                          <p>No Service available yet</p>
                        ) : (
                          serviceData.map((item, index) => (
                            <div
                              className="col-sm-6  px-2 mb-3 mb-lg-3"
                              key={index}
                            >
                              {/* Icon Blocks */}
                              <a
                                className="card h-100 transition-3d-hover"
                                style={{
                                  borderRadius: "15px",
                                  boxShadow: "0 6px 6px -3px gray",
                                }}
                              >
                                <div className="card-body">
                                  <h4>
                                    <small>{item.SVC_NAME}</small>
                                  </h4>
                                  <div>
                                    <span className="text-dark">
                                      <span
                                        className="text-dark"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <NumberFormat
                                          value={item.SVC_PRICE}
                                          className="foo font-size-1"
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={currency + " "}
                                        />
                                      </span>
                                      <span className="font-size-1">
                                        {appLabel.perMonth}
                                      </span>
                                    </span>
                                    {/* Form Group */}

                                    {/* End Form Group */}
                                  </div>
                                </div>
                              </a>
                              {/* End Icon Blocks */}
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Card */}
                {/* Card */}
                <div className="card border shadow-none">
                  <div
                    className="card-body card-collapse"
                    id="shopCardHeadingOne"
                  >
                    <a
                      className="btn btn-link btn-block card-btn collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCardOne"
                      aria-expanded="false"
                      aria-controls="shopCardOne"
                    >
                      <span className="row align-items-center">
                        <span className="col-9">
                          <span className="media align-items-center">
                            <span className="w-100 max-w-6rem mr-3">
                              <i className="fa fa-database text-primary fa-2x" />
                            </span>
                            <span className="media-body">
                              <span className="d-block font-size-1 font-weight-bold">
                                {appLabel.points}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="col-3 text-right">
                          <span className="card-btn-toggle">
                            <span className="card-btn-toggle-default">+</span>
                            <span className="card-btn-toggle-active">−</span>
                          </span>
                        </span>
                      </span>
                    </a>
                  </div>
                  <div
                    id="shopCardOne"
                    className="collapse"
                    aria-labelledby="shopCardHeadingOne"
                    data-parent="#shopCartAccordion"
                  >
                    <div className="card-body">
                      <div className="row">
                        {pointInterestData.length === 0 ? (
                          <p>No point of interest </p>
                        ) : (
                          pointInterestData.map((poi, index) => (
                            <div className="col-sm-12" key={index}>
                              <ul className="list-sm-article text-body mb-0">
                                <li>{poi.pInterest}</li>
                              </ul>
                            </div>
                          ))
                        )}
                      </div>
                    </div>

                    <div className="card-footer border-0 pt-0"></div>
                  </div>
                </div>
                {/* End Card */}
                {/* Card */}
                <div className="card border shadow-none">
                  <div
                    className="card-body card-collapse"
                    id="shopCardHeadingEight"
                  >
                    <a
                      className="btn btn-link btn-block card-btn collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCardEight"
                      aria-expanded="false"
                      aria-controls="shopCardEight"
                    >
                      <span className="row align-items-center">
                        <span className="col-9">
                          <span className="media align-items-center">
                            <span className="w-100 max-w-6rem mr-3">
                              <i className="fa fa-address-book text-primary fa-2x" />
                            </span>
                            <span className="media-body">
                              <span className="d-block font-size-1 font-weight-bold">
                                {appLabel.contact}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="col-3 text-right">
                          <span className="card-btn-toggle">
                            <span className="card-btn-toggle-default">+</span>
                            <span className="card-btn-toggle-active">−</span>
                          </span>
                        </span>
                      </span>
                    </a>
                  </div>
                  <div
                    id="shopCardEight"
                    className="collapse"
                    aria-labelledby="shopCardHeadingEight"
                    data-parent="#shopCartAccordion"
                  >
                    <div className="card-body">
                      <div className="row mx-n2 mt-3">
                        <div className="col-sm-6  px-2 mb-3 mb-lg-0">
                          {/* Icon Blocks */}
                          <a
                            className="card h-100 transition-3d-hover"
                            style={{
                              borderRadius: "15px",
                              boxShadow: "0 6px 6px -3px gray",
                            }}
                          >
                            <div className="card-body">
                              {/* <h4>
                              <small>{appLabel.emailAddress}</small>
                            </h4> */}
                              <div>
                                <center>
                                  <small>
                                    <i className="fa fa-envelope text-warning" />{" "}
                                    <br />
                                    {categoryDetail.LO_EMAIL}
                                  </small>
                                </center>
                              </div>
                            </div>
                          </a>
                          {/* End Icon Blocks */}
                        </div>
                        <div className="col-sm-6  px-2 mb-3 mb-lg-0">
                          {/* Icon Blocks */}
                          <a
                            className="card h-100 transition-3d-hover"
                            style={{
                              borderRadius: "15px",
                              boxShadow: "0 6px 6px -3px gray",
                            }}
                          >
                            <div className="card-body">
                              {/* <h4>
                              <small>{appLabel.contactNumber}</small>
                            </h4> */}
                              <div>
                                <center>
                                  <small>
                                    <i className="fa fa-phone text-warning" />{" "}
                                    <br />
                                    {categoryDetail.LO_PHONE}
                                  </small>
                                </center>
                              </div>
                            </div>
                          </a>
                          {/* End Icon Blocks */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Accordion */}
              <br></br>
              {/* Help Link */}
              <div className="media align-items-center mb-4">
                <span className="w-100 max-w-6rem mr-2"></span>
                <div className="media-body text-body small">
                  <span className="font-weight-bold mr-1">
                    <i className="fa fa-share text-primary" /> Need Help?
                  </span>
                  Check our&nbsp;
                  <Link to="/faq" className="" target="blank">
                    {appLabel.faq}
                  </Link>
                  &nbsp;page
                </div>
              </div>
              {/* End Help Link */}
            </div>
            {/* End Product Description */}
          </div>
        </div>
        {/* End Hero Section */}
        {/* Product Description Section */}

        {/* End Product Description Section */}
      </main>
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};
export default BookingDescriptionStudent;

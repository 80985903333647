/**
 * @author Gedeon NdundeCode
 * @date 06/12/2021
 */

import { combineReducers } from "redux";

import { staticDataRed as staticData } from "../reducers/staticData-red";

export default combineReducers({
  staticData,
});

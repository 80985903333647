import React from "react";
import { Navigate, Route, Switch } from "react-router-dom";

// -------------- PAGES IMPORTS --------------

import Features from "../compenents/Features/Features";
import Contact from "../compenents/Contact/Contact";
import Booking from "../compenents/Booking/Booking";
import Home from "../compenents/Home/Home";
import News from "../compenents/News/News";
import CategoryNews from "../compenents/News/CategoryNews";
import NewsArticle from "../compenents/News/NewsArticle";
import Versions from "../compenents/Versions/Versions";
import Faq from "../compenents/Faq/Faq";
import NewsConstruction from "../compenents/News/NewsConstruction";
import BookingDescriptionGuest from "../compenents/Booking/BookingDescriptionGuest";
import BookingDescriptionStudent from "../compenents/Booking/BookingDescriptionStudent";
import SliderImage from "../sample/SliderImage";

export const RoutedContent = () => {
  return (
    <Switch>
      <Route path="/" component={Booking} exact />
      <Route path="/home" component={Home} exact />
      <Route path="/contact" component={Contact} exact />
      <Route path="/booking" component={Booking} exact />
      <Route path="/features" component={Features} exact />
      <Route path="/versions" component={Versions} exact />
      <Route path="/news" component={News} exact />
      <Route path="/category-news" component={CategoryNews} exact />
      <Route path="/news-article" component={NewsArticle} exact />
      <Route path="/faq" component={Faq} exact />
      <Route path="/news-construction" component={NewsConstruction} exact />
      <Route
        path="/booking-detail-guest"
        component={BookingDescriptionGuest}
        exact
      />
      <Route
        path="/booking-detail-student"
        component={BookingDescriptionStudent}
        exact
      />
      <Route path="/slider-image" component={SliderImage} exact />
    </Switch>
  );
};

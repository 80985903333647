import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../ui-setup/Footer";
import Header from "../ui-setup/Header";
import appLabel from "../../config/appLabel";
import { BOM } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import NumberFormat from "react-number-format";
import appStatic from "../../config/appStaticData";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AcomListFac,
  GuestListFac,
  SearchAcomListFac,
  SearchGuestListFac,
} from "../layouts/Facility";

const Booking = () => {
  const { defaultImgCarourel } = appStatic;

  const [spaceData, setSpaceData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [residenceTypeData, setResidenceTypeData] = useState([]);
  const [tempSearchList, setTempSearchList] = useState([]);
  const [selectedResidenceType, setSelectedResidenceType] = useState("");
  const [currency, setCurrency] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isAccommodation, setIsAccommodation] = useState(false);
  const [isGuestHouse, setIsGuestHouse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tempArr = [];

  useEffect(() => {
    getAllSpace();
    getAllCategory();
    // onSelectResidence(appStatic.residenceType[1]);
    // setSelectedResidenceType(appStatic.residenceType[1].name);
    window.scrollTo(0, 0);
  }, []);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  function getAllSpace() {
    BOM.LoadAlert(cnt.LOAD, "Processing");
    /** FETCH ROLE */
    BOM.FetchReqAction({}, api.GetAllSpace, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.guestHouseListing;

        objList.forEach((element) => {
          tempArr.push({
            name: element.LTS_NAME,
          });
        });

        setResidenceTypeData(BOM.GetUnique(tempArr, "name")); // this is used for residence dropdown
        setSpaceData(objList);

        setSelectedResidenceType(tempArr.length !== 0 && tempArr[0].name); // this is used for the first residence selected on the dropdown
        onSelectResidence(tempArr.length !== 0 && tempArr[0]); // this is for displaying residence base on the residence type selected
      }
    });
  }

  function getAllCategory() {
    BOM.LoadAlert(cnt.LOAD, "Processing");
    /** FETCH ROLE */
    BOM.FetchReqAction({}, api.GetAllCategory, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.allCategoryListing;
        // const tempArr = [];
        objList.forEach((element) => {
          tempArr.push({
            name: element.LTS_NAME,
          });
        });
        setResidenceTypeData(BOM.GetUnique(tempArr, "name"));

        setSelectedResidenceType(tempArr.length !== 0 && tempArr[0].name); // this is used for the first residence selected on the dropdown
        onSelectResidence(tempArr.length !== 0 && tempArr[0]); // this is for displaying residence base on the residence type selected
      }
    });
  }

  const onSelectResidence = (item) => {
    if (!item) return;

    setSelectedResidenceType(item.name);
    setIsAccommodation(false);
    setIsGuestHouse(false);
    setCategoryData([]);
    setSpaceData([]);
    setIsLoading(true);
    setTempSearchList([]);

    // BOM.LoadAlert(cnt.LOAD, "Processing");

    switch (item.name.toLowerCase()) {
      case "guest house":
      case "bnb":
        /** FETCH ROLE */
        BOM.FetchReqAction({}, api.GetAllSpace, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            const objList = res.guestHouseListing.filter(
              (element) =>
                element.LTS_NAME.toLowerCase() === item.name.toLowerCase()
            );
            setSpaceData(objList);
            setIsGuestHouse(true);
            setIsLoading(false);
          }
        });
        break;
      case "estate":
      case "complex":
      case "student accommodation":
        /** FETCH ROLE */
        BOM.FetchReqAction({}, api.GetAllCategory, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            const objList = res.allCategoryListing.filter(
              (element) =>
                element.LTS_NAME.toLowerCase() === item.name.toLowerCase()
            );
            setCategoryData(objList);
            setIsAccommodation(true);
            setIsLoading(false);
          }
        });
        break;
    }
  };

  const onSearch = (searchKey) => {
    let arrToFilter = [];
    setTempSearchList([]);

    if (!searchKey) return;

    switch (selectedResidenceType.toLowerCase()) {
      case "guest house":
      case "bnb":
        arrToFilter = spaceData;
        break;

      case "estate":
      case "complex":
      case "student accommodation":
        arrToFilter = categoryData;
        break;
    }
    const filtered = arrToFilter.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val === "string" && val.includes(searchKey)
      )
    );
    setTempSearchList(filtered);
  };

  return (
    <div>
      <Header />
      <main id="content" role="main">
        <div className="w-md-80 text-center space-top-2 mx-md-auto mb-9"> </div>
        <div className="container space-sm-2  space-bottom-lg-3">
          <div className="col-md-12 mb-6">
            <div className="form-group mb-6">
              <label className="input-label h3">
                <i className="fa fa-search text-primary"></i>&nbsp;
                {appLabel.selectCriteria}
              </label>
              {residenceTypeData.length === 0 ? (
                <div className="alert bg-warning h5 text-white">
                  <p>{BOM.Uwfirst(appLabel.noRegisteredResidence)}</p>
                </div>
              ) : (
                <ButtonDropdown isOpen={dropdownOpen} toggle={() => toggle()}>
                  <DropdownToggle caret size="sm">
                    <i className="fa fa-building text-danger"></i>&nbsp;{" "}
                    {selectedResidenceType}
                  </DropdownToggle>
                  <DropdownMenu>
                    {residenceTypeData.map((item, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => onSelectResidence(item)}
                        >
                          <i className="fa fa-building text-danger"></i>&nbsp;
                          {item.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </ButtonDropdown>
              )}
            </div>
            {(isAccommodation || isGuestHouse) && (
              <form className="input-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder={appLabel.searchAll}
                  style={{}}
                  onChange={(e) => onSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button type="button" className="btn btn-primary btn-xs">
                    <i className="fa fa-search" />
                  </button>
                </div>
              </form>
            )}
          </div>

          {isLoading && (
            <div className="alert bg-info text-white">
              <i
                className="fa fa-spinner fa-spin"
                style={{ fontSize: "24px" }}
              />{" "}
              &nbsp;
              <strong>Loading Residences...</strong>
            </div>
          )}
          {isAccommodation && (
            <>
              {tempSearchList.length !== 0 && (
                <SearchAcomListFac
                  arrList={tempSearchList}
                  currency={currency}
                />
              )}
              <AcomListFac arrList={categoryData} currency={currency} />
            </>
          )}
          {isGuestHouse && (
            <>
              {tempSearchList.length !== 0 && (
                <SearchGuestListFac
                  arrList={tempSearchList}
                  currency={currency}
                />
              )}
              <GuestListFac arrList={spaceData} currency={currency} />
            </>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Booking;
